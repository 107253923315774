.ribbon {
    position: absolute;
    left: -5px; top: -8px;
    z-index: 1;
    overflow: hidden;
    width: 100px; height: 100px;
    text-align: right;
  }
  .ribbon span {
    font-size: 12px;
    font-weight: bold;
    color: #FFF;
    text-transform: capitalize;
    text-align: center;
    line-height: 20px;
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    width: 110px;
    display: block;
    background: $primary;
    box-shadow: 0 3px 10px -5px rgba(0, 0, 0, 1);
    position: absolute;
    top: 20px; left: -25px;
    height:25px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .ribbon span::before {
    content: "";
    position: absolute; left: 0px; top: 100%;
    z-index: -1;
    border-left: 3px solid darken($primary, 5%);
    border-right: 3px solid transparent;
    border-bottom: 3px solid transparent;
    border-top: 3px solid darken($primary, 5%);
  }
  .ribbon span::after {
    content: "";
    position: absolute; right: 0px; top: 100%;
    z-index: -1;
    border-left: 3px solid transparent;
    border-right: 3px solid darken($primary, 5%);
    border-bottom: 3px solid transparent;
    border-top: 3px solid darken($primary, 5%);
  }