.userMeta{
    &__title {
        $p:&;
        &__location {
            
        }
    }
}

.cards__column {
    flex-direction: column;

    @include media-breakpoint-up(md){
        flex-direction: row;
    }

    // .userAction {
    //     margin-top: em(15);
    //     @include media-breakpoint-up(lg){
    //         margin-top: 0;
    //     }
    // }
}