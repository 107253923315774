.tab-menu  {
    .owl-item {
        &:not(:last-child){
            border-right:1px solid #ddd;
    }
    }
}


//responsive tabs

 
  .tab-content{
    padding: 10px;
  }
  
 
  
  
  //css to add hamburger and create dropdown
  .nav-tabs.nav-tabs-dropdown,
  .nav-tabs-dropdown {
   @include media-breakpoint-down(sm) {
        // border: 1px solid $border-color;
        border-radius: 0;
        overflow: hidden;
        position: relative;
        flex-direction: column;
  
        &::after {
          content: "☰";
          position: absolute;
          top: 16px;
          right: 8px;
          z-index: 2;
          pointer-events: none;
          color:white;
          // background: rgba($primary, 0.5);
          padding: 2px 6px;
          border-radius: 2px;
        }
  
        &.open {
          &:after {
            content: "✕";
          }
          a {
            position: relative;
            display: block;
          }
  
          > li> a.active  {
            background-color:$primary;
          }
        }
  
  
      li {
        display: block;
        padding: 0;
        vertical-align: bottom;
      }
  
      > li > a {
        position: absolute;
        top: 0;
        left: 0;
        margin: 0;
        width: 100%;
        height: 100%;
        display: inline-block;
        border-color: transparent;
  
        &:focus,
        &:hover,
        &:active {
          border-color: transparent;
        }
      }
  
      > li > a.active {
        display:block;
        border-color: transparent;
        position: relative;
        z-index: 1;
        background: #fff;
  
        &:focus,
        &:hover,
        &:active {
          border-color: transparent;
        }
         
      }
    }
  }



  //responsive bootstrap tab
  .nav {
    $p:&;
 
    
    &-tabs {
    box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.1);
    border:0 !important;
 
    
    #{$p}-item{
      margin:0px;
        &:not(:last-child){
        border-right:1px solid #ddd;
        }
        &.active {
            a {
                color: white !important;
            }
        }
      
        @media (max-width:768px){
              order:2;
            
              
              &.active {
                  order:1 !important;
              }
        }
       
    }
        #{$p}-link {
                padding: 20px 22px;
                border:0;
                background: transparent;
                border-radius: 0;
                margin:0px;
                color:#555;
                height: 100%;
                border:0 !important;
                text-transform: capitalize;
                &.active {
                    background:$primary !important;
                    color:white;
                   
                }
                &:hover {
                    background:rgba($primary, 0.1);
                }
        
        }
    }
    
}
.tab-pane {
    padding:0 20px;
}

.tab-menu-new {
  
  box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.1);
  // flex-wrap:wrap ;
display:none;
  flex-direction: column;
  @include media-breakpoint-up(md){
    flex-direction:row;
    display:flex;

    &.show{
      display:flex !important;
    }
  }
  .item {
      &:not(:last-child){
          border-right:1px solid #ddd;
      }
      a {
        padding: 20px 22px;
        display:block;
        color:$gray-700;
        transition: all 0.3s ease-in-out;
             &:not(.active){
                 &:hover {
                      background:rgba($primary, 0.1);
                 }
             }    
             &.active {
               background:$primary;
               color: white;
               display:none;
               @include media-breakpoint-up(md){
                display:block;
               }
              
             }
          }
  }
}

.tab-toggle {
  $p:&;
  padding: 20px 22px;
  background:$primary;
  color:white;
  display:block;
  position: relative;
 
  @include media-breakpoint-up(md){
    display:none;
  }
  &:hover, &:focus {
    color:white;
    background:darken($primary, 5%);
  }

  &:after {
    content: "☰";
    @at-root #{$p}[aria-expanded="true"]:after{
      content: "✕";
    }
    position: absolute;
    top: 16px;
    right: 8px;
    z-index: 2;
 
    color: white;
    padding: 2px 6px;
    border-radius: 2px;
  }
}