.ui-slider {
    --handleSize:20px;
 height:8px;
 border-radius: 3px;
 
 &-handle {
     border-radius: 50%;
     background-color: $primary !important;
     border:none !important;
     width:var(--handleSize) !important;
     height:var(--handleSize) !important;
     top:50% !important;
     transform:translateY(-50%) !important;
     &.ui-state-focus {
         background-color: darken($primary, 15%) !important;
     }
  
     
 }
 &-range {
     background-color: $primary !important;
 }
 &-value {
    margin-top: 15px;
    display: flex;
    justify-content: space-between;
    font-size: 15px;
    
}

}

.ui-autocomplete {
    max-height: 300px;
    overflow-x:hidden;
    overflow-y:auto;
}

.ui-datepicker {
    .ui-corner-all {
        &:before {
            color: $gray-700;
        }
    }

    .ui-state-highlight{
        background-color: $primary;
        color: white;
        border:none;
    }
    .ui-state-active {
        background-color: $gray-700;
        color: white;
        border:none;
    }
    .ui-widget-header {
        background: #e9e9e9;
    }
    .ui-sate-hover {
        font-weight: bold;
    }
   .ui-datepicker-prev:hover {
       border:none;
       top:2px;
       left:2px;
       background: transparent;
   }
   .ui-datepicker-next:hover {
       border:none;
       top:2px;
       right:2px;
       background: transparent;
   }
}

//auto complete
.ui-autocomplete {
    z-index: 8011;
    padding-top: 10px;
    padding-bottom: 10px;
    .ui-menu-item {
        padding:6px 10px;
        a {
            color: $gray-700;
            cursor: pointer;
            padding:4px 8px; 
            display: block;

            &.ui-state-focus, &.ui-state-active{
                background-color: lighten($primary, 45%);
                border-color:$primary;
                // color: white;
                
            }

        }
    }
}