.header-block {
    align-items: center;
    background: white;
    flex-wrap: wrap;

    @media (min-width:1250px) {
        flex-wrap: nowrap;
    }
.logo {
    padding:0px;
}
    .navbar-nav {
        .avatar {
            --size:20px;
            width:var(--size);
            height:var(--size);
        }
        li > a {
            @media (max-width:1249px) {
            padding-left: 15px;
            padding-right: 15px;
            }
        }
        a {
            display: flex;
            align-items: center;


            svg,
            .avatar {
                margin-right: 10px;
            }

            svg {
                fill: #A3A7AA;
                // font-size: rem(18);
            }

            &:hover {
                svg {
                    fill: #0ab7c5;
                }

                .avatar {
                    border-color: #0ab7c5;
                }
            }
        }

        .show .avatar {
            border-color:$primary;
        }
    }
    .logo {
        flex: 0 0 auto;
        border: 0;
    }
}


#headersearch {
    flex: 1 1 100%;
    order: 3;
    margin-bottom: 15px;
    @media (min-width:1250px) {
    }
    @media (min-width:1250px) {
        max-width: 40%;
        order: unset;
        margin-bottom: 0;
        flex: 1 1 auto;
    }

    display: flex;
    // margin-right:auto;
    align-items: center;
    // padding:15px 0 15px 0;
    justify-content: space-between;
    // border-right:1px solid #eee;
    // padding-right: 20px;
    height: 45px;
    background:#fff;

    color:#000;

    .form-control {
        flex: 1 1 auto;
        height: 100%;
        margin: 0px;
        border: 1px solid $border-color;
        border-right: none;

        &:focus {

            border-color: $primary;

            ~.btn {
                color: $primary;
                border-color: currentcolor;
                background-color: currentcolor;

                svg {
                    fill: white;
                }
            }
        }
    }

    .btn {
        flex: 0 0 auto;
        align-self: stretch;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 0 0px 0px 0;
        background: #F8F9FA;
        border: 1px solid $border-color;

        svg {
            fill: $gray-700;
        }
    }
}

