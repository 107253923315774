.time-list {
    border: none;
    margin-right: -15px !important;

    li {
        width: calc(33.33% - 15px);
        border: none;

        @include media-breakpoint-up(md) {
            width: calc(16.66% - 15px);
        }

        height:50px;
        margin-right: 15px !important;
        margin-bottom: 15px !important;

        label {
            background-color: #fff;
            border: 1px solid #ccc;
            border-radius: 2px;
            font-weight:600;
        }

        input {
            &:checked+label {
                background-color: #DDF3FC;
                color: $gray-600;
                font-weight: 600;
            }
        }
    }
}

.time-list-item {
    display: flex;
    align-items: center;
    padding: 10px 0;

    @at-root .time-list-option2 & {
        flex-wrap: wrap;

        @include media-breakpoint-up(md) {
            flex-wrap: nowrap;
        }

    }

    .time-list-time {
        flex: 0 0 90px;
    }

    .time-list-slot {
        flex: 1;
        overflow-x: auto;

        @at-root .time-list-option2 & {
            overflow-x: visible;
            margin-top: 20px;

            @include media-breakpoint-up(md) {

                margin-top: 0;
            }
        }
    }
}

.time-list-new {
    --margin: 10px;
    display: flex;
    margin-bottom: 0px !important;
    margin: 0 -var(--margin);
    justify-content: space-between;

    li {
        flex: 1 0 110px;
        display: flex;
        flex-wrap: nowrap;

        @at-root .time-list-option2 & {
            flex: 1 1 110px;

            @include media-breakpoint-up(md) {
                flex: 1 0 110px;
            }
        }

    }

    label {
        background-color: #fff;
        border: 1px solid $gray-700;
        border-radius: 2px;
        text-align: center;
        margin: 0 6px;
        flex: 1;
        padding: 8px;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    input {
        display: none;

        &:checked+label {
            background-color: rgba($primary, 0.15);
            color: $primary;
            font-weight: 600;
            border-color: $primary;
        }

        &:disabled+label {
            background-color: rgba($green, 0.15);
            color: $green;
            font-weight: 600;
            border-color: $green;
            opacity: 0.5;
            cursor: not-allowed;
            pointer-events: stroke;
        }
    }
}

.time-list-wrap {
    @include media-breakpoint-up(md) {
        max-height: 400px;
        overflow-y: auto;
    }
}

.time-list-option3{
    .time-list-new{
        flex-wrap:wrap;
    justify-content:flex-start;
    align-self:Center;
    margin:0 auto;
    text-align:center;
    } 
    flex-wrap:wrap;
    justify-content:flex-start;
    align-self:Center;
    margin:0 auto;
    text-align:center;
    li {
        flex:1 1 auto;
         margin-bottom:15px;
    }
}