.clinicfilters {
    display:flex;
 
    flex-direction: column;
    @include media-breakpoint-up(md){

        flex-direction: row;
        margin:0 -15px;
    }
    .form-group {
        flex:1;
        margin:15px ;
        @include media-breakpoint-up(md){

            margin:0 15px;
        }
    }
    .btn {
        flex:0 0 auto;
        margin:15px ;
        @include media-breakpoint-up(md){

            margin:0 15px;
        }
    }
}

.cards--clinic {
    .appointment-types {
        display: block;
    }
    .summery {
         
        @include media-breakpoint-down(md){
            display: none;
        }
    }
}