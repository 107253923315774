.listconditions {
  background: $white;
  // padding: 0 $spacer $spacer $spacer;
  &__figure {
    // margin: 0 (-$spacer) ($spacer*2);
    max-height: 400px;
    overflow: hidden;
    img {
      @extend %imgobjectcover;
    }
    iframe, video {
      width: 100%;
    }
  }
  .searchbar {
    margin: ($spacer*3) ($spacer*.75);
    form {
      max-width: 500px;
      margin: 0 auto;
    }
  }
  .sidebar {
    &__title {
      border: none;
      border: none;
      margin: 0 0 ($spacer);
      padding: 0;
      font-weight: 600;
      @include font-size($font-size-lg);
    }
  }
  &-body {
    figure {
      margin: ($spacer) 0;
      img {
        max-width: 100%;
      }
    }
  }
}
.arrowlists {
  margin: 0;
  height: 980px;
  overflow: auto;
  &__item {
    margin-bottom: $spacer*.5;
    a {
      color: $gray-700;
      background: url('../images/double-arrow-right.png') no-repeat 0px center;
      background-size: 10px;
      padding-left: $spacer;
      transition: all 0.3s ease 0s;
      &:hover {
        background-position: 5px center;
      }
    }
  }
}
.borderedblock {
  border: 1px solid $border-color;
  padding: $spacer*.5;
  margin-bottom: $spacer*.75;
}
.medicalcondition {
  .media-list {
    .media-object {
      --circleSize: 60px;
      box-shadow: none;
      height: var(--circleSize);
      width: var(--circleSize);
      border-radius: var(--circleSize);
      margin: 0;
    }
    .media-left {
      padding-right: $spacer*.5;
    }
    .media-heading {
      font-weight: 600;
      @include font-size($font-size-base);
    }
    .position {
      color: $gray-500;
      font-size: 12px;
      font-weight: 600;
    }
  }
}
.arrow-lists li {
  background: url('../images/circle-arrow-right.png') no-repeat left 4px;
  background-size: 16px;
  padding-left: $spacer*1.5;
  margin-top: $spacer*.75;
}