.team {
    --minSize:200px;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(var(--minSize), max-content));
    justify-content: center;
    grid-gap: 30px;

    &__figure {
        position: relative;
        padding-top: 100%;
        // border-radius: 4em;
        overflow: hidden;
        align-self: baseline;

        @at-root .team--new & {
            --size: 100px;
            padding-top: var(--size);
            width: var(--size);
            position: absolute;
            top: 25px;
            transform: translateY(0px);
            transition: all 0.3s ease-in-out;
            // transform:translateY(-4px);

            @include media-breakpoint-up(sm) {
                padding-top: 120%;
                width: 100%;
                position: relative;
                top: 0;
            }


            border-radius: 0 16px 0 16px;
            box-shadow: 8px 16px 20px rgba(black, 0.25);

        }

    }

    &__image {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: top center;
        position: absolute;
        top: 0;
        left: 0;
    }

    &__meta {
        padding: 15px 10px;
        text-align: center;

        @at-root .team--new & {
            text-align: left;
           
        }

        &__name {
            font-weight: bold;
            // animation: myfirst 2s linear 2s infinite alternate;
        }

        &__position {
            color: #727272;
            font-size: rem(14);
            margin-top: 6px;
        }

        &__social {
            display: flex;
            justify-content: center;

            li {
                margin: 6px;

                a {
                    font-size: 20px;
                }
            }
        }

        &__header {
            @include media-breakpoint-up(md) {
                justify-content: space-between;
                display: flex;
            }

            @at-root .team--new & {
                padding-left: 115px;

                @include media-breakpoint-up(sm) {
                    padding-left: 0;
                }
            }
        }

        &__title {
            flex: 1 0 auto;
        }

        &__summery {
            border-top: 1px solid $border-color;
            padding-top: 16px;
            margin-top: 22px;

            p:last-child {
                margin-bottom: 0;
            }
        }

    }

    &--new {
        --minSize:165px;
        .social-icons {
            @include media-breakpoint-up(md) {
                margin-left: auto;
                flex: 1 0 auto;
                grid-auto-flow: column;
                justify-content: end;
            }

            svg {
                font-size: 20px;
            }
        }
    }
}

@keyframes myfirst {
    0%   {transform: translateY(-6px) }
    50%   {transform: translateY(-12px)}
    100%  {transform: translateY(0px)}
  }