.intl-tel-input {
    display: block;
    // @include media-breakpoint-up(lg){
    //     display: inline-block;
    // }

    input#phone {
       margin-bottom: em(15);
       @include media-breakpoint-up(lg){
            margin-bottom: 0;
       }
    }
}