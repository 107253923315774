// Variables
//
// Variables should follow the `$component-state-property-size` formula for
// consistent naming. Ex: $nav-link-disabled-color and $modal-content-box-shadow-xs.

// Colors
$blue:    #4267B2;
$purple:  #6A47DA;
$pink:    #F75358;
$red:    #FA6541;
$orange: #F8B91C;
$green:   #03B5AA;
$teal:   rgb(3, 3, 3);
$body-color: #929292;
$facebook:#708cbe;
$google:#f46946;
$twitter:#49c4ea;
$pricing-color:(
  "basic":#0ab7c5,
  "pro":#60DD4D,
  "premium":#F23994,
  "enterprise":#6A47DA
);

$primary: #0ab7c5;
$secondary: #F23994;

$theme-colors: (
  "success": $green
);
// stylelint-disable-next-line scss/dollar-variable-default
$theme-colors: map-merge(
  (
    "purple": $purple,
    "pink": $pink,
    "red": $red,
    "orange": $orange,
    "teal": $teal
  ),
  $theme-colors
);

// bootstrap gray variants
$white:    #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #6c757d !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$black:    #000 !default;


// Fonts
//
// Font, line-height, and color for body text, headings, and more.
// npm install of google font roboto
@import url('https://fonts.googleapis.com/css?family=Roboto+Slab:300,400');

// stylelint-disable value-keyword-case
$font-family-base: 'Roboto Slab', serif;;

// stylelint-enable value-keyword-case
$font-size-base: rem(15);
$lead-font-size: $font-size-base * 2.5; //is to 40px
$font-size-lg:  $font-size-base * 1.25; // is to 20
$font-size-sm:  $font-size-base * .625; // is to 10px


$spacer: 1.25rem; //is to 20px
$lead-font-weight: 700;
$headings-font-weight: 500;
$headings-margin-bottom: $spacer;

$h1-font-size: $font-size-base * 3.12; // is to 50px
$h2-font-size: $font-size-base * 2.5; // is to 40px
$h3-font-size: $font-size-base * 2; // is to 32px
$h4-font-size: $font-size-base;
$h5-font-size: $font-size-base * .85;
$h6-font-size: $font-size-base * .72;

$focused-label-color: red;

$link-color: $primary;
$link-hover-color: currentColor;
$link-hover-decoration: none;
$border-color: $gray-400;
 
$embed-responsive-aspect-ratios: (
  (21 9),
  (16 9),
  (4 3),
  (1 1)
) ;