/*
Bootstrap v4.3.1
*/

/*===========================
=            npm            =
===========================*/
@import 'base/functions-custom';
@import "functions";
// @import 'base/variables'; 
@import "variables";
@import "mixins";   
// @import "root";
// @import "reboot";
// @import "type";
// @import "images";
@import "tables";
// @import "code";
@import "grid";
// @import "buttons";
// @import "transitions";
// @import "dropdown";
@import "nav";
// @import "navbar";
@import "utilities";
// math functions are not included in sass 
// @import "mathfunctions";
// @import "breadcrumb";
// import converter 
// @import "drop-shadow-converter";
// @import "forms";
// @import "custom-forms";
// @import "buttons";
// @import "input-group";
// @import "transitions";
// @import "list-group";
@import "modal";
@import "badge";
// @import "close";
// @import "progress";
// @import "card";
// @import "alert";
// @import "slick.scss";







  
/*=============================
=            base             =
=============================*/


@import 'base/variables';
@import 'base/mixin-custom';  
@import 'base/placeholder';  
@import 'base/global';
 





/*==============================
=            layout            =
==============================*/
@import 'layout/grid';
@import 'layout/sidebar';
@import 'layout/header';
// @import 'layout/footer';    
// @import 'layout/widgets';




/*=========================================
=            vendor       =
=========================================*/

// @import 'vendors/jquery-ui';
// @import 'vendors/slick';
// @import 'vendors/intlTelInput';
// @import 'vendors/jquery.fancybox';
  




/*===============================
=            modules            =
===============================*/
@import 'modules/postblock';
@import 'modules/advert';
@import 'modules/button';
@import 'modules/circleimage';
@import 'modules/profilesetupcard';
@import 'modules/profilesetupform';
@import 'modules/progress-bar';
@import 'modules/form';
@import 'modules/socialbutton';
@import 'modules/listcard';
@import 'modules/postcard';
// @import 'modules/blogcustom';
@import 'modules/blogsearch';
@import 'modules/selectwithicon';
@import 'modules/footer';
@import 'modules/dashboardsidebar';
@import 'modules/time-slot';
@import 'modules/form-switch';
@import 'modules/tableBlock';
@import 'modules/schedule';
@import 'modules/animations';
@import 'modules/cards';
@import 'modules/testimonials';
@import 'modules/pricingtable';
@import 'modules/easy-autocomplete';
@import 'modules/croppie';
@import 'modules/media';
@import 'modules/circlecheck';
@import 'modules/notifications'; 
@import 'modules/report';
@import 'modules/accordion'; 
@import 'modules/cookie'; 
@import 'modules/activities'; 
@import 'modules/alert'; 
@import 'modules/thumbnail';  
@import 'modules/carousel';  
@import 'modules/payoutblock';  
@import 'modules/bubble-info';  
@import 'modules/badge';  
@import 'modules/badge';  
@import 'modules/search-box';  
@import 'modules/table';  
@import 'modules/stepper'; 
@import 'modules/int_tel_input'; 
@import 'modules/tabs'; 
@import 'modules/pagination'; 
@import 'modules/team'; 
@import 'modules/healthFit'; 
@import 'modules/countries';   
@import 'modules/jumbotron';   
@import 'modules/social-icons';   
@import 'modules/navigation';   
@import 'modules/typography';   
@import 'modules/feedbackbtn';   
@import 'modules/ribbon';   
@import 'modules/floatingFilter';   
@import 'modules/uploader';   
@import 'modules/usermeta';   
@import 'modules/nav';   



              
            
          
 



 

 

/*=============================
=            pages            =
=============================*/
// @import 'pages/healthfeed';
@import 'pages/howitworks';
@import 'pages/blogpage';
@import 'pages/addblogpage';
@import 'pages/dashboardpage';
@import 'pages/profilesetting';
@import 'pages/homepage';
@import 'pages/clinicpricing';
@import 'pages/clinicsetup';
@import 'pages/medicalcondition';
@import 'pages/chooseplan';
@import 'pages/search';
@import 'pages/finddoctor'; 
@import 'pages/appointment'; 
@import 'pages/clinicSearch';  
@import 'pages/regulation';  
@import 'pages/healthfeed';  
@import 'pages/styleguide';  
@import 'pages/static-pages';  




/*==============================
=            vendor            =
==============================*/
@import 'vendor/jquery-ui';
@import 'vendor/daterangepicker';
@import 'vendor/fancybox';

 




