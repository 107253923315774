.jumbotron {
    $p: &;
    &:after {
        background: rgba(0, 0, 0, 0.3);
    }

    &--page-banner {
        --blur: 9px;
       height:100px;
        @include media-breakpoint-up(lg){
            height: 374px;
        }
       
        position: relative;

        &:after {
            display: none;
        }

        #{$p}__header {
            // @debug(#{p}__header );
          
                @include absolute(top 0 left 0);
                width: 100%;
                height: 100%;
            
            @include flexCenterAll(column);
            padding:15px;

            background-color: rgba($primary, 0.7);
            // -webkit-backdrop-filter: blur(var(--blur));
            // backdrop-filter: blur(var(--blur));

            * {
                color:white;
            }
        }

        #{$p}__title{
            text-transform:capitalize;
            font-size:rem(24);
            @include media-breakpoint-up(lg){
            font-size:rem(36);
            }
            font-weight: 600;
        }

        #{$p}__imgoverlay {
            @include absolute(top 0 left 0);
            width: 100%;
            height:100%;
            background-repeat: no-repeat;
            z-index: -1;
            background-attachment: fixed;

        }

    }

    .hero-content {
        h1 {
            font-size: rem(22);
            font-weight: bold;
            line-height: normal;
            text-transform: capitalize;
            @include media-breakpoint-up(sm){
                font-size: rem(42);
            }
            + p {
                margin-top: 20px;
                font-size: rem(16);
                line-height: normal;
                @include media-breakpoint-up(sm){
                    font-size: rem(22);
                }
            }
        }
    }

}

