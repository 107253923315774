.--apmt-media {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  .media {
    &-left {
      display: flex;
      flex: 1 0 auto;
      flex-direction: column;
    }
    &-body {
      display: flex;
      flex-direction: column;
    }
  }
  .--media-time {
    justify-content: flex-end;
    &:last-child {
      margin-bottom: 0;
    }
  }
  .apmt__users {
    margin-bottom: 5px !important;
  }
}

.media {
  $p:&;
  &--flex {
    display:flex; 

    #{$p}__left {
      margin-right: 20px;
      #{$p}__thumbnail {
        &--100 {
          --size:100px;
         
            width:var(--size);
            height: var(--size);
            background-color: #F2F2F2;
            overflow: hidden;
            @extend %flexcenterall;
           

        }
      }
    }

    #{$p}__body {
      flex:1;
      position: relative;

       

    }
    #{$p}__header {
      display:flex;
      justify-content: space-between;
    }
    #{$p}__title {
      line-height: 1.6;
     
      flex: 1 1 100%;
    width: 100px;
    @include media-breakpoint-up(md){
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    }

    #{$p}__action {
      flex:1 0 auto;
    }

  }

  &--bordered {
    &:not(:last-of-type){
      border-bottom: 1px solid $border-color;
    }
  }

  &-body {
    @include media-breakpoint-down(sm){
      width: auto;
    }
  }
}

.media-heading {
  font-size:18px;
  line-height:normal;
}