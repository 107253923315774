.dashboard-new {
  .user-media .media-body {
    padding-left: 0;
  }
  .media-heading-link {
    align-items: center;
    font-size: 14px;
    display: flex;
    svg {
      fill: $primary;
      margin-right: $spacer*.25;
    }
  }
  .page-header {
    margin-bottom: 0;
    .page-title {
      align-items: center;
      display: flex;
      justify-content: space-between;
      @include font-size($font-size-lg);
    }
    a {
      align-items: center;
      display: flex;
      @include font-size($font-size-base);
      svg {
        fill: $primary;
        width: 14px;
        height: 14px;
        margin-right: $spacer*.5;
      }
    }
  }
}
.setting-form {
  &__foot {
    margin-top: $spacer*2;
  }
  .cards {
    &__header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      &::before,
      &::after {
        display: none;
      }
    }
    &__title {
      font-size: 14px;
      font-weight: 600;
    }
  }
}
.border {
  &__textblock {
    border: 1px solid $border-color;
    padding: $spacer;
  }
}
.setting-card {
  flex:1;
  .card__footer {
    margin-top: $spacer*2;
  }
}


// Profile Setting popups styling
.uploaditem {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: $spacer*.75;
  $P : &;
  &__details {
    display: flex;
  }
  &__icon {
    display: inline-flex;
    align-items: center;
    margin-right: $spacer*.5;
    svg {
      fill: $body-color;
    }
  }
  &__actions {
    display: flex;
    flex-direction: row;
    align-items: center;
    svg {
      width: 11px;
      height: 11px;
      fill: $gray-700;
    }
  }
  &__status {
    width: 150px;
    margin-right: $spacer;
    .progress {
      margin: 0;
    }
  }
  &.success {
    #{$P}__icon {
      svg {
        fill: $primary;
      }
    }
    #{$P}__name {
      color: $primary;
    }
  }
}

.security-setting {
  input {
    max-width: 350px;
    width: 100%;
    display: inline-flex;
  }
  .intl-tel-input {
    max-width: 350px;
    width: 100%;
  }
  .form-group {
    > label {
      display: flex;
    }
    .input-block {
      display: flex;
      > a {
        text-decoration: underline;
        margin-top: $spacer*.5;
        font-size: 14px;
      }
    }
    > label.error {
      margin: 0;
    }
    .passnote {
      @include media-breakpoint-up(md) {
        margin-left: $spacer*.5;
      }
    }
  }
}