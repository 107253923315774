.alert-bottom {
  background: $success;
  bottom: 10px;
  color: $white !important;
  position: fixed !important;
  right: 10px;
  padding-left: $spacer !important;
  .close {
    opacity: 0.5 !important;
    text-shadow: none !important;
  }
}

.alert {
  z-index: 1;
}