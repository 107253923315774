.upload-list { 
    display:grid;
    margin:10px 0;
    grid-template-columns: 15px 2fr minmax(100px, 0.5fr) 15px;
    grid-gap:10px;
    align-items: center;
    
    .progress {
        margin:0px;
    }
}