.postblock {
	background: $white;
	margin-bottom: $spacer;
	box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.16);
	$p: &;

	&__cont {
		background: $gray-100;
		border: 1px solid $gray-200;
		resize: vertical;
		width: 100%;
		height: 72px;
		padding: 10px;

		@include media-breakpoint-up(md) {
			height: 100px;
		}

		&:focus {
			outline: none;
		}
	}

	&__preview {
		// border:1px dashed $gray-400;
		margin: 15px 0;
		position: relative;
		display: flex;
		// padding:15px;

		.attachments {
			margin-bottom: 0px;
			display: grid;
			grid-template-columns: repeat(auto-fit, minmax(150px, min-content));
			grid-auto-columns: minmax(150px, 1fr);
			scroll-snap-type: x proximity;
			overflow-x: auto;
			grid-auto-flow: column;
			grid-gap: 15px;

		}

		.close-btn {
			@include absolute(top 0 right 0);
			--size: 20px;
			width: var(--size);
			height: var(--size);
			background: $primary;
			display: flex;
			justify-content: center;
			align-items: center;

			svg {
				fill: white;
			}
		}
	}

	&__footer {
		display: flex;
		flex-direction: column;

		@include media-breakpoint-up(sm) {

			align-items: center;
			flex-direction: row;
			justify-content: space-between;
		}

		padding: 10px 0 0;

		#{$p}__attachment {
			margin: -$spacer*.25;
			padding-bottom: em(20px);

			@include media-breakpoint-up(sm) {
				padding-bottom: 0px;
			}

			a {
				margin: $spacer*.25;
				display: inline-block;

				&:hover,
				&:focus,
				&:active,
				&.active {

					label {
						background: rgba($primary, 0.15);
					}
				}

				input[type="file"] {
					position: absolute;
					left: -9999px;
					opacity: 0;
					z-index: -1;
				}

				svg {
					width: 18px;
					fill: $gray-700;
				}

				i {
					@include font-size($font-size-base);
				}

				label {
					font-weight: 600;
					@include font-size(12px);
					margin-bottom: 0px;
					margin-left: 0;
					cursor: pointer;
					display: flex;

					display: inline-flex;
					align-items: center;
					background: darken($gray-100, 2%);
					color: $gray-700;
					padding: em(8) em(12);
					border-radius: 20px;
					cursor: pointer;


					span {
						display: none;
						margin-left: 6px;

						@include media-breakpoint-up(md) {
							display: inline-block;
						}
					}


				}
			}

			&-icon {
				color: $gray-500;
			}
		}

	}


	&__attachment-icon {
		>input[type="file"] {
			position: absolute;
			left: -9999px;
			opacity: 0;
			z-index: -1;
		}
	}

	&__thumbnails {
		display: flex;
		flex-wrap: wrap;
		flex-direction: row;
		margin: 0 -10px;

		&-single {
			flex: 1 0 33.33%;
			max-width: 33.33%;
			padding: $spacer*.5;
			height: 100px;
			margin: 0;
			position: relative;

			img {
				@extend %imgobjectcover;
				object-position: 0 0;
			}
		}
	}

	&__btn {
		.upload-errormsg {
			color: $red;
			margin-right: $spacer*.75;
		}

		.btn {
			padding: 5px 15px;
		}
	}
}

.upload-success {
	align-items: center;
	display: flex;
	justify-content: center;
	position: absolute;
	top: 0;
	right: 0;
	content: '';
	width: 1.875rem;
	height: 1.875rem;
	border: 2px solid #fff;
	border-radius: 50%;
	background-color: #0bd7d9;
	color: $white;

}

.dropdown-menu-right {
	right: 0px;
	left: auto;
}

.card-dropdown {
	.dropdown-menu {
		min-width: 170px;
		top: 0;

		a {
			font-weight: 400;
			display: block;
			width: 100%;
			padding: 0.25rem 1.5rem;
			clear: both;
			font-weight: 400;
			color: #212529;
			text-align: inherit;
			white-space: nowrap;
			background-color: transparent;
			border: 0;

			&:hover {
				background-color: $primary;
				color: white;
			}
		}
	}

	.dropdown-toggle {
		background: none;
		border: none;
		cursor: pointer;
		position: absolute;
		right: 0;


		&:after {
			display: none;
		}

		svg {
			width: 5px;
			fill: $gray-500;
		}

		&:focus {
			outline: none;
		}
	}
}

.postdetailblock {
	background: white;


	margin-bottom: 20px;

	&__head {
		display: flex;
		justify-content: space-between;
		flex-direction: row;
		margin-bottom: $spacer;
		// padding: 15px;

		.media {
			display: flex;
			align-items: center;
			overflow: visible;
			width: 100%;
		}

		.media-body {
			overflow: visible;
			width: auto;
			flex: 1 0 auto;
		}

		.media-heading {
			font-weight: 600;
			font-size: 16px;
		}
	}

	&__figure {
		margin-bottom: $spacer;
	}

	&__foot {
		padding: 0 0 15px;

		a {
			color: $gray-900;
			margin-right: 15px;

			&:hover {
				text-decoration: none;
			}
		}

	}

	.displaycont {
		color: $gray-500;
		cursor: pointer;
		text-transform: lowercase;
	}

	#likepost {
		.actionicon {
			color: blue;
		}
	}
}

.actionicon {
	margin-right: 5px;

	svg {
		width: 18px;
		height: 20px;
		vertical-align: middle;
		fill: $gray-700;
	}

	.cshare1 {
		width: 15px;
	}
}

.usermeta {
	align-items: center;
	display: flex;
	flex-direction: row;

	&__figure {
		width: 40px;
		margin: 0 10px 0 0;
		overflow: hidden;
		border-radius: 50%;

		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}

	&__title {
		cursor: pointer;
		display: flex;
		flex-direction: column;
		@include font-size($font-size-base);
		font-weight: 600;
		margin: 0;

		.postedtime {
			color: $gray-500;
			font-size: 14px;
			font-weight: 400;
			margin-top: 5px;
		}

	}

	&__extra {
		display: flex;
		flex-wrap: wrap;
		font-size: 14px;
		color: $gray-500;

		.deviceused {
			margin-left: $spacer*.5;
		}
	}
}

.postblockheadright {
	align-items: center;
	display: flex;
	flex-direction: row;
	position: relative;

	.postedtime {
		color: $gray-500;
		font-size: 14px;
		margin-right: 40px;
	}

	.card-dropdown .dropdown-toggle {
		right: 0;
		height: 20px;
		top: -10px;

		svg {
			width: 4px;
		}
	}
}

// comment block styling
.commentblock {
	border-top: 1px solid $gray-200;
	display: none;
	padding: 10px 0;

	.form-control {
		border-bottom-color: $gray-200 !important;
	}
}

.commentlist {
	border-bottom: 1px solid $gray-300;
	padding: ($spacer) 0;

	>.commentlist {
		border: none;
		padding: ($spacer) 0 0 ($spacer*2);
	}

	&__first {
		border-top: none;
	}

	&__head {
		align-items: center;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
	}

	&__cont {
		margin: 10px 0 0 50px;
	}
}

.commentreplyblock {
	margin-top: 10px;

	span {
		cursor: pointer;
		color: $primary;
	}
}

.backtoaddcomment {
	color: $gray-500;

	&:hover {
		color: $gray-500;
	}
}

.postblock {
	$P: &;

	&.commentpostblock {
		border: 1px solid #e9ecef;
		background: #f8f9fa;
		box-shadow: none;
		margin: ($spacer) 0;

		#{$P}__cont {
			border: none;
			background: none;
			resize: none;
			padding: 0;
			height: 75px;
		}

		#{$P}__footer {
			justify-content: flex-end;
		}

		.padblock {
			padding: 10px;
		}
	}
}

.postdetailblock {
	.embed-responsive {
		margin-bottom: em(20px);
	}

	.dropdown-toggle {
		top: 0 !important;
		transform: none !important;
		right: 0 !important;
	}

	.dropdown-menu-right {
		left: auto !important;
		right: 0 !important;
		top: 50% !important;
		transform: none !important;
	}
}

.postdetailblock {
	.postblock__btn {
		margin-left: 15px;
	}
}

.like-btn {
	$p: &;

	svg {
		fill: $gray-300;

	}


	&--liked {
		svg {
			fill: $gray-700;

		}
	}

	&--selfliked {
		svg {
			fill: $primary
		}
	}

}



//post slider


.post-slider {
	position: relative;

	.item {
		max-height: 400px;

		img {
			height: 100%;
			object-fit: cover;
		}
	}

	.owl {

		&-prev,
		&-next {
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			background-color: rgba(black, 0.7);
			padding: 4px 8px;
		}

		&-prev {
			left: 0;
		}

		&-next {
			right: 0;
		}
	}
}