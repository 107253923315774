.easy-autocomplete {
  position: relative;
  width:100% !important;
}
 
.easy-autocomplete-container {
  @include absolute(top 100% left 0);
  background:white;
  text-align: left;
  color: $gray-900;
  width:100%;
  max-height: 300px;
  overflow-y: auto;
  box-shadow: 0px 10px 9px 0px rgba(0, 0, 0, 0.3);
  z-index: 800;
 
  

  ul {
    padding:0px;
    >li {
      list-style: none;
      /* padding: 0px !important; */
      padding: 10px 20px;
      border-bottom: 1px solid $gray-200;
      margin: 0px;
      &:last-child {
        margin-bottom: 0px;
        border: none;
      }
      a {
        color: $gray-900;
      }
     
    }
    .eac-category {
      color: $gray-400;
      font-weight: 600;
      /* border-bottom: 1px solid #ccc; */
      padding: 10px 20px;
      padding-bottom: 0px;
      @include font-size(14px);
      
    }
  }
}

 

 