.static-page {
    padding:em(50) 0;

    h1, h2, h3, h4,h5, h6 {
        font-weight: 600;
        margin-bottom: rem(20);
    }
}


.press {
    &__footer {
        border-top: 1px solid $border-color;
        padding-top: 20px;
        display: flex;
        justify-content: space-between;
         
        flex-direction: column;
        @include media-breakpoint-up(sm){
            flex-direction: row;
            align-items: center;
        }
    }

    &__logo {
        max-height:60px;
        // flex: 0 0 200px;
        max-width:200px;
        @include media-breakpoint-down(sm){
            margin-bottom: 10px;
        }
        &__img {
            max-width:100%;
            max-height: 100%;
            object-fit: scale-down;
            object-position: left center;
            height: 100%;
        }
    }
}

.contact-illustration-wrap {
   
        position: static;
 
        img {
            max-width: 100%;
            @include absolute(right 0);
            transform:translateX(40%);

        }
}

.contact-section:not(:first-child) {
    margin-top: em(60);
    padding-top: em(60);
    border-top: 1px solid $border-color;

}

@mixin better-blur($radius) {
    -webkit-filter: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg'%3E%3Cfilter id='a' x='0' y='0' width='1' height='1' color-interpolation-filters='sRGB'%3E%3CfeGaussianBlur stdDeviation='#{$radius}' result='b'/%3E%3CfeMorphology operator='dilate' radius='#{$radius}'/%3E %3CfeMerge%3E%3CfeMergeNode/%3E%3CfeMergeNode in='b'/%3E%3C/feMerge%3E%3C/filter%3E %3C/svg%3E#a");
    filter: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg'%3E%3Cfilter id='a' x='0' y='0' width='1' height='1' color-interpolation-filters='sRGB'%3E%3CfeGaussianBlur stdDeviation='#{$radius}' result='b'/%3E%3CfeMorphology operator='dilate' radius='#{$radius}'/%3E %3CfeMerge%3E%3CfeMergeNode/%3E%3CfeMergeNode in='b'/%3E%3C/feMerge%3E%3C/filter%3E %3C/svg%3E#a");
  }
  .svg-filter {
    @include better-blur(10);
  }