.heading {
    line-height: normal;
}

.link-default {
    color:$gray-700;

    &:hover, &:focus, &:active {
        color: darken($gray-700, 5%);
    }
}