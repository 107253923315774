.report-list-wrapper {
    max-height: 400px;
    overflow-x:hidden;
    overflow-y:auto;
}

.media--report-checkbox {
    padding-left: 30px;
    position: relative;
    .custom-option {
        @include absolute(top 20px left 0);

    }
}


 .tab-content {
     padding:30px 15px;
     @include media-breakpoint-up(md){
        padding: 30px 0px; 
     }
 }