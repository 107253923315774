.sidebar-navigation {
  background: $white;
  padding: $spacer;
  position: sticky;
  top: 50px;
  .menu li a {
    align-items: center;
    display: flex;
    padding: ($spacer*.5) 0;
    svg {
      fill: $body-color;
      margin-right: $spacer*.5;
    }
    &:hover {
      background: none;
      svg {
        fill: $primary;
      }
    }
    &.active {
      background: none;
      color: $primary;
      svg {
        fill: $primary;
      }
    }
  }
  .progress {
    box-shadow: 1px 0px 2px rgba(0, 0, 0, 0.1);
    height: 16px;
    &-bar {
      line-height: 16px;
      font-size: 12px;
    }
    &-note {
      color: $primary;
      margin-top: $spacer*.5;
      font-size: 14px;
    }
  }
}