.form-switch {
    display: flex;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  .form-switch i {
    position: relative;
    display: inline-block;
    width: 36px;
    height: 20px;
    background-color: #8391A6;
    border-radius: 23px;
    transition: all 0.3s linear;
  }
  .form-switch i::after {
    content: "";
    position: absolute;
    left: 0;
    width: 16px;
    height: 16px;
    background-color: #fff;
    border-radius: 50%;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.24);
    transform: translate3d(2px, 2px, 0);
    transition: all 0.2s ease-in-out;
  }
  
  .form-switch input { display: none; }
  
  .form-switch input:checked + i { background-color: #0274D6; }
  
  .form-switch input:checked + i::after { transform: translate3d(18px, 2px, 0); }