.userMeta {
    &__title {

        @at-root .cards--clinic & {
            @include media-breakpoint-up(lg){
                --width:30vw;
            }
        }

        @at-root .section-doctors & {
            --size : 100%;            
            white-space: pre-wrap;
        }

    }
}

 