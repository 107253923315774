.sidebar-action {
  @include media-breakpoint-down(sm) {
    height: 100%;
    overflow: auto;
    z-index: 9999;
    background: $white;
    width: 100%;
    padding: 20px;
    opacity: 0;
    @include fixed(top -100% left);
    @include transition(all, 0.4s, ease);
  }
}

.sidebar-mobile {
  top: 0 !important;
  opacity: 1 !important;
}

.blue-btn {
  background: $blue;
  border-radius: 3px;
  color: $white;
  display: block;
  max-width: 100%;
  margin: 0 auto;
  width: 480px;
  padding: 15px;
  text-align: center;
  @extend %dropshadowblue;

  @include transition(all, 0.3s, ease);
  &:hover {
    background: darken($blue, 10%);
    color: $white;
    text-decoration: none;
  }
}


.search-page {
  padding: 10px 0 20px 0;

  @include media-breakpoint-up(sm) {
    padding: 60px 0;
  }
}

.search-title {
  border-bottom: 1px solid $gray-300;
  color: $gray-900;
  display: flex;
  font-size: rem(16);
  font-weight: 400;
  justify-content: space-between;
  margin-bottom: 15px;
  padding-bottom: 15px;

  .fas {
    cursor: pointer;
    font-size: rem(18);
  }
}

.search-listing {
  &__header {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
  }

  &__header-sort,
  &__header-hl {
    position: relative;

    select {
      background: none;
      border: none;
      padding: 5px 20px 10px 0;
      position: relative;
      @include x-appearance (none);
      z-index: 2;

      &:focus {
        outline: none;
      }
    }

    &:after {
      content: "\f107";
      display: block;
      font-family: Font Awesome\ 5 Free;
      font-weight: 900;
      font-size: rem(16);
      z-index: 1;
      @include absolute(top 6px right);
    }
  }
}

.search-doctors-fields {
  $P: &;

  &--search-form {
    .form-group {
      margin-bottom: 30px;

      .fa-search {
        cursor: pointer;
        color: $gray-500;
        pointer-events: inherit;
      }
    }

    #{$P}__search {
      background: none;
      border: none;
      border-bottom: 1px solid $gray-300;
      padding: 10px 35px 10px 15px !important;
      @include placeholder-color($blue);
    }
  }

  .position-relative img {
    position: absolute;
    right: 10px;
    top: 18px;
    width: 16px;
  }
}

.search-doctors-fields {

  input:not([type="submit"]),
  input:not([type="reset"]),
  select {
    height: rem(52) !important;
    padding-right: rem(30);
    padding-left: rem(30);
    appearance: none;
    @include placeholder-color($gray-500);

    &~.fas {
      @include absolute(top 20px right 15px);

      font: {
        size: rem(14);
      }

      ;
      pointer-events:none;
      color:$gray-600;
    }
  }

  &__search {
    @include media-breakpoint-up(md) {
      box-shadow: none !important;
      border-top-right-radius: 0px;
      border-bottom-left-radius: 0px;
      border-right: none;
    }
  }

  &__specialization {
    @include media-breakpoint-up(md) {
      box-shadow: none !important;
      border-top-right-radius: 0px;
      border-bottom-left-radius: 0px;
    }
  }

  button[type="submit"],
  input[type="submit"] {

    @include media-breakpoint-up(md) {
      box-shadow: none !important;
      border-top-left-radius: 0px;
      border-bottom-left-radius: 0px;
    }
  }


}

// customized radio accordion header styling
.radio_accordion {
  border: 1px solid $gray-300;
  border-radius: rem(4);
  margin-bottom: 20px;

  .btn-link {
    width: 100%;
    text-align: left;
    padding: 15px 10px;

    .acc-title {
      color: $gray-900;
      float: left;
      font-size: rem(16);
    }

    .acc-arrow {
      color: $gray-900;
      float: right;
    }
  }

  .btn-link.collapsed .fa-angle-down {
    display: none;
  }

  .btn-link .fa-angle-down {
    display: inline-block;
  }

  .btn-link .fa-angle-right {
    display: none;
  }

  .btn-link.collapsed .fa-angle-right {
    display: inline-block;
  }

  .collapse {
    border-top: 1px solid $gray-300;
  }

  .pricing-slider {
    padding: 10px 15px;

    img {
      max-width: 100%;
    }
  }
}

// customized radio accordion header styling ends