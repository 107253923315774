// .media-thumbnail {
// >img {
//     display: block;
//     max-width: 100%;
//     height: auto !important;
//     object-fit: cover;
//     position: absolute;
//     top: 50%;
//     transform: translateY(-50%) translateX(-50%);
//     left: 50%;
// }
// }


.ratio-16by9{
    @include responsive-ratio(16,9);
    position: relative;

    img {
        width:100%;
        height:100%;
        object-fit: cover;
        @include absolute(top 0 left 0)
    }
}

.embed-responsive {
    img {
        max-width: 100%;
        @include absolute(top 0 bottom 0 left 0);
        width:100%;
        height: 100%;
        object-fit: cover;
    }
    &-1by1 {
        padding-bottom: 100%;
    }
}