.badge {
    $p:&;
    font-size:$font-size-base;
    padding:6px 20px;
    &-primary {
        background-color: $primary;
    }
    &-success {
        background-color: $green;
    }
    &-xs {
        font-size:12px;
        padding:3px 10px;

    }

    &-variant {
        color:$gray-800;
        // font-weight: 600;
        &#{$p}-primary {
            background-color: lighten($primary, 45%);
            color: $primary;
        }
        &#{$p}-warning {
            background-color: lighten($orange, 30%);
            color: $warning;
        }
    }
}