.inlineItems {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  
}
 
  .inlineItems {
    margin: 0 (-$spacer*.25);
    li {
      padding: $spacer*.25;

      
    }
  }

 
.cols-4 {
  .inlineItems {
    li {
      width: 25%;
      img {
        @extend %imgobjectcover;
      }
    }
  }
}

.widget-section {
  .menu {
    li {
      a {
        color:$gray-800;
      }
     }
  }
}

.footer-bottom {
  padding-top: rem(15);
  @include media-breakpoint-down(sm){
  display: none;
  }

  p {
    font-size: rem(14);
  }
  .social-links {
    @include media-breakpoint-down(sm){
      margin:0 auto;
    }
    li {
      margin:0 $spacer *0.25;
      a {
      font-size: rem(14);
      padding:6px;

    }
  }
  }
}

.widget {
   a.title {
    font-size: 18px;
   } 
  .collapse:not(.show){
    @include media-breakpoint-up(sm){
      display: block;
    }
  }
}

.widget-section {
	@include media-breakpoint-down(sm){
		padding:0px !important;
	}
}