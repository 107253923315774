.sidebar {

    &-navigation {
        // box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.05);
        // border:1px solid #eee;
        padding:0 !important;
        > * {
            padding:15px 20px !important;
            margin-bottom: 0 !important; 
          }

          .menu {
            padding-top: 8px !important;
        }
    }
    &__feature-block {
        margin-bottom: em(15);
        padding-bottom: em(15);
        border-bottom: 1px solid #eee;
        border-top: 1px solid #eee;
        color:$primary;
        position: relative;

        &:before {
            @include absolute(top 0 left 0 right 0 bottom 0);
            content: " ";
            opacity: 0.05;
            background-color: currentColor;
            background-image: linear-gradient(to bottom, currentColor, #fff);
        }

        &__title {
            color: currentColor;
            display: flex;
          margin-bottom: 0;
          font-weight: bold;
          align-items: center;

            svg {
                fill: currentColor;
                margin-right: 8px;
                font-size: 20px;
            }
        }
    }

    
}



.profile-progress-wrap {
    display: flex;
    flex-direction: column;
    
    strong {
        display:block;
        font-weight: normal;
        margin-bottom:15px;

        + a.btn-info {
            position: absolute;
            top:0;
            right: 0;
        }
    }
    p{
        position: relative;
        margin-bottom: 0 !important;
        ~ p {
            display: none;
        }
    }

    .progress-note {
        order:1;
         margin:0 0 15px 0 !important;
        font-weight: normal;
        font-size:16px !important;
        color:#343a40 !important;
    }
    .progress {
        order:2;  
       
    }
}

.user-media.--user-sidebar,
.profile-progress-wrap {
    border-color:#eee;
    border:0;
}

.user-media.--user-sidebar {
    // background-color: $gray-100;
   
}