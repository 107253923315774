.postcard {
  background: $white;
  margin: 0 0 30px;
  box-shadow: 0px 6px 16.2px 1.8px rgba(224, 224, 224, 0.67);

  .entry-figure {
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .entry-main {
    border-bottom: 1px solid $gray-200;
    margin: 0 15px;
  }

  .entry-title {
    font-size: rem(18);
    font-weight: 500;
    line-height: 1.5;
    margin: 10px 0;
  }

  .entry-date {
    color: $gray-500;
    margin-bottom: 10px;
  }

  .entry-footer {
    align-items: center;
    display: flex;
    padding: 15px;
    justify-content: space-between;
  }

  .entry-author {
    display: flex;
    align-items: center;

    .author-img {
      width: 35px;
      height: 35px;
      border-radius: 50%;
      overflow: hidden;
      margin: 0 10px 0 0;
    }

    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }

    .author-name {
      font-weight: 500;
      font-size: rem(14);
    }
  }

  .entry-action a {
    color: $gray-600;
    margin-left: 10px;
  }
}

.sharepost {
  transition: all 0.3s ease 0s;
  &:hover {
    .shareoptions {
      max-height: 100px;
      opacity: 1;
      visibility: visible;
    }
  }
}
.shareoptions {
  background: $white;
  border: 1px solid $border-color;
  display: flex;
  align-items: center;
  @include absolute(right 0);
  max-height: 0;
  transition: all 0.3s ease 0s;
  opacity: 0;
  visibility: hidden;
  &__item {
    cursor: pointer;
    display: inline-flex;
    margin: $spacer*.5;
    &:hover {
      fill: $primary;
    }
  }
}