.cardCont {
  align-items: center;
  display: flex;
  margin: ($spacer*2) 0;
  &.profilepic {
    justify-content: center;
    flex-wrap: wrap;
    @include media-breakpoint-up(md) {
      justify-content: flex-start;
      flex-wrap: nowrap;
    }
  }
  .profileimage {
    flex: 0 0 auto;
    margin: 0 0 ($spacer*2);
    position: relative;
    @include media-breakpoint-up(md) {
      margin: 0 ($spacer*2) 0 0;
    }
  }
  &__article {
    @include media-breakpoint-up(md) {
      flex: 0 1 auto;
    }
  }
}

.facebookbtn {
  background: #3B5998;
  color: #fff;
  display: flex;
  align-items: center;
  padding: 10px 20px;
  font-weight: 600;
  border-radius: 3px;
  svg {
    fill: white;
    width: 30px;
    height: 30px;
    margin-right: 10px;
  }
  &:hover {
    background: #293F6B;
    color: $white;
  }
}

.btnwrap .postblock__attachment-icon {
  border: 1px solid $border-color;
  display: flex;
  margin: 0;
  margin-top: 20px;
  align-items: center;
  justify-content: center;
  padding: 12px;
  border-radius: 3px;
  i {
    @include font-size($font-size-base*1.5);
    margin-right: $spacer*.5;
  }
  label {
    color: $body-color;
    display: inline-block;
    @include font-size($font-size-base);
    font-weight: 600;
  }
  &:hover {
    background: $primary;
    border-color: $primary;
    color: $white;
    i {
      color: $white;
    }
    label {
      color: $white;
    }
  }
}




.icon--profilesetupnotificitionicon {
  fill: $primary;
  width: 50px;
  height: 50px;
}
#toggleCont {
  align-items: center;
  cursor: pointer;
  width: 100%;
  justify-content: flex-start;
  position: relative;
  &:focus {
    outline: none;
  }
  svg {
    width:30px;
    height: 30px;
    margin-right: $spacer*.5;
  }
  .icon--closeicon {
    @include absolute(top 5px right 0);
    margin: 0;
    width: 15px;
    height: 15px;
  }
}
#toggleCont_article {
  display: none; 
  padding-top: $spacer;
  @include media-breakpoint-up(md) {
    display: block;
    padding-top: 0;
    position: sticky;
    top:20px;
  }
  svg {
    display: none;
    @include media-breakpoint-up(md) {
      display: block;
    }
  }
}
.stickytop {
  @include absolute(top 2px right 30px);
  @include media-breakpoint-up(md) {
    right: 5px;
  }
}
.profilesetup {
  &__header {
    position: relative;
    &-progresstitle {
      display: flex;
      justify-content: space-between;
      margin-bottom: $spacer*.5;
    }
  }
  .cards {
    position: relative;
  }
  .block-infographics {
    padding: $spacer;
    justify-content: flex-start;
    @include media-breakpoint-up(md) {
      padding: $spacer*2.5;
    }
  }
  &__card-foot {
    a {
      margin-right: $spacer*.5;
    }
  }
}
.icon--closeicon {
  display: none;
}