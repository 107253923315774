.navigation {
    $p:&;

    &.sticky {
        position:sticky;
        top:0;
        z-index: 50;
    }
    &--secondary {
        background-color: #F5FCFF;
        border-bottom: 1px solid $border-color;

        .menu {
            display: flex;
            justify-content: space-between;
            margin-bottom: 0px;
            overflow-x:auto;
            padding-bottom: 10px;
            @include media-breakpoint-up(md){
                padding-bottom: 0;
            }
          
            >li {
                flex:1 0 auto;
                a {
                    padding:em(20) em(12);
                    position: relative;
                    display: block;
                    text-transform: uppercase;
                    line-height: 1;
                    color:$gray-700;
                    text-align: center;
                    &:after {
                        content:" ";
                        @include absolute(bottom 0 left 0 right 0);
                        transform:scaleX(0);
                        height:2px;
                        background-color: currentColor; 
                        transition: all 0.3s ease-in-out;
                    }

                    &.active, &:hover {
                        &:after {
                            transform:scaleX(1);
                        }
                    }
                    &.active {
                        color:$primary;
                        font-weight: 600;
                    }
                    &:hover:not(.active) {
                        &:after {
                            opacity: 0.2;
                        }
                    }
                }
            }

        }
    }
}