.custom-select {
  position: relative;
  margin: 1rem 0;
  &__select {
    // background: transparent;
    width: 100%;
    height: 44px;
    display: block;
    border: 1px solid $border-color;
    border-radius: 0;
    padding: ($spacer*.5) ($spacer*2) ($spacer*.5) ($spacer*.5);
    outline: none;
    text-overflow: ellipsis;
    -webkit-appearance: button;
    -moz-appearance:none &::-ms-expand {
      display: none;
    }
    .custom-select--with-icon & {
      padding-left: 3rem;
    }
  }
}

.custom-select .select-icons {
  align-items: center;
  display: flex;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  user-select: none;
  cursor: pointer;
  pointer-events: none;
}

.custom-select__icon {
  left: 1rem;
}

.custom-select__arrow {
  right: 1rem;
}