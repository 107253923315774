.social-icons{
    display:grid;
    grid-template-columns: repeat(auto-fit, minmax(20px, min-content));
    grid-gap:10px;
    a {
        display: inline-block;
        svg {
            font-size: 35px;


        }
    }
}