 
.category-max-height {
        max-height:300px;
        @include media-breakpoint-up(md) {
            max-height:600px;
        }
       
        overflow-y:auto;
    }
 
.custom-option-block--category{
    .custom-option--block {
        display: block;
    }
.custom-option__item {
    position: relative;
    cursor: pointer;
    .card {
        &:before, &:after {
            content: " ";
            position: absolute;
            width: 20px;
            height: 20px;
            left: 15px;
            border-radius: 50%;
            top:15px;
        }
        &:before {
            background-color: #EEEEEE;
            transition: all 0.3s;
        }
        &:after {
            display: flex;
            -ms-flex-align: center;
            align-items: center;
            -ms-flex-pack: center;
            justify-content: center;
            content: "\f00c";
            font-family: fontawesome;
            font-size: 10px;
            opacity: 0;
            transition: all 0.3s;
        }
    }

    input:checked ~ {
        .card {
            &:before {
                background-color: var(--activeColor);
                
            }
            &:after {
                color: white;
                opacity: 1;
            }
        }
    }
    input:disabled ~ {
        .card {
            opacity: 0.4;
            // background-color: #f9f9f9;
				cursor: not-allowed;
            &:before {
                background-color: #EEEEEE;
                
            }
            &:after {
                display:none;
            }
        }
    }
}
}


//appointment type
.wrap-box {
    input{
        &:disabled ~ {
           * {
              opacity: 0.2;
           }
           .overlay-content {
               min-height: 0px !important;
            label {
                cursor: default;
            }
               
           h2:after {
               display: none;
           }
           }
            
           
        }
    }
}