.activities {
    padding:0px;
    margin:0px;

    &--short-lists {
        max-height: 200px;
        overflow-y:auto;
    }

    &__item {
        display:flex;
        justify-content: space-between;
       @include spacer(padding, y, 15px);
        @include font-size(14);
        flex-wrap: wrap;
        &:not(:last-child){
            border-bottom:1px solid #eee;

        }
    }
}