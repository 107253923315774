.clinicsetup {
  &-head {
    margin-bottom: $spacer*2;
    margin-top: $spacer*2;
  }
  &-body {
    margin-bottom: $spacer*2;
  }
  .templatelists {
    &__block {
      background: $white;
      box-shadow: none;
      border: 1px solid $border-color;
    }
    li {
      margin: ($spacer*.5) 0;
      label {
        font-weight: 600;
      }
    }
    &__actions {
      display: flex;
      li {
        border-top: 1px solid $border-color;
        label, a {
          display: flex;
          justify-content: center;
          margin: 0;
          font-weight: 400;
        }
      }
    }
  }
}
.cliniclogo {
  $C : &;
  &__select {
    margin: ($spacer) 0 ($spacer*.5);
    display: block;
  }
  &__img {
    border: 1px dotted $border-color;
    display: flex;
    justify-content: center;
    padding: $spacer*.5;
    max-width: 300px;
    position: relative;
    span {
      display: block;
    }
    img {
      display: none;
      @extend %imgobjectcover;
    }
    #removeImg {
      color: $white;
      display: none;
      @include absolute(top -10px right -10px);
    }
  }
  &.clinicimage {
    #{$C}__img {
      display: inline-flex;
      span {
        display: none;
      }
      img {
        display: block;
      }
      #removeImg {
        display: flex;
      }
    }
  }
}
.clinicslider {
  &__img {
    border: none;
    padding: 0;
    max-width: inherit;
  }
}
#accordionSlider {
  .card {
    border: 1px solid $border-color;
    &-head {
      h2 {
        padding: $spacer*.75;
      }
    }
    &-body {
      border-top: 1px solid $border-color;
      padding: $spacer;
    }
  }
  .clinicsetup-head {
    margin-top: 0;
  }
  .clinicsetup-body {
    margin-bottom: 0;
    textarea {
      height: 100px;
      resize: none;
    }
  }
}
.media--clinic {
  &-top {
    .media-thumbnail {
      height: 60px;
      width: 100%;
      border-radius: 0;
      margin-bottom: $spacer*.75;
    }
  }
}