@keyframes circleFadeOut {
  0% {
    background: rgba($white, 1);
    transform: scale(0);
    opacity: 0;
  }
  50% {
    background: rgba($primary, 0.2);
    transform: scale(1.5);
    opacity: 1;
  }
  100% {
    background: rgba($white, 1);
    transform: scale(3);
    opacity: 0;
  }
}