.enterprisepricing {
  padding: ($spacer) 0;
  background: $white;
  @include media-breakpoint-up(md) {
    padding: ($spacer*3) 0;
  }
  .inline-iconlist {
    margin-bottom: $spacer*2;
  }
  .btnwrap {
    text-align: center;
    margin-top: $spacer*2;
  }
  &-howitworks {
    background: #FBFBFB;
  }
}
.inline-iconlist {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 0 (-$spacer*.75);
  $p : &;
  &__title {
    align-items: center;
    @include font-size($font-size-lg);
    font-weight: 600;
    display: flex;
    .circlecheck {
      margin-right: $spacer*.5;
    }
  }
  li {
    max-width: 100%;
    padding: $spacer*.75;
    flex: 1 0 100%;
    @include media-breakpoint-up(sm) {
      max-width: 50%;
    }
    @include media-breakpoint-up(md) {
      max-width: 25%;
    }
  }
}