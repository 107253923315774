// Supporting variables 
$border-color: #DBDBDB;
// $green: #AFEEA5;
// $blue: #7fd7f7;
// $pink: #F89BC9;


.pricingtablecontainer {
  display: flex;
  flex-wrap: nowrap;
  overflow: auto;
  // margin: 0 -10px;
  padding: 30px 0;
  scroll-snap-type: x mandatory; /* Chrome Canary */  
  scroll-snap-type: mandatory; /* Firefox */  
  -ms-scroll-snap-type: mandatory; /* IE/Edge */  
  -webkit-scroll-snap-type: mandatory; /* Safari */  
  -webkit-scroll-snap-destination: 0% 0%;  
  -webkit-overflow-scrolling: touch; /* important for iOS */ 
  display:grid;
 grid-template-columns: repeat(3, minmax(300px, 1fr));
 grid-gap: 15px; 
 grid-auto-flow: column;
}
.pricingtable {
  padding: 0px;
  border: 1px solid $border-color;
  border-radius: 5px;
  background-color: #fff;
  // max-width: 33%;
  // min-width: 250px;
  flex: 1 0 100%;
  scroll-snap-align: start; /* latest (Chrome 69+) */  
  scroll-snap-coordinate: 0% 0%; /* older (Firefox/IE) */  
  -webkit-scroll-snap-coordinate: 0% 0%; /* older (Safari) */  
  $P : &;
  display:flex;
  flex-direction: column;
  // &__header, &__footer {
  //     flex:0 0 auto;
  // }
  // &__body {
  //     flex:1 1 auto;
  //     max-height: 500px;
  //     overflow-y: auto;
  // }

  &__body {
    margin-top:1.25rem;

   @at-root #{$P}__header + &  {
      margin-top:0px;
    }
  }

  &__footer {
    padding-bottom: 20px;

    .btn-box + #{$P}__btn {
      margin:15px 0 0 0;
      padding:0px;
    }
  }
  &.js-priceHighlights{

    .btn-box {
      position: relative;
      &:before {
        @include absolute(bottom 100% left 0);
        content: " ";
        height:80px;
        width:100%;
        background:linear-gradient(0deg, #fbfbfb 20%, transparent 100%)
      }
    }

   
  }
 
  .js-extra {
display: none;
   
  }
  .yearlypricing {
    display: none;
  }
  &__listBlock {
    ul {
      padding-top:0 !important;
      li {
        margin: 0px;
      }
    }
    &__title {
      background-color: $gray-200;
      padding:15px 20px;
      font-weight: bold;
      margin-bottom: 0;
      text-transform: capitalize;
      margin:0 10px;
    }
  }
  ul {
   
    // position: relative;
    padding: 10px;
  }
  li {
    display: flex;
   
    padding: em(15) em(10);
    align-items: center;
    &:not(:last-child){
      border-bottom: 1px solid $border-color;
    }
    
    justify-content: space-between;
    &.disable {
      opacity: 0.5;
    }
    &#{$P}__head {
      border: none;
      text-transform: uppercase;
      justify-content: center;
      padding: ($spacer) ($spacer*.5);
      @include font-size($lead-font-size);
      small {
        font-size: 14px;
        margin-left: $spacer*.25;
        text-transform: lowercase;
      }
    }
    &#{$P}__highlight {
      border: none;
      color: $white;
      padding: $spacer*.5;
      font-size: rem(24);
      justify-content: center;
      margin-bottom: 0;
    }
  
  }
  #{$P}__btn {
    border: none;
    display: flex;
    justify-content: center;
    margin: $spacer 0;
  }
.basic, .premium, .pro {
  position: sticky;
    top: 0;
    background: white;
    z-index: 100;
    margin-bottom:0px;
}
 
  .basic {
    #{$P}__highlight {
      background: rgba(map-get($pricing-color, 'basic'), 0.5);
    }
  }
  .pro {
    #{$P}__highlight {
      background: rgba(map-get($pricing-color, 'pro'), 0.5);
    }
  }
  .premium {
    #{$P}__highlight {
      background: rgba(map-get($pricing-color, 'premium'), 0.5);
    }
  }
}


// toggle styling
.slideToggle{
  display: flex;
  justify-content: center;
  margin: 50px 0;
  i {
    margin: 0 15px;
  }
}
.form-switch {
  align-items: center;
  display: flex;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  justify-content: space-between;
  margin-bottom: 0;
}
.form-switch i {
  position: relative;
  display: inline-block;
  width: 100px;
  height: 30px;
  border: 1px solid #DFDFDF;
  border-radius: 15px;
  transition: all 0.3s linear;
}
.form-switch i::after {
  content: "";
  position: absolute;
  left: 0;
  width: 40px;
  height: 22px;
  background-color: #00CE6C;
  border-radius: 15px;
  transform: translate3d(4px, 3px, 0);
  transition: all 0.2s ease-in-out;
}

.form-switch input { display: none; }

// .form-switch input:checked + i { background-color: #0274D6; }

.form-switch input:checked + i::after { transform: translate3d(54px, 3px, 0); }