.form-switch {
    span {
        display:none;
        &.disabled {
            display:inline-block;
        }
    }
    input:checked {
        ~ div  {
            .enabled {
                display:inline-block;
            }
            .disabled {
                display: none;
            }
            
        }
    }
}

// clinic schedule

.time-tab {
  

    .ui-tabs-anchor {
        padding: 10px 0 !important;
        @include media-breakpoint-up(md){
            padding: 20px 22px;
        }
       
        display: block;
        color: $gray-900;
        margin-bottom: 0px;
        font-size: 16px;
        font-weight: normal;
        background:transparent !important;
        border-radius: 0px;

        &:hover {
            border:none;
        }
        + div {
            display:none;
        }
    }

    .tab-list {
        width:100%;
        background-color: #fff !important;
        box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.1);
        padding: 0 !important;
        margin-bottom: 20px !important; 
        >li {
            width:calc(100%/7);
            border-radius: 0px;
        }
    }

    .ui-state-active {
        a {
            background-color: $primary !important;
        }
        

    }
    .ui-state-default {
        background-color: #fff !important;
    }
}

.list-hint {
    padding:0px;
    margin:0px;
    display: flex;
    align-items:center;
    margin:0 -6px;
    flex-wrap: wrap;
    position: relative;
    padding-left: 30px;
    &:before {
        content: url(../images/info-icon.svg);
        @include absolute(top 6px left)
    }
    >li {
        padding:6px;
        font-size: rem(14);
    }

    &__icon {
        --size:14px;
        width:var(--size);
        height:var(--size);
        border:1px solid transparent;
        display:inline-block;
        &--scheduled {
            background-color: rgba($primary, 0.15);
            border-color:$primary;
        }
        &--unscheduled {
            background-color: #fff;
            border-color:$gray-700;
        }
        &--booked{
            background-color: rgba($green, 0.15);
            border-color:$green;

        }
    }
}

.schedule-block {
    .tabcontent {
        @include media-breakpoint-down(md){

            padding:0px;
        }
    }
}