.btn {
    font-weight: 600;
    border-radius: 2px;
    &-default {
        background-color: #F5F5F5;
        &:hover, &:focus, &:active {
            background-color: #F0F0F0; 
        }
    }
}

.btn-file {
 
    input[type="file"]{
        position: absolute;
        z-index: -1;
        left: -9999px;
        opacity: 0;

        + .fa {
            margin-right: 6px;
        }
    }
}

.btn-toggle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color:var(--bodycolor);
    padding:0px;
    margin-bottom: 20px;
    .vital-records__title {
        margin-bottom: 0;
    }
    .fa {
        transform:rotate(180deg);
        transition: all 0.3s ease-in-out;
        margin-right: 8px;
    }
    &[aria-expanded="true"]{
        .fa {
            transform:rotate(270deg);
        }
    }
}

.btn-icon {
    display: inline-flex;
    align-items: center;
    text-decoration-style: dashed;
    text-decoration-color: $primary;
    text-decoration-thickness: 0.125em;
    text-underline-offset: 1.5px;

    svg {
        fill: $primary;
        margin-left: 5px;
    }
}