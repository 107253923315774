.bubble-info {
    background-color: #D8F2FC;
    padding:rem(10);
    font-size: rem(14);
    position: relative;
    display: none;
    @include media-breakpoint-up(md){
        display:inline-block;
    }

    &[data-direction] {
        &:before {
            
   
        width:20px;
        height:20px;
        margin:0 auto;
        background-color: inherit;
        content: " ";
        clip-path: polygon(0% 100%, 100% 100%, 0% 0%);
        
        }
    }

    &[data-direction="left"]{
        &:before {
            @include absolute (right 100% top 50%);
            transform: rotate(45deg)  translateY(-50%);
        transform-origin:center;
        margin-right: -5px;

        }
    }
    }