.dotted-box {
  border: 1px dashed $primary;
  padding: $spacer*2;
}

.howitworks {
  &__inner {
    padding: $spacer;
    @include media-breakpoint-up(md) {
      padding: ($spacer*2) ($spacer*4);
    }
    .loader-cont {
      @include media-breakpoint-up(md) {
        max-width: 80%;
        margin: 0 auto;
      }
    }
  }
}

.howcard {
  margin: ($spacer*2) 0 0;
  .card {
    background: #FCFCFC;
    border: 1px solid #E9E9E9;
    padding: $spacer;
    text-align: center;
    margin: 0 0 ($spacer) 0;
    @include media-breakpoint-up(md) {
      min-height: 350px;
    }
    @include media-breakpoint-up(lg) {
      margin: 0;
    }
    &__image {
      align-items: center;
      display: flex;
      max-width: 150px;
      margin: 0 auto;
      height: 150px;
      justify-content: center;
      img {
        max-width: 100%;
        height: auto;
      }
    }
    &__title {
      margin: ($spacer) 0 ($spacer*.5);
      @include font-size($font-size-lg);
    }
  }
}