.row {
    &:before, &:after {
        display: none;
    }
}

.tbl-block {
    @extend .row;
      width: unset;
    &__cell {
      position: relative;
   
      padding-right: 15px;
      padding-left: 15px;
      display: block;

      @include media-breakpoint-down(md){
             width: 100%;
      }
    }
}
