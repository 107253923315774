.media {
  .--media-time {
    margin-bottom: $spacer*.5;
    display: flex;
    align-items: center;
    svg {
      fill: $gray-700;
      margin-right: $spacer*.5;
    }
  }
  .status {
    font-weight: 500;
  }
  .online {
    color: $green;
  }
  .offline {
    color: $red;
  }
}
.associatedcard {
  .tableBlock__item-cont:first-child {
    max-width: initial;
    @include media-breakpoint-up(sm) {
      max-width: 120px;
    }
  }
  .tableBlock__item-cont:nth-child(2) {
    flex: 1 0 auto;
    @include media-breakpoint-up(sm) {
      flex-basis: 0;
    }
  }
  .tableBlock__item {
    align-items: center;
    position: relative;
    .bordered-btn {
      border: 1px solid;
      padding: 3px 15px;
      font-size: 14px;
      border-radius: 3px;
      @include absolute(top 0 right 0);
      @include media-breakpoint-up(sm) {
        position: initial;
      }
    }
  }
  .tableBlock__item-list {
    flex-direction: column;
    align-items: flex-start;
  }

  .tableBlock__item-list h3 {
      font-size: 16px;
      margin: 0;
      font-weight: 600;
  }
  .tableBlock__item-cont:nth-child(3) {
    margin-bottom: auto;
    @include media-breakpoint-up(sm) {
      margin-bottom: $spacer*.5;
    }
  }
  

  
}
.highcharts-root {
  width: auto;
  height: auto;
}