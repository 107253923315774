 
$colors-list: #26A69A #29B6F6 #26C6DA #EF5350 #EC407A  #7E57C2 #5C6BC0 #78909C #8D6E63 #FFEE58 #FFA726 #26C6DA;
 
 

@each $current-color in $colors-list {
    $i: index($colors-list, $current-color);
    .media--report:nth-of-type(12n + #{$i}) { 
        .media__thumbnail {
            background-color: $current-color;
        }
        
    }
}

.media--report {
    .media__thumbnail {
        @include font-size(14px);
        font-weight: 600;
        text-transform: uppercase;
        color: white;
       
            --size:50px !important;
            @include media-breakpoint-up(md){
              --size:100px !important;
              @include font-size(20px);
            }
           

        svg {
            fill: white;
            font-size: 14px;
            @include media-breakpoint-up(md){
            font-size: 40px;
            }
        }
    }

    .media__title {
        color:$gray-900;
        font-weight: 600;
        a {
            color:inherit;
        }
    }

    &.report-shared {
        .media__header {
            flex-wrap: wrap;
            @include media-breakpoint-up(sm){ 
                flex-wrap: nowrap;

            }
        }
    }
}


.report-list { 
    display:flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding:25px 0 25px 40px;
    position: relative;
    &:not(:last-child){
        border-bottom: 1px solid $border-color;
    }
    .custom-option {
         position: absolute;
         top:10px;
         left:0;
    }
    &__content {
        margin-right:auto;
        @include media-breakpoint-up(sm){
            flex: 0 1 60%;
        }
    }
    &__title {
        font-size: 16px;
        line-height: normal;
    margin-top: -4px;
        label {
            font-size: inherit;
            margin:0px;
            // font-weight: normal;
            cursor: pointer;
        }
    }
    .fancygallery {
        display:inline-flex;
        align-self: flex-start;
        img {
            margin-left:10px;
            flex:0 0 14px;
            
        }
    }
}

.swMains .anchor > li a .stepNumber {
    --size:20px;
    width:var(--size);
    height:var(--size);
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
}

.swMains .anchor > li a .stepDesc{
    font-size: 14px;
    small {
        font-size: inherit;
    }
}
.swMains .anchor > li {
    width: auto;
}
.swMains .anchor > li a{
  @include spacer(padding, x, 6px !important)  
}