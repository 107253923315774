.payoutblock {
  &--empty {
    min-height: 200px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .plusicon svg {
      --svgSize: 40px;
      height: var(--svgSize);
      width: var(--svgSize);
      fill: $gray-500;
    }
    p {
      color: $gray-500;
      margin: ($spacer*.5) 0 ($spacer) 0;
    }
    .btnwrap {
      margin-top: 0 !important;
    }
  }
  &--methodlists {
    .tableblock__item-title {
      margin-top: 0;
      @include font-size($font-size-lg);
      font-weight: 600;
    }
    .tableblock__item {
      flex-basis: 0;
      flex-grow: 1;
      &:first-child {
        max-width: 40%;
      }
      &:last-child {
        justify-content: flex-end;
        align-items: flex-end;
      }
    }
    .tableBlock {
      min-width: 600px;
    }
  }
  &--history {
    .tableblock__item {
      flex-basis: 0;
      flex-grow: 1;
      padding: $spacer*.5;
      &:first-child {
        color: $gray-500;
        max-width: inherit;
        flex: 0 0 auto;
        font-size: 12px;
      }
      &:nth-child(2) {
        max-width: 45%;
        @extend %longtextellipsis;
      }
      &:nth-child(3) {
        max-width: 25%;
      }
      &:last-child {
        justify-content: flex-end;
        align-items: flex-end;
        flex: 0 0 auto;
      }
      .fancyclick {
        cursor: pointer;
      }
    }
    .tableBlock {
      min-width: 600px;
    }
  }
}
.paymentstatus[status="pending"] {
  color: $google;
}
.paymentstatus[status="paid"] {
  color: $success;
}
#payoutmethod {
  display: none;
}
.setting-payout {
  .radioOptions {
    margin-bottom: $spacer*2;
  }
  .custom-option--single .custom-option__item {
    border: none;
    padding: 0;
  }
  .custom-option--single input[type="checkbox"] ~ .custom-option__label:before {
    border-radius: 0;
  }
  .btnwrap {
    margin-top: $spacer*2;
  }
  .custom-option__item {
    @include media-breakpoint-down(md) {
      margin-bottom: $spacer*.5;
    }
  }
}
.filterbtns {
  display: flex;
  align-items: center;
  border-bottom: 1px solid $border-color;
  padding-bottom: $spacer;
  margin-bottom: $spacer;
  flex-wrap: wrap;
  .filteroptions {
    margin-top: $spacer;
    display: flex;
    flex-wrap: wrap;
    @include media-breakpoint-up(sm) {
      margin-left: $spacer;
      margin-top: 0;
    }
    &__item {
      border: 1px solid $border-color;
      color: $body-color;
      margin: ($spacer*.25);
      padding: ($spacer*.25) ($spacer*.75);
      border-radius: 3px;
      transition: all 0.3s ease-out 0s;
      @include media-breakpoint-up(sm) {
        margin-bottom: 0;
        margin-top: 0;
      }
      &:hover, &.active {
        background: $primary;
        border-color: $primary;
        color: $white;
      }
    }
  }
}
.detailpopup {
  > h3 {
    font-size: 14px;
    font-weight: 600;
    color: $gray-700;
  }
  > p {
    font-size: 14px;
  }
}

.detailcontent {
  display: flex;
  justify-content: space-between;
  margin: ($spacer*1.5) 0;
  align-items: flex-start;
  &__item {
    &-title {
      @include font-size($font-size-base);
      font-weight: 600;
    }
    &-subtitle {
      font-size: 14px;
    }
  }
}

.detailfoot {
  margin: $spacer 0;
  border-top: 1px solid $border-color;
  padding-top: $spacer;
  display: flex;
  justify-content: space-between;
  &__item {
    &-subtitle {
      font-size: 12px;
      color: $gray-600;
      display: block;
    }
    &-cont {
      font-size: 14px;
    }
  }
}
.tableBlock--responsive {
  overflow: auto;
}