html,
body {
    @include font-size($font-size-base);
    min-height: 100%;
    height: auto;
}
body {
   padding:0px;
   margin:0px;
}
* {
    @include customscrollbar();
}
.notopspace {
    padding-top: 0 !important;
    margin-top: 0 !important;
}
.flexblock {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
.flexright {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
}
iframe {
    max-width: 100%;
}
.btn-outline-secondary {
    background: $white;
    color: #6c757d;
    border: 1px solid #6c757d;
    padding: 6px 12px;
    &:hover {
        color: #fff;
        background-color: #6c757d;
        border-color: #6c757d;
    }
}

svg {
    width:1em;
    height:1em;
    @include font-size($font-size-base);
}
.mb-md-20 {
    @include media-breakpoint-up(md) {
        margin-bottom: $spacer;
    }
}

.mb-md-40 {
    @include media-breakpoint-up(md) {
        margin-bottom: $spacer*2;
    }
}

//sticky footer
.wrapper {
    display:flex;
    min-height: 100vh;
    flex-direction: column;
    #header,
    #footer {
        flex-shrink: 0;
    }
    .main-content {
        flex: 1 0 auto; 
    }
}

.text-medium{
    @include font-size(14px);
}
.color-light {
    color:$gray-500;
}

.page-content {
    display: flex;
    flex-direction: column;
    
}

.row-sm {
    @include spacer(margin, x, -7.5px); 
}

.box-bodered {
    border:1px solid $border-color;
    border-radius: 5px;
}

.isDisabled {
    color: currentColor;
    cursor: not-allowed;
    opacity: 0.5;
    text-decoration: none;
  }

  .stickyBlock {
      @include media-breakpoint-up(md){

          position:sticky;
          top:em(20);
      }
  }

  .box {
      background-color: $gray-100;
  }

  .font-size-14 {
      font-size: rem(14);
  }
  .embed-responsive {
    padding-bottom: 56.25%;
  }


