.table-price {
    width:100%;
    font-size: 15px;
	thead {
		border-bottom: 1px solid $border-color;
    }
    tfoot {
        border-top: 1px solid $border-color;

    }

    td, th {
        padding: 10px;
    }
}

.table {
    &-striped {
        tr:nth-of-type(odd){
            background-color: white !important;

        }
        tr:nth-of-type(even){
            background-color: rgba($primary, 0.1);

        }
        th, td {
            border:0 !important;
            padding:em(20) !important;

            img {
                margin:0 0.5rem;
                margin-left:0;
                ~ img {
                    margin-left:0.5rem;
                    margin-right:0;
                }
            }
           
        }
        thead th {
            background-color: $gray-100;
            font-size: 14px;
        }
    }

    &-align-left {
         th:last-child, td:last-child {
            text-align: left !important;
        }
    }

    &-user-activities {
        img {
            --width:20px;
            max-width:var(--width);
            max-height:var(--width);
        }
    }
}