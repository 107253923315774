.pagination {
    display: flex;
    border: 1px solid #ccc;
    padding: 5px 10px !important;
    align-items: center;
    overflow-x: auto;

    .page-item {
        --size: 50px;
        width: var(--size);

        border: none;

        &:last-child {
            margin-left: auto !important;
        }

        &:first-child {
            margin-right: auto !important;
        }

        &:first-child,
        &:last-child {
            flex: 0 0 auto;

            .page-link {
                font-weight: bold;
            }

        }

        &:not(:first-child):not(:last-child) {
            padding: 4px !important;
        }

        .page-link {
            padding: 6px 10px !important;
            display: flex !important;
            height: auto !important;
            line-height: normal !important;
            align-items: center;
            justify-content: center;
            float: none !important;
            border: none;
            height: 40px !important;
            border-radius: 2px !important;
        }

        

        

        &.disabled {
            .page-link {
                cursor: not-allowed;
            }

        }

        &:hover:not(.active) {
            .page-link {
                border-radius: 2px !important;
                background-color: #f1f1f1 !important;
                color: #777 !important;
            }

        }

        &:active {
            .page-link {
                border-radius: 2px !important;
            }
        }
    }
}