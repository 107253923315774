.icon-lists {
    display:grid;
    grid-template-columns: repeat(auto-fit, minmax(80px, 1fr));
 
    grid-auto-rows: 80px;
    grid-gap: 10px;
   place-items:center;
   counter-reset: icons;


   > svg {
       font-size: 22px;
       background:#eee;
       width:100%;
       height: 100%;
       padding:20px;
       display: block;
       
   }


}