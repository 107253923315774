.fancypop {
    padding: 6px;
    >.closebtn {
        @include absolute(top 0 right 0);  
    }

    &-title {
        font-size: rem(16) !important;
        font-weight: 600 !important;
    }

    .content-block-heading {
        margin:0 15px;
        padding: 18px 0 !important;
        display: flex;
        justify-content: space-between;
        margin-bottom: 8px;
        &:before, &:after {
            display: none;
        }

        h1, h2, h3, h4, h5, h6 {
            font-size: rem(16) !important;
        font-weight: 600 !important;
        line-height: 26px;
        }
        .closebtn {
            position: static;
            flex: 0 0 auto;
            margin-top:2px;
            margin-left: 15px;
            padding:0px !important;
            font-size: inherit;
        
        }
    }

    &--flex {
        --height:100%;
        height: var(--height);
        &__container {
        height: var(--height);
        display:flex;
        flex-direction: column;
       

        }
        &__fullScreen {
            @include media-breakpoint-down(sm){
                @include absolute(top 0 left 0);
                width:100%;;
                height: 100%;
            }
        }
       
        .content-block {
            &-heading, &-footer {
                flex: 0 0 auto;
            }
            &-detail {
                flex:1 1 auto;
                overflow-y:auto;
            }
            &-footer {
                margin:0 15px;
                padding: 0 0 18px 0 !important;
             
            }
        }

        .content-block-heading {
            margin-bottom: 0;
        }
    }

    &__status {
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;

        &__icon {
            .fa {
                font-size: 30px;
            }
        }
        &__title {
            font-weight: 600;
            margin-top: 20px;
        }
    }

    
}

.confirm-dialog {
    &__title {
        font-weight: 600;
    }
}
.fancybox-wrap  {
    transition: all 0.3s ease-in-out;
}

.fancybox-overlay {
    background:rgba(black, 0.6) !important;
}