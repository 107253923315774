// floting button
 

.filter-wrap {
    img {
        max-width: 18px;
    }
    >a {
        background: $primary;
        border-radius: 50%;
        color: #fff;
        width: 50px;
        height: 50px;
        z-index: 999;
        font-size: rem(18);
        padding: 15px 17px;
        @include fixed(bottom 80px right 30px);
        display: flex;
        align-items: center;
        justify-content: center;
        @extend %dropshadowblue;
    
        @include media-breakpoint-up(lg){
            display: none;
          }
    
    }
    


}

.sidebar-mobile {
    top: 0;
    opacity: 1;
}



//floating filter menu 

.filter-sidebar {
    // position: sticky;
    // top: 60px;

    @include media-breakpoint-down(md){
        height: 100%;
        overflow: auto;
        z-index: 8010;
        background: #fff;
        width: 100%;
        padding: 15px;
        opacity: 0;
        position: fixed;
        top: -100%;
        left: 0;
        -webkit-transition: all 0.4s ease;
        transition: all 0.4s ease;
    }
    &__header {
        margin-bottom: 25px;
        margin-top: 10px;
        display:flex;
        justify-content: space-between;
        align-items: center;
        

        #sidebar_mob_close {
            display:none;
            color: black;
           
            @include media-breakpoint-down(md){
                display: inline-block;
            }
        }
    }
    &__title {
       
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 0;
    }

    .card__title {
        font-size: 16px;
    }

    .rating {
        float:none;
    }

    &--clinic {
        @include media-breakpoint-up(md){
            padding:30px 40px;
        }
    }
    
}
 