.feedback-btn {
    writing-mode: vertical-lr;
    display: inline-block;
    position: fixed;
    right: 0;
    top:50%;
    transform:translateY(-50%) ;
    padding:1.5em 0.5em ;
    letter-spacing: 1.5px;
    font-size: 14px;
    line-height: normal;
    

}