@include media-breakpoint-down(sm){
    .swMains {
 
        .anchor {
            display:flex;
            flex-wrap:nowrap;
               overflow-x: auto;
        overflow-y: hidden;
        padding-bottom:30px;
            > li {
                display:flex;
                a {
                    display:flex;
                    padding:30px;
                    align-items:flex-start;
                    flex-direction: column;
                     
                    .stepNumber {
                        flex:0 0 auto;
                        margin:0;
                    }
                    .stepDesc {
                       
                            padding-top:10px;
                            padding-left:0;
                            flex:1 1 100%;
                            display:block;
                            width:100%;
                            display:flex;
                            white-space: nowrap;
                             
                            
                         
                    }
                }
            }
        }
    }
}