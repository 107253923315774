.addblogpage {
  padding: ($spacer*2) 0;

  .sidebar {
    .custom-option__item {
      display: block;
      margin: ($spacer*.5) 0;
    }

    .categorylist {
      height: 170px;
      overflow: auto;
    }
  }
}

.setprofileimage {
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.16);
  position: relative;
  margin-bottom: $spacer;

  img {
    @extend %imgobjectcover;
  }

  .postblock__attachment-icon {
    background: $white;
    padding: 0 !important;
    @include absolute(left 10px bottom 10px);

    label {
      font-weight: 400;
      color: $black;
      padding: 10px 10px 10px 0;
    }

    i {
      color: $black;
      margin-left: $spacer*.75;
    }
  }
}

.close_btn {
  --circleSize: 20px;
  background: rgba(0, 0, 0, 0.5);
  width: var(--circleSize);
  height: var(--circleSize);
  display: flex;
  border-radius: var(--circleSize);
  justify-content: center;
  align-items: center;
  cursor: pointer;
  @include absolute(top 10px right 10px);

  svg {
    width: 10px;
    fill: $white;
  }
}


.setprofileimage {
  .beforepicset {
    display: inline-block !important;
  }

  .afterpicset {
    display: none !important;
  }

  &.profilepicadded {
    .beforepicset {
      display: none !important;
    }

    .afterpicset {
      display: inline-block !important;
    }
  }
}

.setprofileimage {
  .close_btn {
    display: none !important;
  }

  &.profilepicadded {
    .close_btn {
      display: flex !important;
    }
  }
}

.addcategorydiv {
  display: none;
}

.pageTitle {
  margin-bottom: $spacer;
}

.filtersForm {
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  @include media-breakpoint-up(md) {
    flex-direction: row;
  }

  .filters {
    display: flex;
    flex-direction: column;
    margin-right: 0;

    @include media-breakpoint-up(md) {
      flex-direction: row;
      margin-right: $spacer;
    }

    @include media-breakpoint-up(lg) {
      margin-right: 0;
    }

    .form-group {
      margin-right: 0;
      margin-bottom: $spacer*.75;

      @include media-breakpoint-up(md) {
        margin-right: $spacer*.75;
        margin-bottom: 0;
      }
    }

    .form-control {
      padding: 10px 20px 10px 10px;
      border-radius: 3px;
    }
  }
}

.blogauthor {
  max-width: 75px;

  img {
    @extend %imgobjectcover;
  }
}

.modifybloglinks {
  a {
    border-left: 1px solid $border-color;
    margin-left: $spacer*.5;
    padding-left: $spacer*.5;
    &:first-child {
      border-left: none;
      margin-left: 0;
      padding-left: 0;
    }
  }
}

.bloglists {
  .custom-option__item {
    display: block;
  }

  .actions {
    a {
      display: inline-flex;
      margin-bottom: $spacer*.5;
    }

    svg {
      fill: $gray-600;
    }
  }

  .table>thead>tr>th {
    vertical-align: top;
  }

  .table>thead>tr>th:first-child+th {
    width: 10%;
  }
}

.searchblog {
  margin-bottom: $spacer;

  @include media-breakpoint-up(md) {
    align-items: center;
    display: flex;
    flex-direction: row;
  }

  .form-group {
    margin: 0;

    @include media-breakpoint-up(md) {
      display: flex;
      flex-direction: row;
      align-items: center;
      flex-wrap: wrap;
    }

    input {
      margin-right: $spacer*.5;
      width: auto;

      &[type="submit"] {
        @include media-breakpoint-down(md) {
          display: none;
        }
      }

      &[type="search"] {
        @include media-breakpoint-down(md) {
          background-image: url(http://s2.wp.com/wp-content/themes/pub/twentythirteen/images/search-icon.png);
          background-position: 95% center;
          background-repeat: no-repeat;
          background-size: 18px auto;
          border-radius: 3px;
          cursor: pointer;
          padding-right: 35px;
          width: 100%;
        }
      }
    }
  }

  form {
    margin-bottom: 20px;

    @include media-breakpoint-up(md) {
      margin-bottom: 0;
      margin-right: 0;
    }
  }
}

.addblogpage-head {
  @include media-breakpoint-up(md) {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}

.filter-wrap>#deletesticky {
  background: $red;
  box-shadow: 0px 10px 18px 0px rgba(250, 101, 65, 0.41);
}

.stickybtn {
  transform: scale(0);
  transition: all 0.3s ease 0s;

  &.active {
    transform: scale(1);
  }
}

.--bloglistcomments {
  .table>thead>tr>th:first-child+th {
    width: auto;
  }

  .blogauthor {
    max-width: inherit;
    display: flex;
    --circleSize: 40px;

    img {
      width: var(--circleSize);
      height: var(--circleSize);
      border-radius: var(--circleSize);
      margin-right: $spacer*.75;
    }

    &__info {
      &-name {
        display: flex;
      }

      &-email {
        display: flex;
        font-size: 12px;
      }
    }
  }

  .actions {
    display: flex;
    flex-direction: column;
    @include media-breakpoint-up(md) {
      margin-left: 30px;
    }
  }
}

.blockquote {
  $P : &;
  &.--blueilneblockquote {
    border-left: 2px solid $primary;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    @include media-breakpoint-up(sm) {
      flex-direction: row;
    }
    #{$P}__left {
      margin-right: $spacer*.75;
      h3 {
        @include font-size($font-size-lg);
        font-weight: 600;
      }
    }
    .replyto {
      font-style: italic;
      font-size: 14px;
      color: $gray-600;
    }
  }
}