.sidebar__title {
	display: flex;
	justify-content: space-between;
	padding: $spacer*.75;
	border-bottom: 1px solid $gray-300;
	@include font-size($font-size-base);
	a {
		color: $primary;
		&:hover {
			color: $body-color;
		}
	}
}
.adblock {
	background: $white;
	border-bottom: 1px solid $gray-300;
	display: flex;
	padding: $spacer*.75;
	justify-content: space-between;
	position: relative;
	flex-direction: column;
	$p:&;

	@include border-radius(3px);
	&__header {
		position: relative;
		margin-top:6px;
		margin-bottom:10px;
 
	 
		&-title {
			color: $gray-700;
			@include font-size(14px);
			font-weight: 600;
			margin-bottom: 0;
			word-break: break-all;
			line-height: 24px;
		 
		}
		&-url {
			// @include truncate();
			margin-top: 4px;
			word-break: break-all;
		}
		
	}
	p {
        @include font-size(14px);
		line-height: 24px;
		word-break: break-all;
        
    }
	.adimage {
		width: 100%;
		margin: 0;
		img {
		  @extend %imgobjectcover;
		}
	}
	.adarticle {
		padding: ($spacer*.5) 0;
	}
	.pricingtag {
		margin: ($spacer*.5) 0;
		.highlight-price {
			margin: 0;
			@include font-size($font-size-base);
			font-weight: 600;
			del {
				&:hover {
					text-decoration: line-through;
				}
			}
			small {
				font-size: 80%;
			}
		}
	}

	.dropdown-menu-button {
		position: absolute;
    right: 20px;
    background: #fff;
    border: 1px solid $gray-400;
    border-radius: 4px;
    padding:6px 2px;
	top: 20px;
	display: flex;;
	@include media-breakpoint-up(md){

		display: none;
	}
	
	svg {
		fill:$gray-500;
		font-size: 14px;
	}
	 
	}

	.dropdown-menu {
		right:auto !important;
		left:0 !important;
	}

	&:hover, .card-dropdown.show {
		.dropdown-menu-button {
			display: flex;
		}
	}

 
}

 


//old advertisement sidebar block

.sidebar-navigation {
    .panel-body {
        padding:30px 0px 0px;
        
        .thumbnail {
            border:none;
        }
        >li {
            &:not(:first-child){
                border-top:1px solid #e4dfdf;
                padding-top:20px;
                
            }
        }
       hr, br  {
                display:none;
            }
    }
}


.adBlock-extra{
	.adblock__header  {
		display:flex;
		justify-content: space-between;
		

	}
	* {
		font-size: 14px;
	}
	.custom-option__label:before, .custom-option__label:after {
		width:18px;
		height:18px; 
	}


}