.countries {
    display:flex;
    flex-wrap:wrap;
    @supports (display: grid) {
    display:grid;
    grid-template-columns: repeat(auto-fit, minmax(220px, max-content));
    grid-auto-rows: auto;
    grid-gap: 20px;
    }
    margin:0 -10px;
    
    &__list {
        display: flex;
        padding:10px;
        flex:0 0 auto;         
        align-items:  center;
        @include media-breakpoint-up(md){
            flex-basis: 25%;
        }
        


        &__link {
            color: $gray-700;
        }
        &__flag {
            width: 30px;
          
        }
        &__title {
            margin-left: 10px;
        }
    }

    &--single {
        grid-template-columns: 1fr;
    }
}

