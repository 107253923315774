.circlecheck {
  --circleSize: 18px;
  --bgcolor: $gray-500;
  align-items: center;
  background: var(--bgcolor);
  border-radius: var(--circleSize);
  display: flex;
  height: var(--circleSize);
  justify-content: center;
  width: var(--circleSize);
  flex: 0 0 var(--circleSize);
  svg {
    width: var(--circleSize) - 5px;
    height: auto;
    fill: $white;
  }
}