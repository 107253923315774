.listcard {
	border: 1px solid $gray-300;
	margin-bottom: 20px;
	@include border-radius(3px);
	&__title {
		border-bottom: 1px solid $gray-300;
		font-size: rem(16);
		font-weight: 500;
		padding: 15px;
	}
}
.listgroup {
	list-style: none;
	margin: 0;
	padding: 5px 15px;
	$P:&;
	li {
		margin: 10px 0;
	}
	a {
		color: $gray-600;
		display: flex;
		flex-wrap: wrap;
		&:hover {
			color: $blue;
			text-decoration: none;
			#{$P}__icon {
				@include translate(5px, 0);
			}
		}
	}
	&__icon {
		margin-right: 10px;
		@include transition(all, 0.3s, ease, 0s);
	}
	&__meta {
		margin-left: auto;
	}
}
.pagination-no-border {
	#pagination {
		border: none;
		padding: 0;
	}
}