.howitworks-homepage {
  .card__thumbnail {
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.section-conditions {
  background: #FBFBFB;
}
.conditions-homepage {
  .introcontent {
    margin-bottom: $spacer;

    .--introcard {
      height: 100%;
    }
  }
  .card {
    &__thumbnail {
      --thumbnailsize: 75px;
      height: var(--thumbnailsize);
      width: var(--thumbnailsize);
      margin: 0 auto;
    }
  }
}
.section-howitworks, .section-doctors, .section-intro-highlight {
  background: $white;
}
.pricing-homepage {
  .custom-option--inline {
    .custom-option__item {
      margin-bottom: $spacer*.75;
      @include media-breakpoint-up(md) {
        margin-bottom: 0;
      }
    }
  }
}
.pricing-bottom-title {
  margin-top: $spacer*2;
}
.jumbotron .static-content {
  // top: 15px;
}

.jumbotron {
  z-index: 1;
  .static-content,
  .hero-content {
    width:100%;
  }
  .container {
    // max-width: inherit;
  }
}
.section-intro-highlight {
  .card__thumbnail {
    height: 150px;
    width: 200px;
    margin: 0 auto 20px;
    img {
      height: 100%;
    }
  }
}

$banner-height:calc(100vh - 80px) !important;
%slide-overlay {
  position:absolute;
  top:0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(black, 0.2);
  content:"";
}
%slide {
  height: $banner-height;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
  
}
 
  .static-slide {
    @extend %slide;
    &:after{
      // @extend %slide-overlay;
    }
  }
  .single-bgs {
  @extend %slide;
    opacity: 0;
  
    
  }
 

.jumbo {
  position:relative;
  max-height: $banner-height;
  overflow: hidden;
  
  .static-content {
      position:absolute;
      z-index: 500;
      width: 100%;
      top: 0;
      .hero-content {
          width:100%;
          padding:30px 15px;
      }
  }
}

.home-slider.slick-initialized {
  .single-bgs {
      position:relative;
      &:after {
        @extend %slide-overlay;
      }
  }
  img {
      width:100%;
      height:100%;
      object-fit: cover;
      object-position: bottom;
      transform:translateY(-20%);
  }
  
 
}

 