@charset "UTF-8";
/*
Bootstrap v4.3.1
*/
/*===========================
=            npm            =
===========================*/
@import url("https://fonts.googleapis.com/css?family=Roboto+Slab:300,400");
.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #212529; }
  .table th,
  .table td {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid #dee2e6; }
  .table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #dee2e6; }
  .table tbody + tbody {
    border-top: 2px solid #dee2e6; }

.table-sm th,
.table-sm td {
  padding: 0.3rem; }

.table-bordered {
  border: 1px solid #dee2e6; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6; }
  .table-bordered thead th,
  .table-bordered thead td {
    border-bottom-width: 2px; }

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0; }

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05); }

.table-hover tbody tr:hover {
  color: #212529;
  background-color: rgba(0, 0, 0, 0.075); }

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #b8daff; }

.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #7abaff; }

.table-hover .table-primary:hover {
  background-color: #9fcdff; }
  .table-hover .table-primary:hover > td,
  .table-hover .table-primary:hover > th {
    background-color: #9fcdff; }

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #d6d8db; }

.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: #b3b7bb; }

.table-hover .table-secondary:hover {
  background-color: #c8cbcf; }
  .table-hover .table-secondary:hover > td,
  .table-hover .table-secondary:hover > th {
    background-color: #c8cbcf; }

.table-success,
.table-success > th,
.table-success > td {
  background-color: #c3e6cb; }

.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
  border-color: #8fd19e; }

.table-hover .table-success:hover {
  background-color: #b1dfbb; }
  .table-hover .table-success:hover > td,
  .table-hover .table-success:hover > th {
    background-color: #b1dfbb; }

.table-info,
.table-info > th,
.table-info > td {
  background-color: #bee5eb; }

.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
  border-color: #86cfda; }

.table-hover .table-info:hover {
  background-color: #abdde5; }
  .table-hover .table-info:hover > td,
  .table-hover .table-info:hover > th {
    background-color: #abdde5; }

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #ffeeba; }

.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
  border-color: #ffdf7e; }

.table-hover .table-warning:hover {
  background-color: #ffe8a1; }
  .table-hover .table-warning:hover > td,
  .table-hover .table-warning:hover > th {
    background-color: #ffe8a1; }

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #f5c6cb; }

.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: #ed969e; }

.table-hover .table-danger:hover {
  background-color: #f1b0b7; }
  .table-hover .table-danger:hover > td,
  .table-hover .table-danger:hover > th {
    background-color: #f1b0b7; }

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fdfdfe; }

.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
  border-color: #fbfcfc; }

.table-hover .table-light:hover {
  background-color: #ececf6; }
  .table-hover .table-light:hover > td,
  .table-hover .table-light:hover > th {
    background-color: #ececf6; }

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c6c8ca; }

.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
  border-color: #95999c; }

.table-hover .table-dark:hover {
  background-color: #b9bbbe; }
  .table-hover .table-dark:hover > td,
  .table-hover .table-dark:hover > th {
    background-color: #b9bbbe; }

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075); }

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075); }
  .table-hover .table-active:hover > td,
  .table-hover .table-active:hover > th {
    background-color: rgba(0, 0, 0, 0.075); }

.table .thead-dark th {
  color: #fff;
  background-color: #343a40;
  border-color: #454d55; }

.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6; }

.table-dark {
  color: #fff;
  background-color: #343a40; }
  .table-dark th,
  .table-dark td,
  .table-dark thead th {
    border-color: #454d55; }
  .table-dark.table-bordered {
    border: 0; }
  .table-dark.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(255, 255, 255, 0.05); }
  .table-dark.table-hover tbody tr:hover {
    color: #fff;
    background-color: rgba(255, 255, 255, 0.075); }

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-sm > .table-bordered {
      border: 0; } }

@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-md > .table-bordered {
      border: 0; } }

@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-lg > .table-bordered {
      border: 0; } }

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-xl > .table-bordered {
      border: 0; } }

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch; }
  .table-responsive > .table-bordered {
    border: 0; }

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }
  @media (min-width: 576px) {
    .container {
      max-width: 540px; } }
  @media (min-width: 768px) {
    .container {
      max-width: 720px; } }
  @media (min-width: 992px) {
    .container {
      max-width: 960px; } }
  @media (min-width: 1200px) {
    .container {
      max-width: 1140px; } }

.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

.row, .tbl-block {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px; }

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%; }

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%; }

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.col-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.col-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.col-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  order: -1; }

.order-last {
  order: 13; }

.order-0 {
  order: 0; }

.order-1 {
  order: 1; }

.order-2 {
  order: 2; }

.order-3 {
  order: 3; }

.order-4 {
  order: 4; }

.order-5 {
  order: 5; }

.order-6 {
  order: 6; }

.order-7 {
  order: 7; }

.order-8 {
  order: 8; }

.order-9 {
  order: 9; }

.order-10 {
  order: 10; }

.order-11 {
  order: 11; }

.order-12 {
  order: 12; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    order: -1; }
  .order-sm-last {
    order: 13; }
  .order-sm-0 {
    order: 0; }
  .order-sm-1 {
    order: 1; }
  .order-sm-2 {
    order: 2; }
  .order-sm-3 {
    order: 3; }
  .order-sm-4 {
    order: 4; }
  .order-sm-5 {
    order: 5; }
  .order-sm-6 {
    order: 6; }
  .order-sm-7 {
    order: 7; }
  .order-sm-8 {
    order: 8; }
  .order-sm-9 {
    order: 9; }
  .order-sm-10 {
    order: 10; }
  .order-sm-11 {
    order: 11; }
  .order-sm-12 {
    order: 12; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; } }

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    order: -1; }
  .order-md-last {
    order: 13; }
  .order-md-0 {
    order: 0; }
  .order-md-1 {
    order: 1; }
  .order-md-2 {
    order: 2; }
  .order-md-3 {
    order: 3; }
  .order-md-4 {
    order: 4; }
  .order-md-5 {
    order: 5; }
  .order-md-6 {
    order: 6; }
  .order-md-7 {
    order: 7; }
  .order-md-8 {
    order: 8; }
  .order-md-9 {
    order: 9; }
  .order-md-10 {
    order: 10; }
  .order-md-11 {
    order: 11; }
  .order-md-12 {
    order: 12; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; } }

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    order: -1; }
  .order-lg-last {
    order: 13; }
  .order-lg-0 {
    order: 0; }
  .order-lg-1 {
    order: 1; }
  .order-lg-2 {
    order: 2; }
  .order-lg-3 {
    order: 3; }
  .order-lg-4 {
    order: 4; }
  .order-lg-5 {
    order: 5; }
  .order-lg-6 {
    order: 6; }
  .order-lg-7 {
    order: 7; }
  .order-lg-8 {
    order: 8; }
  .order-lg-9 {
    order: 9; }
  .order-lg-10 {
    order: 10; }
  .order-lg-11 {
    order: 11; }
  .order-lg-12 {
    order: 12; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    order: -1; }
  .order-xl-last {
    order: 13; }
  .order-xl-0 {
    order: 0; }
  .order-xl-1 {
    order: 1; }
  .order-xl-2 {
    order: 2; }
  .order-xl-3 {
    order: 3; }
  .order-xl-4 {
    order: 4; }
  .order-xl-5 {
    order: 5; }
  .order-xl-6 {
    order: 6; }
  .order-xl-7 {
    order: 7; }
  .order-xl-8 {
    order: 8; }
  .order-xl-9 {
    order: 9; }
  .order-xl-10 {
    order: 10; }
  .order-xl-11 {
    order: 11; }
  .order-xl-12 {
    order: 12; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; } }

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }

.nav-link {
  display: block;
  padding: 0.5rem 1rem; }
  .nav-link:hover, .nav-link:focus {
    text-decoration: none; }
  .nav-link.disabled {
    color: #6c757d;
    pointer-events: none;
    cursor: default; }

.nav-tabs {
  border-bottom: 1px solid #dee2e6; }
  .nav-tabs .nav-item {
    margin-bottom: -1px; }
  .nav-tabs .nav-link {
    border: 1px solid transparent;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
    .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
      border-color: #e9ecef #e9ecef #dee2e6; }
    .nav-tabs .nav-link.disabled {
      color: #6c757d;
      background-color: transparent;
      border-color: transparent; }
  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    color: #495057;
    background-color: #fff;
    border-color: #dee2e6 #dee2e6 #fff; }
  .nav-tabs .dropdown-menu {
    margin-top: -1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.nav-pills .nav-link {
  border-radius: 0.25rem; }

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #007bff; }

.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center; }

.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center; }

.tab-content > .tab-pane {
  display: none; }

.tab-content > .active {
  display: block; }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.bg-primary {
  background-color: #007bff !important; }

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #0062cc !important; }

.bg-secondary {
  background-color: #6c757d !important; }

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #545b62 !important; }

.bg-success {
  background-color: #28a745 !important; }

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #1e7e34 !important; }

.bg-info {
  background-color: #17a2b8 !important; }

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #117a8b !important; }

.bg-warning {
  background-color: #ffc107 !important; }

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #d39e00 !important; }

.bg-danger {
  background-color: #dc3545 !important; }

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #bd2130 !important; }

.bg-light {
  background-color: #f8f9fa !important; }

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dae0e5 !important; }

.bg-dark {
  background-color: #343a40 !important; }

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1d2124 !important; }

.bg-white {
  background-color: #fff !important; }

.bg-transparent {
  background-color: transparent !important; }

.border {
  border: 1px solid #dee2e6 !important; }

.border-top {
  border-top: 1px solid #dee2e6 !important; }

.border-right {
  border-right: 1px solid #dee2e6 !important; }

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important; }

.border-left {
  border-left: 1px solid #dee2e6 !important; }

.border-0 {
  border: 0 !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-right-0 {
  border-right: 0 !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-left-0 {
  border-left: 0 !important; }

.border-primary {
  border-color: #007bff !important; }

.border-secondary {
  border-color: #6c757d !important; }

.border-success {
  border-color: #28a745 !important; }

.border-info {
  border-color: #17a2b8 !important; }

.border-warning {
  border-color: #ffc107 !important; }

.border-danger {
  border-color: #dc3545 !important; }

.border-light {
  border-color: #f8f9fa !important; }

.border-dark {
  border-color: #343a40 !important; }

.border-white {
  border-color: #fff !important; }

.rounded-sm {
  border-radius: 0.2rem !important; }

.rounded {
  border-radius: 0.25rem !important; }

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important; }

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important; }

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-lg {
  border-radius: 0.3rem !important; }

.rounded-circle {
  border-radius: 50% !important; }

.rounded-pill {
  border-radius: 50rem !important; }

.rounded-0 {
  border-radius: 0 !important; }

.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 768px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; } }

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden; }
  .embed-responsive::before {
    display: block;
    content: ""; }
  .embed-responsive .embed-responsive-item,
  .embed-responsive iframe,
  .embed-responsive embed,
  .embed-responsive object,
  .embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0; }

.embed-responsive-21by9::before {
  padding-top: 42.85714%; }

.embed-responsive-16by9::before {
  padding-top: 56.25%; }

.embed-responsive-4by3::before {
  padding-top: 75%; }

.embed-responsive-1by1::before {
  padding-top: 100%; }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.flex-fill {
  flex: 1 1 auto !important; }

.flex-grow-0 {
  flex-grow: 0 !important; }

.flex-grow-1 {
  flex-grow: 1 !important; }

.flex-shrink-0 {
  flex-shrink: 0 !important; }

.flex-shrink-1 {
  flex-shrink: 1 !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-sm-fill {
    flex: 1 1 auto !important; }
  .flex-sm-grow-0 {
    flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; } }

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-md-fill {
    flex: 1 1 auto !important; }
  .flex-md-grow-0 {
    flex-grow: 0 !important; }
  .flex-md-grow-1 {
    flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; } }

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-lg-fill {
    flex: 1 1 auto !important; }
  .flex-lg-grow-0 {
    flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; } }

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-xl-fill {
    flex: 1 1 auto !important; }
  .flex-xl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; } }

.float-left {
  float: left !important; }

.float-right {
  float: right !important; }

.float-none {
  float: none !important; }

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important; }
  .float-sm-right {
    float: right !important; }
  .float-sm-none {
    float: none !important; } }

@media (min-width: 768px) {
  .float-md-left {
    float: left !important; }
  .float-md-right {
    float: right !important; }
  .float-md-none {
    float: none !important; } }

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important; }
  .float-lg-right {
    float: right !important; }
  .float-lg-none {
    float: none !important; } }

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important; }
  .float-xl-right {
    float: right !important; }
  .float-xl-none {
    float: none !important; } }

.overflow-auto {
  overflow: auto !important; }

.overflow-hidden {
  overflow: hidden !important; }

.position-static {
  position: static !important; }

.position-relative {
  position: relative !important; }

.position-absolute {
  position: absolute !important; }

.position-fixed {
  position: fixed !important; }

.position-sticky {
  position: sticky !important; }

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020; } }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0; }

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal; }

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important; }

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important; }

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important; }

.shadow-none {
  box-shadow: none !important; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.w-auto {
  width: auto !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.h-auto {
  height: auto !important; }

.mw-100 {
  max-width: 100% !important; }

.mh-100 {
  max-height: 100% !important; }

.min-vw-100 {
  min-width: 100vw !important; }

.min-vh-100 {
  min-height: 100vh !important; }

.vw-100 {
  width: 100vw !important; }

.vh-100 {
  height: 100vh !important; }

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0); }

.m-0 {
  margin: 0 !important; }

.mt-0,
.my-0 {
  margin-top: 0 !important; }

.mr-0,
.mx-0 {
  margin-right: 0 !important; }

.mb-0,
.my-0 {
  margin-bottom: 0 !important; }

.ml-0,
.mx-0 {
  margin-left: 0 !important; }

.m-1 {
  margin: 0.25rem !important; }

.mt-1,
.my-1 {
  margin-top: 0.25rem !important; }

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important; }

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important; }

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important; }

.m-2 {
  margin: 0.5rem !important; }

.mt-2,
.my-2 {
  margin-top: 0.5rem !important; }

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important; }

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important; }

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important; }

.m-3 {
  margin: 1rem !important; }

.mt-3,
.my-3 {
  margin-top: 1rem !important; }

.mr-3,
.mx-3 {
  margin-right: 1rem !important; }

.mb-3,
.my-3 {
  margin-bottom: 1rem !important; }

.ml-3,
.mx-3 {
  margin-left: 1rem !important; }

.m-4 {
  margin: 1.5rem !important; }

.mt-4,
.my-4 {
  margin-top: 1.5rem !important; }

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important; }

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important; }

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important; }

.m-5 {
  margin: 3rem !important; }

.mt-5,
.my-5 {
  margin-top: 3rem !important; }

.mr-5,
.mx-5 {
  margin-right: 3rem !important; }

.mb-5,
.my-5 {
  margin-bottom: 3rem !important; }

.ml-5,
.mx-5 {
  margin-left: 3rem !important; }

.p-0 {
  padding: 0 !important; }

.pt-0,
.py-0 {
  padding-top: 0 !important; }

.pr-0,
.px-0 {
  padding-right: 0 !important; }

.pb-0,
.py-0 {
  padding-bottom: 0 !important; }

.pl-0,
.px-0 {
  padding-left: 0 !important; }

.p-1 {
  padding: 0.25rem !important; }

.pt-1,
.py-1 {
  padding-top: 0.25rem !important; }

.pr-1,
.px-1 {
  padding-right: 0.25rem !important; }

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important; }

.pl-1,
.px-1 {
  padding-left: 0.25rem !important; }

.p-2 {
  padding: 0.5rem !important; }

.pt-2,
.py-2 {
  padding-top: 0.5rem !important; }

.pr-2,
.px-2 {
  padding-right: 0.5rem !important; }

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important; }

.pl-2,
.px-2 {
  padding-left: 0.5rem !important; }

.p-3 {
  padding: 1rem !important; }

.pt-3,
.py-3 {
  padding-top: 1rem !important; }

.pr-3,
.px-3 {
  padding-right: 1rem !important; }

.pb-3,
.py-3 {
  padding-bottom: 1rem !important; }

.pl-3,
.px-3 {
  padding-left: 1rem !important; }

.p-4 {
  padding: 1.5rem !important; }

.pt-4,
.py-4 {
  padding-top: 1.5rem !important; }

.pr-4,
.px-4 {
  padding-right: 1.5rem !important; }

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important; }

.pl-4,
.px-4 {
  padding-left: 1.5rem !important; }

.p-5 {
  padding: 3rem !important; }

.pt-5,
.py-5 {
  padding-top: 3rem !important; }

.pr-5,
.px-5 {
  padding-right: 3rem !important; }

.pb-5,
.py-5 {
  padding-bottom: 3rem !important; }

.pl-5,
.px-5 {
  padding-left: 3rem !important; }

.m-n1 {
  margin: -0.25rem !important; }

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important; }

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important; }

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important; }

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important; }

.m-n2 {
  margin: -0.5rem !important; }

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important; }

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important; }

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important; }

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important; }

.m-n3 {
  margin: -1rem !important; }

.mt-n3,
.my-n3 {
  margin-top: -1rem !important; }

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important; }

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important; }

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important; }

.m-n4 {
  margin: -1.5rem !important; }

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important; }

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important; }

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important; }

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important; }

.m-n5 {
  margin: -3rem !important; }

.mt-n5,
.my-n5 {
  margin-top: -3rem !important; }

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important; }

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important; }

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important; }

.m-auto {
  margin: auto !important; }

.mt-auto,
.my-auto {
  margin-top: auto !important; }

.mr-auto,
.mx-auto {
  margin-right: auto !important; }

.mb-auto,
.my-auto {
  margin-bottom: auto !important; }

.ml-auto,
.mx-auto {
  margin-left: auto !important; }

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important; }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important; }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important; }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important; }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important; }
  .m-sm-1 {
    margin: 0.25rem !important; }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important; }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important; }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important; }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important; }
  .m-sm-2 {
    margin: 0.5rem !important; }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important; }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important; }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important; }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important; }
  .m-sm-3 {
    margin: 1rem !important; }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important; }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important; }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important; }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important; }
  .m-sm-4 {
    margin: 1.5rem !important; }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important; }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important; }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important; }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important; }
  .m-sm-5 {
    margin: 3rem !important; }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important; }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important; }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important; }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important; }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important; }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important; }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important; }
  .p-sm-1 {
    padding: 0.25rem !important; }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important; }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important; }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important; }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important; }
  .p-sm-2 {
    padding: 0.5rem !important; }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important; }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important; }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important; }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important; }
  .p-sm-3 {
    padding: 1rem !important; }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important; }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important; }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important; }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important; }
  .p-sm-4 {
    padding: 1.5rem !important; }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important; }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important; }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important; }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important; }
  .p-sm-5 {
    padding: 3rem !important; }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important; }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important; }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important; }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important; }
  .m-sm-n1 {
    margin: -0.25rem !important; }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important; }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important; }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important; }
  .m-sm-n2 {
    margin: -0.5rem !important; }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important; }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important; }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important; }
  .m-sm-n3 {
    margin: -1rem !important; }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important; }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important; }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important; }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important; }
  .m-sm-n4 {
    margin: -1.5rem !important; }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important; }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important; }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important; }
  .m-sm-n5 {
    margin: -3rem !important; }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important; }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important; }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important; }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important; }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important; }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important; }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important; } }

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important; }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important; }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important; }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important; }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important; }
  .m-md-1 {
    margin: 0.25rem !important; }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important; }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important; }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important; }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important; }
  .m-md-2 {
    margin: 0.5rem !important; }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important; }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important; }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important; }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important; }
  .m-md-3 {
    margin: 1rem !important; }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important; }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important; }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important; }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important; }
  .m-md-4 {
    margin: 1.5rem !important; }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important; }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important; }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important; }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important; }
  .m-md-5 {
    margin: 3rem !important; }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important; }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important; }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important; }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important; }
  .p-md-0 {
    padding: 0 !important; }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important; }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important; }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important; }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important; }
  .p-md-1 {
    padding: 0.25rem !important; }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important; }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important; }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important; }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important; }
  .p-md-2 {
    padding: 0.5rem !important; }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important; }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important; }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important; }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important; }
  .p-md-3 {
    padding: 1rem !important; }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important; }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important; }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important; }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important; }
  .p-md-4 {
    padding: 1.5rem !important; }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important; }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important; }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important; }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important; }
  .p-md-5 {
    padding: 3rem !important; }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important; }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important; }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important; }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important; }
  .m-md-n1 {
    margin: -0.25rem !important; }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important; }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important; }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important; }
  .m-md-n2 {
    margin: -0.5rem !important; }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important; }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important; }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important; }
  .m-md-n3 {
    margin: -1rem !important; }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important; }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important; }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important; }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important; }
  .m-md-n4 {
    margin: -1.5rem !important; }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important; }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important; }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important; }
  .m-md-n5 {
    margin: -3rem !important; }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important; }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important; }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important; }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important; }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important; }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important; }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important; } }

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important; }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important; }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important; }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important; }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important; }
  .m-lg-1 {
    margin: 0.25rem !important; }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important; }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important; }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important; }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important; }
  .m-lg-2 {
    margin: 0.5rem !important; }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important; }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important; }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important; }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important; }
  .m-lg-3 {
    margin: 1rem !important; }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important; }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important; }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important; }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important; }
  .m-lg-4 {
    margin: 1.5rem !important; }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important; }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important; }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important; }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important; }
  .m-lg-5 {
    margin: 3rem !important; }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important; }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important; }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important; }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important; }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important; }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important; }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important; }
  .p-lg-1 {
    padding: 0.25rem !important; }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important; }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important; }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important; }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important; }
  .p-lg-2 {
    padding: 0.5rem !important; }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important; }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important; }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important; }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important; }
  .p-lg-3 {
    padding: 1rem !important; }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important; }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important; }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important; }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important; }
  .p-lg-4 {
    padding: 1.5rem !important; }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important; }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important; }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important; }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important; }
  .p-lg-5 {
    padding: 3rem !important; }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important; }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important; }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important; }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important; }
  .m-lg-n1 {
    margin: -0.25rem !important; }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important; }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important; }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important; }
  .m-lg-n2 {
    margin: -0.5rem !important; }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important; }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important; }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important; }
  .m-lg-n3 {
    margin: -1rem !important; }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important; }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important; }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important; }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important; }
  .m-lg-n4 {
    margin: -1.5rem !important; }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important; }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important; }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important; }
  .m-lg-n5 {
    margin: -3rem !important; }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important; }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important; }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important; }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important; }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important; }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important; }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important; } }

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important; }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important; }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important; }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important; }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important; }
  .m-xl-1 {
    margin: 0.25rem !important; }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important; }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important; }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important; }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important; }
  .m-xl-2 {
    margin: 0.5rem !important; }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important; }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important; }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important; }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important; }
  .m-xl-3 {
    margin: 1rem !important; }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important; }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important; }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important; }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important; }
  .m-xl-4 {
    margin: 1.5rem !important; }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important; }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important; }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important; }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important; }
  .m-xl-5 {
    margin: 3rem !important; }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important; }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important; }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important; }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important; }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important; }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important; }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important; }
  .p-xl-1 {
    padding: 0.25rem !important; }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important; }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important; }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important; }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important; }
  .p-xl-2 {
    padding: 0.5rem !important; }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important; }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important; }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important; }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important; }
  .p-xl-3 {
    padding: 1rem !important; }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important; }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important; }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important; }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important; }
  .p-xl-4 {
    padding: 1.5rem !important; }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important; }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important; }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important; }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important; }
  .p-xl-5 {
    padding: 3rem !important; }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important; }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important; }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important; }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important; }
  .m-xl-n1 {
    margin: -0.25rem !important; }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important; }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important; }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important; }
  .m-xl-n2 {
    margin: -0.5rem !important; }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important; }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important; }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important; }
  .m-xl-n3 {
    margin: -1rem !important; }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important; }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important; }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important; }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important; }
  .m-xl-n4 {
    margin: -1.5rem !important; }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important; }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important; }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important; }
  .m-xl-n5 {
    margin: -3rem !important; }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important; }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important; }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important; }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important; }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important; }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important; }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important; } }

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important; }

.text-justify {
  text-align: justify !important; }

.text-wrap {
  white-space: normal !important; }

.text-nowrap {
  white-space: nowrap !important; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.text-left {
  text-align: left !important; }

.text-right {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important; }
  .text-sm-right {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important; }
  .text-md-right {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important; }
  .text-lg-right {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important; }
  .text-xl-right {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.font-weight-light {
  font-weight: 300 !important; }

.font-weight-lighter {
  font-weight: lighter !important; }

.font-weight-normal {
  font-weight: 400 !important; }

.font-weight-bold {
  font-weight: 700 !important; }

.font-weight-bolder {
  font-weight: bolder !important; }

.font-italic {
  font-style: italic !important; }

.text-white {
  color: #fff !important; }

.text-primary {
  color: #007bff !important; }

a.text-primary:hover, a.text-primary:focus {
  color: #0056b3 !important; }

.text-secondary {
  color: #6c757d !important; }

a.text-secondary:hover, a.text-secondary:focus {
  color: #494f54 !important; }

.text-success {
  color: #28a745 !important; }

a.text-success:hover, a.text-success:focus {
  color: #19692c !important; }

.text-info {
  color: #17a2b8 !important; }

a.text-info:hover, a.text-info:focus {
  color: #0f6674 !important; }

.text-warning {
  color: #ffc107 !important; }

a.text-warning:hover, a.text-warning:focus {
  color: #ba8b00 !important; }

.text-danger {
  color: #dc3545 !important; }

a.text-danger:hover, a.text-danger:focus {
  color: #a71d2a !important; }

.text-light {
  color: #f8f9fa !important; }

a.text-light:hover, a.text-light:focus {
  color: #cbd3da !important; }

.text-dark {
  color: #343a40 !important; }

a.text-dark:hover, a.text-dark:focus {
  color: #121416 !important; }

.text-body {
  color: #212529 !important; }

.text-muted {
  color: #6c757d !important; }

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important; }

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important; }

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; }

.text-decoration-none {
  text-decoration: none !important; }

.text-break {
  word-break: break-word !important;
  overflow-wrap: break-word !important; }

.text-reset {
  color: inherit !important; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

.modal-open {
  overflow: hidden; }
  .modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto; }

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0; }

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none; }
  .modal.fade .modal-dialog {
    transition: transform 0.3s ease-out;
    transform: translate(0, -50px); }
    @media (prefers-reduced-motion: reduce) {
      .modal.fade .modal-dialog {
        transition: none; } }
  .modal.show .modal-dialog {
    transform: none; }

.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 1rem); }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 1rem);
    overflow: hidden; }
  .modal-dialog-scrollable .modal-header,
  .modal-dialog-scrollable .modal-footer {
    flex-shrink: 0; }
  .modal-dialog-scrollable .modal-body {
    overflow-y: auto; }

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem); }
  .modal-dialog-centered::before {
    display: block;
    height: calc(100vh - 1rem);
    content: ""; }
  .modal-dialog-centered.modal-dialog-scrollable {
    flex-direction: column;
    justify-content: center;
    height: 100%; }
    .modal-dialog-centered.modal-dialog-scrollable .modal-content {
      max-height: none; }
    .modal-dialog-centered.modal-dialog-scrollable::before {
      content: none; }

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0; }

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000; }
  .modal-backdrop.fade {
    opacity: 0; }
  .modal-backdrop.show {
    opacity: 0.5; }

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem; }
  .modal-header .close {
    padding: 1rem 1rem;
    margin: -1rem -1rem -1rem auto; }

.modal-title {
  margin-bottom: 0;
  line-height: 1.5; }

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem; }

.modal-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 1rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem; }
  .modal-footer > :not(:first-child) {
    margin-left: .25rem; }
  .modal-footer > :not(:last-child) {
    margin-right: .25rem; }

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll; }

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto; }
  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem); }
    .modal-dialog-scrollable .modal-content {
      max-height: calc(100vh - 3.5rem); }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem); }
    .modal-dialog-centered::before {
      height: calc(100vh - 3.5rem); }
  .modal-sm {
    max-width: 300px; } }

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px; } }

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px; } }

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .badge {
      transition: none; } }
  a.badge:hover, a.badge:focus {
    text-decoration: none; }
  .badge:empty {
    display: none; }

.btn .badge {
  position: relative;
  top: -1px; }

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem; }

.badge-primary {
  color: #fff;
  background-color: #007bff; }
  a.badge-primary:hover, a.badge-primary:focus {
    color: #fff;
    background-color: #0062cc; }
  a.badge-primary:focus, a.badge-primary.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5); }

.badge-secondary {
  color: #fff;
  background-color: #6c757d; }
  a.badge-secondary:hover, a.badge-secondary:focus {
    color: #fff;
    background-color: #545b62; }
  a.badge-secondary:focus, a.badge-secondary.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }

.badge-success {
  color: #fff;
  background-color: #28a745; }
  a.badge-success:hover, a.badge-success:focus {
    color: #fff;
    background-color: #1e7e34; }
  a.badge-success:focus, a.badge-success.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }

.badge-info {
  color: #fff;
  background-color: #17a2b8; }
  a.badge-info:hover, a.badge-info:focus {
    color: #fff;
    background-color: #117a8b; }
  a.badge-info:focus, a.badge-info.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }

.badge-warning {
  color: #212529;
  background-color: #ffc107; }
  a.badge-warning:hover, a.badge-warning:focus {
    color: #212529;
    background-color: #d39e00; }
  a.badge-warning:focus, a.badge-warning.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }

.badge-danger {
  color: #fff;
  background-color: #dc3545; }
  a.badge-danger:hover, a.badge-danger:focus {
    color: #fff;
    background-color: #bd2130; }
  a.badge-danger:focus, a.badge-danger.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }

.badge-light {
  color: #212529;
  background-color: #f8f9fa; }
  a.badge-light:hover, a.badge-light:focus {
    color: #212529;
    background-color: #dae0e5; }
  a.badge-light:focus, a.badge-light.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }

.badge-dark {
  color: #fff;
  background-color: #343a40; }
  a.badge-dark:hover, a.badge-dark:focus {
    color: #fff;
    background-color: #1d2124; }
  a.badge-dark:focus, a.badge-dark.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

/*=============================
=            base             =
=============================*/
.postblock__thumbnails-single img, .adblock .adimage img, .profileimage img, .cols-4 .inlineItems li img, .setprofileimage img, .blogauthor img, .cliniclogo__img img, .listconditions__figure img {
  width: 100%;
  height: 100%;
  object-fit: cover; }

.payoutblock--history .tableblock__item:nth-child(2) {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.blue-btn, .filter-wrap > a {
  box-shadow: 0px 10px 18px 0px rgba(0, 176, 239, 0.41); }

.media--flex .media__left .media__thumbnail--100 {
  display: flex;
  align-items: center;
  justify-content: center; }

select, .select-box input[type="text"], .input-icon-box input[type="text"], .input-datepicker .form-control {
  appearance: none;
  padding-right: 30px !important;
  background-image: url(../images/caret-down.svg) !important;
  background-position: calc(100% - 14px) center, calc(100% - 10px) center !important;
  background-size: 8px !important;
  background-repeat: no-repeat !important; }
  select::-moz-focusring, .select-box input[type="text"]::-moz-focusring, .input-icon-box input[type="text"]::-moz-focusring, .input-datepicker .form-control::-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #000; }

html,
body {
  font-size: 1rem;
  min-height: 100%;
  height: auto; }

body {
  padding: 0px;
  margin: 0px; }

* {
  scrollbar-color: #0ab7c5 #fff; }
  *::-webkit-scrollbar {
    width: 6px;
    height: 6px; }
  *::-webkit-scrollbar-track {
    background: none; }
  *::-webkit-scrollbar-thumb {
    background: #0ab7c5;
    border-radius: 5px; }
  *::-webkit-scrollbar-thumb:hover {
    background: #1399A3; }

.notopspace {
  padding-top: 0 !important;
  margin-top: 0 !important; }

.flexblock {
  display: flex;
  justify-content: space-between;
  align-items: center; }

.flexright {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end; }

iframe {
  max-width: 100%; }

.btn-outline-secondary {
  background: #fff;
  color: #6c757d;
  border: 1px solid #6c757d;
  padding: 6px 12px; }
  .btn-outline-secondary:hover {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d; }

svg {
  width: 1em;
  height: 1em;
  font-size: 1rem; }

@media (min-width: 768px) {
  .mb-md-20 {
    margin-bottom: 1.25rem; } }

@media (min-width: 768px) {
  .mb-md-40 {
    margin-bottom: 2.5rem; } }

.wrapper {
  display: flex;
  min-height: 100vh;
  flex-direction: column; }
  .wrapper #header,
  .wrapper #footer {
    flex-shrink: 0; }
  .wrapper .main-content {
    flex: 1 0 auto; }

.text-medium {
  font-size: 0.875rem; }

.color-light {
  color: #adb5bd; }

.page-content {
  display: flex;
  flex-direction: column; }

.row-sm {
  margin-left: -7.5px;
  margin-right: -7.5px; }

.box-bodered {
  border: 1px solid #ced4da;
  border-radius: 5px; }

.isDisabled {
  color: currentColor;
  cursor: not-allowed;
  opacity: 0.5;
  text-decoration: none; }

@media (min-width: 768px) {
  .stickyBlock {
    position: sticky;
    top: 1.33333em; } }

.box {
  background-color: #f8f9fa; }

.font-size-14 {
  font-size: 0.93333rem; }

.embed-responsive {
  padding-bottom: 56.25%; }

/*==============================
=            layout            =
==============================*/
.row:before, .tbl-block:before, .row:after, .tbl-block:after {
  display: none; }

.tbl-block {
  width: unset; }
  .tbl-block__cell {
    position: relative;
    padding-right: 15px;
    padding-left: 15px;
    display: block; }
    @media (max-width: 991.98px) {
      .tbl-block__cell {
        width: 100%; } }

.sidebar-navigation {
  padding: 0 !important; }
  .sidebar-navigation > * {
    padding: 15px 20px !important;
    margin-bottom: 0 !important; }
  .sidebar-navigation .menu {
    padding-top: 8px !important; }

.sidebar__feature-block {
  margin-bottom: 1em;
  padding-bottom: 1em;
  border-bottom: 1px solid #eee;
  border-top: 1px solid #eee;
  color: #0ab7c5;
  position: relative; }
  .sidebar__feature-block:before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    content: " ";
    opacity: 0.05;
    background-color: currentColor;
    background-image: linear-gradient(to bottom, currentColor, #fff); }
  .sidebar__feature-block__title {
    color: currentColor;
    display: flex;
    margin-bottom: 0;
    font-weight: bold;
    align-items: center; }
    .sidebar__feature-block__title svg {
      fill: currentColor;
      margin-right: 8px;
      font-size: 20px; }

.profile-progress-wrap {
  display: flex;
  flex-direction: column; }
  .profile-progress-wrap strong {
    display: block;
    font-weight: normal;
    margin-bottom: 15px; }
    .profile-progress-wrap strong + a.btn-info {
      position: absolute;
      top: 0;
      right: 0; }
  .profile-progress-wrap p {
    position: relative;
    margin-bottom: 0 !important; }
    .profile-progress-wrap p ~ p {
      display: none; }
  .profile-progress-wrap .progress-note {
    order: 1;
    margin: 0 0 15px 0 !important;
    font-weight: normal;
    font-size: 16px !important;
    color: #343a40 !important; }
  .profile-progress-wrap .progress {
    order: 2; }

.user-media.--user-sidebar,
.profile-progress-wrap {
  border-color: #eee;
  border: 0; }

.header-block {
  align-items: center;
  background: white;
  flex-wrap: wrap; }
  @media (min-width: 1250px) {
    .header-block {
      flex-wrap: nowrap; } }
  .header-block .logo {
    padding: 0px; }
  .header-block .navbar-nav .avatar {
    --size:20px;
    width: var(--size);
    height: var(--size); }
  @media (max-width: 1249px) {
    .header-block .navbar-nav li > a {
      padding-left: 15px;
      padding-right: 15px; } }
  .header-block .navbar-nav a {
    display: flex;
    align-items: center; }
    .header-block .navbar-nav a svg,
    .header-block .navbar-nav a .avatar {
      margin-right: 10px; }
    .header-block .navbar-nav a svg {
      fill: #A3A7AA; }
    .header-block .navbar-nav a:hover svg {
      fill: #0ab7c5; }
    .header-block .navbar-nav a:hover .avatar {
      border-color: #0ab7c5; }
  .header-block .navbar-nav .show .avatar {
    border-color: #0ab7c5; }
  .header-block .logo {
    flex: 0 0 auto;
    border: 0; }

#headersearch {
  flex: 1 1 100%;
  order: 3;
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 45px;
  background: #fff;
  color: #000; }
  @media (min-width: 1250px) {
    #headersearch {
      max-width: 40%;
      order: unset;
      margin-bottom: 0;
      flex: 1 1 auto; } }
  #headersearch .form-control {
    flex: 1 1 auto;
    height: 100%;
    margin: 0px;
    border: 1px solid #ced4da;
    border-right: none; }
    #headersearch .form-control:focus {
      border-color: #0ab7c5; }
      #headersearch .form-control:focus ~ .btn {
        color: #0ab7c5;
        border-color: currentcolor;
        background-color: currentcolor; }
        #headersearch .form-control:focus ~ .btn svg {
          fill: white; }
  #headersearch .btn {
    flex: 0 0 auto;
    align-self: stretch;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0 0px 0px 0;
    background: #F8F9FA;
    border: 1px solid #ced4da; }
    #headersearch .btn svg {
      fill: #495057; }

/*=========================================
=            vendor       =
=========================================*/
/*===============================
=            modules            =
===============================*/
.postblock {
  background: #fff;
  margin-bottom: 1.25rem;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.16); }
  .postblock__cont {
    background: #f8f9fa;
    border: 1px solid #e9ecef;
    resize: vertical;
    width: 100%;
    height: 72px;
    padding: 10px; }
    @media (min-width: 768px) {
      .postblock__cont {
        height: 100px; } }
    .postblock__cont:focus {
      outline: none; }
  .postblock__preview {
    margin: 15px 0;
    position: relative;
    display: flex; }
    .postblock__preview .attachments {
      margin-bottom: 0px;
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(150px, min-content));
      grid-auto-columns: minmax(150px, 1fr);
      scroll-snap-type: x proximity;
      overflow-x: auto;
      grid-auto-flow: column;
      grid-gap: 15px; }
    .postblock__preview .close-btn {
      position: absolute;
      top: 0;
      right: 0;
      --size: 20px;
      width: var(--size);
      height: var(--size);
      background: #0ab7c5;
      display: flex;
      justify-content: center;
      align-items: center; }
      .postblock__preview .close-btn svg {
        fill: white; }
  .postblock__footer {
    display: flex;
    flex-direction: column;
    padding: 10px 0 0; }
    @media (min-width: 576px) {
      .postblock__footer {
        align-items: center;
        flex-direction: row;
        justify-content: space-between; } }
    .postblock__footer .postblock__attachment {
      margin: -0.3125rem;
      padding-bottom: 1.33333em; }
      @media (min-width: 576px) {
        .postblock__footer .postblock__attachment {
          padding-bottom: 0px; } }
      .postblock__footer .postblock__attachment a {
        margin: 0.3125rem;
        display: inline-block; }
        .postblock__footer .postblock__attachment a:hover label, .postblock__footer .postblock__attachment a:focus label, .postblock__footer .postblock__attachment a:active label, .postblock__footer .postblock__attachment a.active label {
          background: rgba(0, 175, 240, 0.15); }
        .postblock__footer .postblock__attachment a input[type="file"] {
          position: absolute;
          left: -9999px;
          opacity: 0;
          z-index: -1; }
        .postblock__footer .postblock__attachment a svg {
          width: 18px;
          fill: #495057; }
        .postblock__footer .postblock__attachment a i {
          font-size: 1rem; }
        .postblock__footer .postblock__attachment a label {
          font-weight: 600;
          font-size: 0.75rem;
          margin-bottom: 0px;
          margin-left: 0;
          cursor: pointer;
          display: flex;
          display: inline-flex;
          align-items: center;
          background: #f2f4f6;
          color: #495057;
          padding: 0.53333em 0.8em;
          border-radius: 20px;
          cursor: pointer; }
          .postblock__footer .postblock__attachment a label span {
            display: none;
            margin-left: 6px; }
            @media (min-width: 768px) {
              .postblock__footer .postblock__attachment a label span {
                display: inline-block; } }
      .postblock__footer .postblock__attachment-icon {
        color: #adb5bd; }
  .postblock__attachment-icon > input[type="file"] {
    position: absolute;
    left: -9999px;
    opacity: 0;
    z-index: -1; }
  .postblock__thumbnails {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    margin: 0 -10px; }
    .postblock__thumbnails-single {
      flex: 1 0 33.33%;
      max-width: 33.33%;
      padding: 0.625rem;
      height: 100px;
      margin: 0;
      position: relative; }
      .postblock__thumbnails-single img {
        object-position: 0 0; }
  .postblock__btn .upload-errormsg {
    color: #FA6541;
    margin-right: 0.9375rem; }
  .postblock__btn .btn {
    padding: 5px 15px; }

.upload-success {
  align-items: center;
  display: flex;
  justify-content: center;
  position: absolute;
  top: 0;
  right: 0;
  content: '';
  width: 1.875rem;
  height: 1.875rem;
  border: 2px solid #fff;
  border-radius: 50%;
  background-color: #0bd7d9;
  color: #fff; }

.dropdown-menu-right {
  right: 0px;
  left: auto; }

.card-dropdown .dropdown-menu {
  min-width: 170px;
  top: 0; }
  .card-dropdown .dropdown-menu a {
    font-weight: 400;
    display: block;
    width: 100%;
    padding: 0.25rem 1.5rem;
    clear: both;
    font-weight: 400;
    color: #212529;
    text-align: inherit;
    white-space: nowrap;
    background-color: transparent;
    border: 0; }
    .card-dropdown .dropdown-menu a:hover {
      background-color: #0ab7c5;
      color: white; }

.card-dropdown .dropdown-toggle {
  background: none;
  border: none;
  cursor: pointer;
  position: absolute;
  right: 0; }
  .card-dropdown .dropdown-toggle:after {
    display: none; }
  .card-dropdown .dropdown-toggle svg {
    width: 5px;
    fill: #adb5bd; }
  .card-dropdown .dropdown-toggle:focus {
    outline: none; }

.postdetailblock {
  background: white;
  margin-bottom: 20px; }
  .postdetailblock__head {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    margin-bottom: 1.25rem; }
    .postdetailblock__head .media {
      display: flex;
      align-items: center;
      overflow: visible;
      width: 100%; }
    .postdetailblock__head .media-body {
      overflow: visible;
      width: auto;
      flex: 1 0 auto; }
    .postdetailblock__head .media-heading {
      font-weight: 600;
      font-size: 16px; }
  .postdetailblock__figure {
    margin-bottom: 1.25rem; }
  .postdetailblock__foot {
    padding: 0 0 15px; }
    .postdetailblock__foot a {
      color: #212529;
      margin-right: 15px; }
      .postdetailblock__foot a:hover {
        text-decoration: none; }
  .postdetailblock .displaycont {
    color: #adb5bd;
    cursor: pointer;
    text-transform: lowercase; }
  .postdetailblock #likepost .actionicon {
    color: blue; }

.actionicon {
  margin-right: 5px; }
  .actionicon svg {
    width: 18px;
    height: 20px;
    vertical-align: middle;
    fill: #495057; }
  .actionicon .cshare1 {
    width: 15px; }

.usermeta {
  align-items: center;
  display: flex;
  flex-direction: row; }
  .usermeta__figure {
    width: 40px;
    margin: 0 10px 0 0;
    overflow: hidden;
    border-radius: 50%; }
    .usermeta__figure img {
      width: 100%;
      height: 100%;
      object-fit: cover; }
  .usermeta__title {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    font-size: 1rem;
    font-weight: 600;
    margin: 0; }
    .usermeta__title .postedtime {
      color: #adb5bd;
      font-size: 14px;
      font-weight: 400;
      margin-top: 5px; }
  .usermeta__extra {
    display: flex;
    flex-wrap: wrap;
    font-size: 14px;
    color: #adb5bd; }
    .usermeta__extra .deviceused {
      margin-left: 0.625rem; }

.postblockheadright {
  align-items: center;
  display: flex;
  flex-direction: row;
  position: relative; }
  .postblockheadright .postedtime {
    color: #adb5bd;
    font-size: 14px;
    margin-right: 40px; }
  .postblockheadright .card-dropdown .dropdown-toggle {
    right: 0;
    height: 20px;
    top: -10px; }
    .postblockheadright .card-dropdown .dropdown-toggle svg {
      width: 4px; }

.commentblock {
  border-top: 1px solid #e9ecef;
  display: none;
  padding: 10px 0; }
  .commentblock .form-control {
    border-bottom-color: #e9ecef !important; }

.commentlist {
  border-bottom: 1px solid #dee2e6;
  padding: 1.25rem 0; }
  .commentlist > .commentlist {
    border: none;
    padding: 1.25rem 0 0 2.5rem; }
  .commentlist__first {
    border-top: none; }
  .commentlist__head {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between; }
  .commentlist__cont {
    margin: 10px 0 0 50px; }

.commentreplyblock {
  margin-top: 10px; }
  .commentreplyblock span {
    cursor: pointer;
    color: #0ab7c5; }

.backtoaddcomment {
  color: #adb5bd; }
  .backtoaddcomment:hover {
    color: #adb5bd; }

.postblock.commentpostblock {
  border: 1px solid #e9ecef;
  background: #f8f9fa;
  box-shadow: none;
  margin: 1.25rem 0; }
  .postblock.commentpostblock .postblock__cont {
    border: none;
    background: none;
    resize: none;
    padding: 0;
    height: 75px; }
  .postblock.commentpostblock .postblock__footer {
    justify-content: flex-end; }
  .postblock.commentpostblock .padblock {
    padding: 10px; }

.postdetailblock .embed-responsive {
  margin-bottom: 1.33333em; }

.postdetailblock .dropdown-toggle {
  top: 0 !important;
  transform: none !important;
  right: 0 !important; }

.postdetailblock .dropdown-menu-right {
  left: auto !important;
  right: 0 !important;
  top: 50% !important;
  transform: none !important; }

.postdetailblock .postblock__btn {
  margin-left: 15px; }

.like-btn svg {
  fill: #dee2e6; }

.like-btn--liked svg {
  fill: #495057; }

.like-btn--selfliked svg {
  fill: #0ab7c5; }

.post-slider {
  position: relative; }
  .post-slider .item {
    max-height: 400px; }
    .post-slider .item img {
      height: 100%;
      object-fit: cover; }
  .post-slider .owl-prev, .post-slider .owl-next {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: rgba(0, 0, 0, 0.7);
    padding: 4px 8px; }
  .post-slider .owl-prev {
    left: 0; }
  .post-slider .owl-next {
    right: 0; }

.sidebar__title {
  display: flex;
  justify-content: space-between;
  padding: 0.9375rem;
  border-bottom: 1px solid #dee2e6;
  font-size: 1rem; }
  .sidebar__title a {
    color: #0ab7c5; }
    .sidebar__title a:hover {
      color: #929292; }

.adblock {
  background: #fff;
  border-bottom: 1px solid #dee2e6;
  display: flex;
  padding: 0.9375rem;
  justify-content: space-between;
  position: relative;
  flex-direction: column;
  -moz-border-radius: 3px;
  -o-border-radius: 3px;
  -ms-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px; }
  .adblock__header {
    position: relative;
    margin-top: 6px;
    margin-bottom: 10px; }
    .adblock__header-title {
      color: #495057;
      font-size: 0.875rem;
      font-weight: 600;
      margin-bottom: 0;
      word-break: break-all;
      line-height: 24px; }
    .adblock__header-url {
      margin-top: 4px;
      word-break: break-all; }
  .adblock p {
    font-size: 0.875rem;
    line-height: 24px;
    word-break: break-all; }
  .adblock .adimage {
    width: 100%;
    margin: 0; }
  .adblock .adarticle {
    padding: 0.625rem 0; }
  .adblock .pricingtag {
    margin: 0.625rem 0; }
    .adblock .pricingtag .highlight-price {
      margin: 0;
      font-size: 1rem;
      font-weight: 600; }
      .adblock .pricingtag .highlight-price del:hover {
        text-decoration: line-through; }
      .adblock .pricingtag .highlight-price small {
        font-size: 80%; }
  .adblock .dropdown-menu-button {
    position: absolute;
    right: 20px;
    background: #fff;
    border: 1px solid #ced4da;
    border-radius: 4px;
    padding: 6px 2px;
    top: 20px;
    display: flex; }
    @media (min-width: 768px) {
      .adblock .dropdown-menu-button {
        display: none; } }
    .adblock .dropdown-menu-button svg {
      fill: #adb5bd;
      font-size: 14px; }
  .adblock .dropdown-menu {
    right: auto !important;
    left: 0 !important; }
  .adblock:hover .dropdown-menu-button, .adblock .card-dropdown.show .dropdown-menu-button {
    display: flex; }

.sidebar-navigation .panel-body {
  padding: 30px 0px 0px; }
  .sidebar-navigation .panel-body .thumbnail {
    border: none; }
  .sidebar-navigation .panel-body > li:not(:first-child) {
    border-top: 1px solid #e4dfdf;
    padding-top: 20px; }
  .sidebar-navigation .panel-body hr, .sidebar-navigation .panel-body br {
    display: none; }

.adBlock-extra .adblock__header {
  display: flex;
  justify-content: space-between; }

.adBlock-extra * {
  font-size: 14px; }

.adBlock-extra .custom-option__label:before, .adBlock-extra .custom-option__label:after {
  width: 18px;
  height: 18px; }

.btn {
  font-weight: 600;
  border-radius: 2px; }
  .btn-default {
    background-color: #F5F5F5; }
    .btn-default:hover, .btn-default:focus, .btn-default:active {
      background-color: #F0F0F0; }

.btn-file input[type="file"] {
  position: absolute;
  z-index: -1;
  left: -9999px;
  opacity: 0; }
  .btn-file input[type="file"] + .fa {
    margin-right: 6px; }

.btn-toggle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--bodycolor);
  padding: 0px;
  margin-bottom: 20px; }
  .btn-toggle .vital-records__title {
    margin-bottom: 0; }
  .btn-toggle .fa {
    transform: rotate(180deg);
    transition: all 0.3s ease-in-out;
    margin-right: 8px; }
  .btn-toggle[aria-expanded="true"] .fa {
    transform: rotate(270deg); }

.btn-icon {
  display: inline-flex;
  align-items: center;
  text-decoration-style: dashed;
  text-decoration-color: #0ab7c5;
  text-decoration-thickness: 0.125em;
  text-underline-offset: 1.5px; }
  .btn-icon svg {
    fill: #0ab7c5;
    margin-left: 5px; }

.profileimage {
  border: 1px solid #ced4da;
  margin-right: 2.5rem;
  border-radius: 150px;
  height: 150px;
  width: 150px;
  overflow: hidden; }

.cardCont {
  align-items: center;
  display: flex;
  margin: 2.5rem 0; }
  .cardCont.profilepic {
    justify-content: center;
    flex-wrap: wrap; }
    @media (min-width: 768px) {
      .cardCont.profilepic {
        justify-content: flex-start;
        flex-wrap: nowrap; } }
  .cardCont .profileimage {
    flex: 0 0 auto;
    margin: 0 0 2.5rem;
    position: relative; }
    @media (min-width: 768px) {
      .cardCont .profileimage {
        margin: 0 2.5rem 0 0; } }
  @media (min-width: 768px) {
    .cardCont__article {
      flex: 0 1 auto; } }

.facebookbtn {
  background: #3B5998;
  color: #fff;
  display: flex;
  align-items: center;
  padding: 10px 20px;
  font-weight: 600;
  border-radius: 3px; }
  .facebookbtn svg {
    fill: white;
    width: 30px;
    height: 30px;
    margin-right: 10px; }
  .facebookbtn:hover {
    background: #293F6B;
    color: #fff; }

.btnwrap .postblock__attachment-icon {
  border: 1px solid #ced4da;
  display: flex;
  margin: 0;
  margin-top: 20px;
  align-items: center;
  justify-content: center;
  padding: 12px;
  border-radius: 3px; }
  .btnwrap .postblock__attachment-icon i {
    font-size: 1.5rem;
    margin-right: 0.625rem; }
  .btnwrap .postblock__attachment-icon label {
    color: #929292;
    display: inline-block;
    font-size: 1rem;
    font-weight: 600; }
  .btnwrap .postblock__attachment-icon:hover {
    background: #0ab7c5;
    border-color: #0ab7c5;
    color: #fff; }
    .btnwrap .postblock__attachment-icon:hover i {
      color: #fff; }
    .btnwrap .postblock__attachment-icon:hover label {
      color: #fff; }

.icon--profilesetupnotificitionicon {
  fill: #0ab7c5;
  width: 50px;
  height: 50px; }

#toggleCont {
  align-items: center;
  cursor: pointer;
  width: 100%;
  justify-content: flex-start;
  position: relative; }
  #toggleCont:focus {
    outline: none; }
  #toggleCont svg {
    width: 30px;
    height: 30px;
    margin-right: 0.625rem; }
  #toggleCont .icon--closeicon {
    position: absolute;
    top: 5px;
    right: 0;
    margin: 0;
    width: 15px;
    height: 15px; }

#toggleCont_article {
  display: none;
  padding-top: 1.25rem; }
  @media (min-width: 768px) {
    #toggleCont_article {
      display: block;
      padding-top: 0;
      position: sticky;
      top: 20px; } }
  #toggleCont_article svg {
    display: none; }
    @media (min-width: 768px) {
      #toggleCont_article svg {
        display: block; } }

.stickytop {
  position: absolute;
  top: 2px;
  right: 30px; }
  @media (min-width: 768px) {
    .stickytop {
      right: 5px; } }

.profilesetup__header {
  position: relative; }
  .profilesetup__header-progresstitle {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.625rem; }

.profilesetup .cards {
  position: relative; }

.profilesetup .block-infographics {
  padding: 1.25rem;
  justify-content: flex-start; }
  @media (min-width: 768px) {
    .profilesetup .block-infographics {
      padding: 3.125rem; } }

.profilesetup__card-foot a {
  margin-right: 0.625rem; }

.icon--closeicon {
  display: none; }

.profilesetupform form {
  display: block;
  width: 100%; }
  .profilesetupform form textarea {
    height: 100px;
    resize: none; }

.profilesetupform .error {
  font-weight: 400;
  margin: 0;
  width: 100%; }

.profilesetupform .tokenfield .token {
  height: 26px; }

.profilesetupform .custom-option--inline {
  flex-wrap: wrap; }

.tokenfield {
  padding: 10px 10px 0; }

.payment-input-group .input-group-prepend {
  display: flex;
  z-index: 100;
  height: 100%;
  position: absolute;
  top: 0;
  left: 15px; }
  .payment-input-group .input-group-prepend .input-group-text {
    padding: 0.625rem 0.9375rem;
    border-right: 1px solid #ced4da;
    white-space: nowrap;
    display: flex;
    align-items: center;
    font-weight: 600; }

.payment-input-group .group-note {
  font-size: 14px;
  margin-top: 0.3125rem; }

.payment-input-group .input-group-text {
  display: inline-flex;
  height: 100%;
  align-items: center; }

.chipswrap.--customchips .chips {
  display: inline-flex;
  align-items: center; }

.chipswrap.--customchips .chipclose {
  font-size: 1rem;
  color: #212529;
  margin-left: 0.625rem; }

.sprogress {
  background: #fff;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  height: 10px; }

.progress {
  border-radius: 2px;
  height: 16px; }
  .progress-bar {
    background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
    background-size: 40px 40px;
    line-height: 16px;
    font-size: 12px; }

.input-datepicker {
  border: 1px solid #ccc;
  display: flex;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
  cursor: pointer; }
  .input-datepicker span.form-control {
    border: none;
    font-size: 0.875rem;
    align-items: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block; }

.select-box {
  position: relative; }
  @media (max-width: 767.98px) {
    .search-listing__header .select-box {
      width: 100%; } }
  .form-inline .select-box {
    display: inline-block; }
  .select-box select {
    appearance: none;
    padding-right: 60px; }
  .select-box:after {
    position: absolute;
    top: 0;
    right: 0;
    content: "";
    font-family: fontawesome;
    display: flex;
    align-items: center;
    padding: 10px;
    pointer-events: none;
    z-index: 3; }

.select-box:after {
  display: none; }

.select-box input[type="text"] + .fa {
  display: none; }

.custom-select select {
  background-color: white; }
  .custom-select select + .select-icons {
    display: none; }

.input-icon-box select ~ .fa {
  display: none; }

.input-icon-box input[type="text"] ~ .fa {
  display: none; }

.input-datepicker {
  padding-right: 0; }
  .input-datepicker .form-control + .fa {
    display: none; }

.custom-select > .custom-select__icon {
  z-index: 1; }
  .custom-select > .custom-select__icon ~ .custom-select__arrow {
    display: none; }

#changelanguage .goog-te-combo {
  background-color: white !important; }

#changelanguage .goog-te-gadget {
  border: 1px solid #ced4da; }

.upload-group .btnwrap .postblock__attachment-icon {
  margin: 0;
  display: inline-flex;
  padding: 10px 20px;
  cursor: pointer; }
  .upload-group .btnwrap .postblock__attachment-icon input[type="file"] {
    position: absolute;
    left: -9999px;
    opacity: 0;
    z-index: -1; }
  .upload-group .btnwrap .postblock__attachment-icon label {
    cursor: pointer; }

.input-group {
  width: 100%; }
  .input-group .input-group-addon {
    position: absolute;
    z-index: 100;
    height: 44px;
    padding: 5px;
    width: 44px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    left: 1px; }
  .input-group .form-control {
    padding-left: 50px; }
  .input-group.addon-reverese .input-group-addon {
    left: auto;
    right: 1px; }
  .input-group.addon-reverese .form-control {
    padding-left: 20px; }
  .input-group--custom {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-align: stretch;
    align-items: stretch;
    width: 100%; }
    .input-group--custom input.form-control {
      position: relative;
      -ms-flex: 1 1 auto;
      flex: 1 1 auto;
      width: 1%;
      margin-bottom: 0;
      margin-right: -1px;
      padding: 10px 20px; }
    .input-group--custom select {
      padding: 0 0 0 15px !important;
      height: auto;
      border: none;
      background: none;
      box-shadow: none;
      height: 100%; }
      .input-group--custom select.input-sm {
        line-height: normal; }
    .input-group--custom .input-group-units {
      padding: 0 9px;
      display: block; }
    .input-group--custom .select-box {
      height: 42px;
      width: 100%; }
    .input-group--custom .input-group-append,
    .input-group--custom .input-group-prepend {
      background: #e9ecef;
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 70px;
      border: 1px solid #c8c8c8; }

.error,
.form-group > label.error {
  color: #FA6541;
  font-size: 13px;
  font-weight: 400; }

.valid-feedback.feedback-icon,
.invalid-feedback.feedback-icon {
  position: absolute;
  width: auto;
  top: 47px;
  right: 10px;
  margin-top: 0; }

.is-valid,
.form-control.is-valid {
  border-color: #28a745; }

.is-invalid,
.form-control.is-invalid {
  border-color: #FA6541; }

.valid-feedback,
.invalid-feedback {
  display: block;
  width: 100%;
  margin-top: .25rem;
  font-size: 80%;
  color: #28a745; }

.invalid-feedback {
  color: #FA6541; }

.profilesetupform .custom-option__item {
  border: 1px solid #ced4da;
  padding: 8px 10px;
  margin-bottom: 0.9375rem; }
  @media (min-width: 768px) {
    .profilesetupform .custom-option__item {
      margin-bottom: 0; } }

.profilesetupform .custom-option__label:before,
.profilesetupform .custom-option__label:after {
  border-radius: 0px;
  width: 18px;
  height: 18px;
  top: 2px; }

.custom-option__square .custom-option__label:before,
.custom-option__square .custom-option__label:after {
  border: 1px solid #ced4da;
  border-radius: 0px;
  width: 18px;
  height: 18px;
  top: 2px; }

label,
.form-label {
  font-weight: 600;
  font-size: 0.875rem; }

input[type='date'] {
  text-transform: uppercase; }

.pricingoptions .custom-option {
  justify-content: center; }

.custom-option--bordered .custom-option__item {
  border: 1px solid #ced4da;
  padding: 0.4375rem 0.9375rem; }

.custom-option--bordered .custom-option__label::before {
  border: 1px solid #ced4da;
  background: #fff; }

.custom-option--bordered .custom-option__label::before, .custom-option--bordered .custom-option__label::after {
  border-radius: 0; }

.custom-option--bordered input[type="radio"]:checked ~ .custom-option__label::before {
  border-color: var(--activeColor); }

.posrelative {
  position: relative; }
  .posrelative .cvvinfo {
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 0; }
    .posrelative .cvvinfo__icon {
      --circleSize: 15px;
      color: #fff;
      width: var(--circleSize);
      height: var(--circleSize);
      display: inline-flex;
      justify-content: center;
      align-items: center;
      border-radius: var(--circleSize);
      font-size: 12px; }
      .posrelative .cvvinfo__icon img {
        width: 16px; }
    .posrelative .cvvinfo__figure {
      border-radius: 5px;
      display: none;
      margin: 0;
      width: 200px;
      z-index: 99;
      position: absolute;
      right: 0; }
      @media (min-width: 992px) {
        .posrelative .cvvinfo__figure {
          right: auto;
          left: 0; } }
      .posrelative .cvvinfo__figure img {
        border-radius: 5px;
        width: 100%;
        height: 100%;
        object-fit: cover; }
    .posrelative .cvvinfo:hover .cvvinfo__figure {
      display: block; }

.form-group.form-inlinebtn {
  display: flex; }
  .form-group.form-inlinebtn .form-inlinebtn .btn {
    flex: 1 0 auto;
    border-radius: 0; }

#noresult {
  padding-bottom: 60px; }
  #noresult form {
    max-width: 600px;
    margin: 0 auto; }

.iconinput__block {
  display: flex; }

.iconinput__icon {
  border: 1px solid #ced4da;
  padding: 0.3125rem;
  margin-right: 0.625rem;
  display: flex;
  align-items: center;
  width: 44px;
  justify-content: center; }
  .iconinput__icon svg {
    fill: #6c757d; }

.iconinput__input {
  flex-grow: 1; }

.fade.show {
  opacity: 1; }

.modal-backdrop.show {
  opacity: .5; }

small.help-block p.help-block {
  font-size: 13px;
  margin: 0px; }

.form-error {
  --color: red; }
  .form-error .form-group > label {
    color: var(--color); }
  .form-error .form-group .form-control {
    border-color: var(--color);
    color: var(--color); }
    .form-error .form-group .form-control::-webkit-input-placeholder {
      /* WebKit browsers */
      color: var(--color); }
    .form-error .form-group .form-control:-moz-placeholder {
      /* Mozilla Firefox 4 to 18 */
      color: var(--color); }
    .form-error .form-group .form-control::-moz-placeholder {
      /* Mozilla Firefox 19+ */
      color: var(--color); }
    .form-error .form-group .form-control:-ms-input-placeholder {
      /* Internet Explorer 10+ */
      color: var(--color); }

.form-group-value .form-label {
  font-weight: normal;
  font-size: 14px;
  margin-bottom: 6px; }

.form-group-value .form-value {
  font-size: 14px;
  font-weight: 600; }

.form-control-sm {
  padding: 5px 12px !important;
  font-size: 14px;
  line-height: 1.5;
  height: 33px; }

.select-box select {
  padding-right: 30px !important; }
  .select-box select option:checked ~ .fa {
    color: red;
    opacity: 1; }

.select-box .fa {
  transform: translateY(-50%);
  opacity: 0;
  pointer-events: none; }

.socialbtn-block {
  --spacer: 6px;
  display: flex;
  justify-content: space-between;
  margin: 0 calc(var(--spacer) * -1); }
  .socialbtn-block--inline {
    display: inline-flex; }

.btn-social {
  --btnHeight: 44px;
  height: var(--btnHeight);
  border-radius: 2px;
  color: white;
  display: flex;
  line-height: var(--btnHeight);
  margin: 0 var(--spacer);
  flex: 1 0 auto; }
  .btn-social__icon {
    text-align: center;
    flex: 1 1 100%;
    width: 40px;
    display: flex;
    align-items: center;
    height: var(--btnHeight);
    text-align: center;
    justify-content: center; }
    @media (min-width: 992px) {
      .btn-social__icon {
        flex: 1 0 40px;
        border-right: 1px solid rgba(255, 255, 255, 0.2); } }
    .btn-social__icon svg {
      fill: white; }
  .btn-social__label {
    padding: 0px 20px;
    display: none; }
    @media (min-width: 992px) {
      .btn-social__label {
        display: flex;
        flex: 0 0 100%; } }
  .btn-social.btn-fb {
    background-color: #708cbe;
    transition: background-color 0.5s; }
    .btn-social.btn-fb:hover, .btn-social.btn-fb:focus {
      background-color: #5e7eb6;
      color: white; }
  .btn-social.btn-gp {
    background-color: #f46946;
    transition: background-color 0.5s; }
    .btn-social.btn-gp:hover, .btn-social.btn-gp:focus {
      background-color: #f3552e;
      color: white; }
  .btn-social.btn-tw {
    background-color: #49c4ea;
    transition: background-color 0.5s; }
    .btn-social.btn-tw:hover, .btn-social.btn-tw:focus {
      background-color: #32bde7;
      color: white; }

.listcard {
  border: 1px solid #dee2e6;
  margin-bottom: 20px;
  -moz-border-radius: 3px;
  -o-border-radius: 3px;
  -ms-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px; }
  .listcard__title {
    border-bottom: 1px solid #dee2e6;
    font-size: 1.06667rem;
    font-weight: 500;
    padding: 15px; }

.listgroup {
  list-style: none;
  margin: 0;
  padding: 5px 15px; }
  .listgroup li {
    margin: 10px 0; }
  .listgroup a {
    color: #6c757d;
    display: flex;
    flex-wrap: wrap; }
    .listgroup a:hover {
      color: #4267B2;
      text-decoration: none; }
      .listgroup a:hover .listgroup__icon {
        -moz-transform: translate(5px, 0);
        -o-transform: translate(5px, 0);
        -ms-transform: translate(5px, 0);
        -webkit-transform: translate(5px, 0);
        transform: translate(5px, 0); }
  .listgroup__icon {
    margin-right: 10px;
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s; }
  .listgroup__meta {
    margin-left: auto; }

.pagination-no-border #pagination {
  border: none;
  padding: 0; }

.postcard {
  background: #fff;
  margin: 0 0 30px;
  box-shadow: 0px 6px 16.2px 1.8px rgba(224, 224, 224, 0.67); }
  .postcard .entry-figure img {
    width: 100%;
    height: 100%;
    object-fit: cover; }
  .postcard .entry-main {
    border-bottom: 1px solid #e9ecef;
    margin: 0 15px; }
  .postcard .entry-title {
    font-size: 1.2rem;
    font-weight: 500;
    line-height: 1.5;
    margin: 10px 0; }
  .postcard .entry-date {
    color: #adb5bd;
    margin-bottom: 10px; }
  .postcard .entry-footer {
    align-items: center;
    display: flex;
    padding: 15px;
    justify-content: space-between; }
  .postcard .entry-author {
    display: flex;
    align-items: center; }
    .postcard .entry-author .author-img {
      width: 35px;
      height: 35px;
      border-radius: 50%;
      overflow: hidden;
      margin: 0 10px 0 0; }
    .postcard .entry-author img {
      object-fit: cover;
      width: 100%;
      height: 100%; }
    .postcard .entry-author .author-name {
      font-weight: 500;
      font-size: 0.93333rem; }
  .postcard .entry-action a {
    color: #6c757d;
    margin-left: 10px; }

.sharepost {
  transition: all 0.3s ease 0s; }
  .sharepost:hover .shareoptions {
    max-height: 100px;
    opacity: 1;
    visibility: visible; }

.shareoptions {
  background: #fff;
  border: 1px solid #ced4da;
  display: flex;
  align-items: center;
  position: absolute;
  right: 0;
  max-height: 0;
  transition: all 0.3s ease 0s;
  opacity: 0;
  visibility: hidden; }
  .shareoptions__item {
    cursor: pointer;
    display: inline-flex;
    margin: 0.625rem; }
    .shareoptions__item:hover {
      fill: #0ab7c5; }

@media (max-width: 767.98px) {
  .sidebar-action {
    height: 100%;
    overflow: auto;
    z-index: 9999;
    background: #fff;
    width: 100%;
    padding: 20px;
    opacity: 0;
    position: fixed;
    top: -100%;
    left: 0;
    -webkit-transition: all 0.4s ease 0s;
    -moz-transition: all 0.4s ease 0s;
    -o-transition: all 0.4s ease 0s;
    transition: all 0.4s ease 0s; } }

.sidebar-mobile {
  top: 0 !important;
  opacity: 1 !important; }

.blue-btn {
  background: #4267B2;
  border-radius: 3px;
  color: #fff;
  display: block;
  max-width: 100%;
  margin: 0 auto;
  width: 480px;
  padding: 15px;
  text-align: center;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s; }
  .blue-btn:hover {
    background: #34518d;
    color: #fff;
    text-decoration: none; }

.search-page {
  padding: 10px 0 20px 0; }
  @media (min-width: 576px) {
    .search-page {
      padding: 60px 0; } }

.search-title {
  border-bottom: 1px solid #dee2e6;
  color: #212529;
  display: flex;
  font-size: 1.06667rem;
  font-weight: 400;
  justify-content: space-between;
  margin-bottom: 15px;
  padding-bottom: 15px; }
  .search-title .fas {
    cursor: pointer;
    font-size: 1.2rem; }

.search-listing__header {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between; }

.search-listing__header-sort, .search-listing__header-hl {
  position: relative; }
  .search-listing__header-sort select, .search-listing__header-hl select {
    background: none;
    border: none;
    padding: 5px 20px 10px 0;
    position: relative;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    z-index: 2; }
    .search-listing__header-sort select:focus, .search-listing__header-hl select:focus {
      outline: none; }
  .search-listing__header-sort:after, .search-listing__header-hl:after {
    content: "\f107";
    display: block;
    font-family: Font Awesome\ 5 Free;
    font-weight: 900;
    font-size: 1.06667rem;
    z-index: 1;
    position: absolute;
    top: 6px;
    right: 0; }

.search-doctors-fields--search-form .form-group {
  margin-bottom: 30px; }
  .search-doctors-fields--search-form .form-group .fa-search {
    cursor: pointer;
    color: #adb5bd;
    pointer-events: inherit; }

.search-doctors-fields--search-form .search-doctors-fields__search {
  background: none;
  border: none;
  border-bottom: 1px solid #dee2e6;
  padding: 10px 35px 10px 15px !important; }
  .search-doctors-fields--search-form .search-doctors-fields__search::-webkit-input-placeholder {
    /* WebKit browsers */
    color: #4267B2; }
  .search-doctors-fields--search-form .search-doctors-fields__search:-moz-placeholder {
    /* Mozilla Firefox 4 to 18 */
    color: #4267B2; }
  .search-doctors-fields--search-form .search-doctors-fields__search::-moz-placeholder {
    /* Mozilla Firefox 19+ */
    color: #4267B2; }
  .search-doctors-fields--search-form .search-doctors-fields__search:-ms-input-placeholder {
    /* Internet Explorer 10+ */
    color: #4267B2; }

.search-doctors-fields .position-relative img {
  position: absolute;
  right: 10px;
  top: 18px;
  width: 16px; }

.search-doctors-fields input:not([type="submit"]),
.search-doctors-fields input:not([type="reset"]),
.search-doctors-fields select {
  height: 3.46667rem !important;
  padding-right: 2rem;
  padding-left: 2rem;
  appearance: none; }
  .search-doctors-fields input:not([type="submit"])::-webkit-input-placeholder,
  .search-doctors-fields input:not([type="reset"])::-webkit-input-placeholder,
  .search-doctors-fields select::-webkit-input-placeholder {
    /* WebKit browsers */
    color: #adb5bd; }
  .search-doctors-fields input:not([type="submit"]):-moz-placeholder,
  .search-doctors-fields input:not([type="reset"]):-moz-placeholder,
  .search-doctors-fields select:-moz-placeholder {
    /* Mozilla Firefox 4 to 18 */
    color: #adb5bd; }
  .search-doctors-fields input:not([type="submit"])::-moz-placeholder,
  .search-doctors-fields input:not([type="reset"])::-moz-placeholder,
  .search-doctors-fields select::-moz-placeholder {
    /* Mozilla Firefox 19+ */
    color: #adb5bd; }
  .search-doctors-fields input:not([type="submit"]):-ms-input-placeholder,
  .search-doctors-fields input:not([type="reset"]):-ms-input-placeholder,
  .search-doctors-fields select:-ms-input-placeholder {
    /* Internet Explorer 10+ */
    color: #adb5bd; }
  .search-doctors-fields input:not([type="submit"]) ~ .fas,
  .search-doctors-fields input:not([type="reset"]) ~ .fas,
  .search-doctors-fields select ~ .fas {
    position: absolute;
    top: 20px;
    right: 15px;
    font-size: 0.93333rem;
    pointer-events: none;
    color: #6c757d; }

@media (min-width: 768px) {
  .search-doctors-fields__search {
    box-shadow: none !important;
    border-top-right-radius: 0px;
    border-bottom-left-radius: 0px;
    border-right: none; } }

@media (min-width: 768px) {
  .search-doctors-fields__specialization {
    box-shadow: none !important;
    border-top-right-radius: 0px;
    border-bottom-left-radius: 0px; } }

@media (min-width: 768px) {
  .search-doctors-fields button[type="submit"],
  .search-doctors-fields input[type="submit"] {
    box-shadow: none !important;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px; } }

.radio_accordion {
  border: 1px solid #dee2e6;
  border-radius: 0.26667rem;
  margin-bottom: 20px; }
  .radio_accordion .btn-link {
    width: 100%;
    text-align: left;
    padding: 15px 10px; }
    .radio_accordion .btn-link .acc-title {
      color: #212529;
      float: left;
      font-size: 1.06667rem; }
    .radio_accordion .btn-link .acc-arrow {
      color: #212529;
      float: right; }
  .radio_accordion .btn-link.collapsed .fa-angle-down {
    display: none; }
  .radio_accordion .btn-link .fa-angle-down {
    display: inline-block; }
  .radio_accordion .btn-link .fa-angle-right {
    display: none; }
  .radio_accordion .btn-link.collapsed .fa-angle-right {
    display: inline-block; }
  .radio_accordion .collapse {
    border-top: 1px solid #dee2e6; }
  .radio_accordion .pricing-slider {
    padding: 10px 15px; }
    .radio_accordion .pricing-slider img {
      max-width: 100%; }

.custom-select {
  position: relative;
  margin: 1rem 0; }
  .custom-select__select {
    width: 100%;
    height: 44px;
    display: block;
    border: 1px solid #ced4da;
    border-radius: 0;
    padding: 0.625rem 2.5rem 0.625rem 0.625rem;
    outline: none;
    text-overflow: ellipsis;
    -webkit-appearance: button; }
    -moz-appearance:none .custom-select__select::-ms-expand {
      display: none; }
    .custom-select--with-icon .custom-select__select {
      padding-left: 3rem; }

.custom-select .select-icons {
  align-items: center;
  display: flex;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  user-select: none;
  cursor: pointer;
  pointer-events: none; }

.custom-select__icon {
  left: 1rem; }

.custom-select__arrow {
  right: 1rem; }

.inlineItems {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap; }

.inlineItems {
  margin: 0 -0.3125rem; }
  .inlineItems li {
    padding: 0.3125rem; }

.cols-4 .inlineItems li {
  width: 25%; }

.widget-section .menu li a {
  color: #343a40; }

.footer-bottom {
  padding-top: 1rem; }
  @media (max-width: 767.98px) {
    .footer-bottom {
      display: none; } }
  .footer-bottom p {
    font-size: 0.93333rem; }
  @media (max-width: 767.98px) {
    .footer-bottom .social-links {
      margin: 0 auto; } }
  .footer-bottom .social-links li {
    margin: 0 0.3125rem; }
    .footer-bottom .social-links li a {
      font-size: 0.93333rem;
      padding: 6px; }

.widget a.title {
  font-size: 18px; }

@media (min-width: 576px) {
  .widget .collapse:not(.show) {
    display: block; } }

@media (max-width: 767.98px) {
  .widget-section {
    padding: 0px !important; } }

.sidebar-navigation {
  background: #fff;
  padding: 1.25rem;
  position: sticky;
  top: 50px; }
  .sidebar-navigation .menu li a {
    align-items: center;
    display: flex;
    padding: 0.625rem 0; }
    .sidebar-navigation .menu li a svg {
      fill: #929292;
      margin-right: 0.625rem; }
    .sidebar-navigation .menu li a:hover {
      background: none; }
      .sidebar-navigation .menu li a:hover svg {
        fill: #0ab7c5; }
    .sidebar-navigation .menu li a.active {
      background: none;
      color: #0ab7c5; }
      .sidebar-navigation .menu li a.active svg {
        fill: #0ab7c5; }
  .sidebar-navigation .progress {
    box-shadow: 1px 0px 2px rgba(0, 0, 0, 0.1);
    height: 16px; }
    .sidebar-navigation .progress-bar {
      line-height: 16px;
      font-size: 12px; }
    .sidebar-navigation .progress-note {
      color: #0ab7c5;
      margin-top: 0.625rem;
      font-size: 14px; }

.time-list {
  border: none;
  margin-right: -15px !important; }
  .time-list li {
    width: calc(33.33% - 15px);
    border: none;
    height: 50px;
    margin-right: 15px !important;
    margin-bottom: 15px !important; }
    @media (min-width: 768px) {
      .time-list li {
        width: calc(16.66% - 15px); } }
    .time-list li label {
      background-color: #fff;
      border: 1px solid #ccc;
      border-radius: 2px;
      font-weight: 600; }
    .time-list li input:checked + label {
      background-color: #DDF3FC;
      color: #6c757d;
      font-weight: 600; }

.time-list-item {
  display: flex;
  align-items: center;
  padding: 10px 0; }
  .time-list-option2 .time-list-item {
    flex-wrap: wrap; }
    @media (min-width: 768px) {
      .time-list-option2 .time-list-item {
        flex-wrap: nowrap; } }
  .time-list-item .time-list-time {
    flex: 0 0 90px; }
  .time-list-item .time-list-slot {
    flex: 1;
    overflow-x: auto; }
    .time-list-option2 .time-list-item .time-list-slot {
      overflow-x: visible;
      margin-top: 20px; }
      @media (min-width: 768px) {
        .time-list-option2 .time-list-item .time-list-slot {
          margin-top: 0; } }

.time-list-new {
  --margin: 10px;
  display: flex;
  margin-bottom: 0px !important;
  margin: 0 -var(--margin);
  justify-content: space-between; }
  .time-list-new li {
    flex: 1 0 110px;
    display: flex;
    flex-wrap: nowrap; }
    .time-list-option2 .time-list-new li {
      flex: 1 1 110px; }
      @media (min-width: 768px) {
        .time-list-option2 .time-list-new li {
          flex: 1 0 110px; } }
  .time-list-new label {
    background-color: #fff;
    border: 1px solid #495057;
    border-radius: 2px;
    text-align: center;
    margin: 0 6px;
    flex: 1;
    padding: 8px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center; }
  .time-list-new input {
    display: none; }
    .time-list-new input:checked + label {
      background-color: rgba(0, 175, 240, 0.15);
      color: #0ab7c5;
      font-weight: 600;
      border-color: #0ab7c5; }
    .time-list-new input:disabled + label {
      background-color: rgba(3, 181, 170, 0.15);
      color: #03B5AA;
      font-weight: 600;
      border-color: #03B5AA;
      opacity: 0.5;
      cursor: not-allowed;
      pointer-events: stroke; }

@media (min-width: 768px) {
  .time-list-wrap {
    max-height: 400px;
    overflow-y: auto; } }

.time-list-option3 {
  flex-wrap: wrap;
  justify-content: flex-start;
  align-self: Center;
  margin: 0 auto;
  text-align: center; }
  .time-list-option3 .time-list-new {
    flex-wrap: wrap;
    justify-content: flex-start;
    align-self: Center;
    margin: 0 auto;
    text-align: center; }
  .time-list-option3 li {
    flex: 1 1 auto;
    margin-bottom: 15px; }

.form-switch {
  display: flex;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  justify-content: space-between;
  margin-bottom: 20px; }

.form-switch i {
  position: relative;
  display: inline-block;
  width: 36px;
  height: 20px;
  background-color: #8391A6;
  border-radius: 23px;
  transition: all 0.3s linear; }

.form-switch i::after {
  content: "";
  position: absolute;
  left: 0;
  width: 16px;
  height: 16px;
  background-color: #fff;
  border-radius: 50%;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.24);
  transform: translate3d(2px, 2px, 0);
  transition: all 0.2s ease-in-out; }

.form-switch input {
  display: none; }

.form-switch input:checked + i {
  background-color: #0274D6; }

.form-switch input:checked + i::after {
  transform: translate3d(18px, 2px, 0); }

.tableBlock__item {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 1.25rem;
  padding-bottom: 1.25rem;
  border-bottom: 1px solid #ced4da; }
  .tableBlock__item-title {
    margin-bottom: 0.625rem;
    font-weight: 600; }
  .tableBlock__item-subtitle {
    font-size: 14px;
    margin-bottom: 0.625rem;
    flex: 1 1 100%; }
  .tableBlock__item-note {
    font-size: 14px;
    color: #6c757d; }
  .tableBlock__item-list {
    display: flex;
    align-items: baseline;
    margin: 5px 0; }
    .tableBlock__item-list svg {
      margin-right: 0.625rem;
      fill: #6c757d;
      flex: 0 0 14px; }
  .tableBlock__item-action a {
    margin-left: 0.625rem; }
  .tableBlock__item-action svg {
    fill: #343a40; }
  .tableBlock__item-cont {
    flex-basis: 0;
    flex-grow: 1;
    margin-bottom: 0.625rem; }
    .tableBlock__item-cont:first-child {
      display: flex;
      flex-direction: column;
      max-width: 100%;
      flex-basis: 100%; }
      @media (min-width: 768px) {
        .tableBlock__item-cont:first-child {
          max-width: 50%;
          flex-basis: 50%; } }
    .tableBlock__item-cont:last-child {
      display: flex;
      justify-content: flex-end; }
    .tableBlock__item-cont:nth-child(2) {
      display: flex;
      align-items: flex-start;
      flex-direction: column; }
  .tableBlock__item.--tableBlock__bottomitem > .tableBlock__item-subtitle {
    margin-top: 0.625rem;
    margin-bottom: 0; }

.tableblock {
  align-items: center;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%; }
  .tableblock__item {
    display: flex;
    flex-direction: column; }
    .tableblock__item-title {
      align-items: center;
      display: flex;
      font-size: 2.5rem;
      margin-top: 0.625rem; }
      .tableblock__item-title .btn {
        margin-left: 0.625rem;
        padding: 0.3125rem 0.9375rem; }
    .tableblock__item-row {
      align-items: center;
      flex-direction: row; }

.listingactions__item {
  --itemColor: #495057;
  --itemhoverColor: #0ab7c5;
  margin-left: 0.625rem;
  color: var(--itemColor);
  display: inline-flex;
  cursor: pointer;
  transition: all 0.3s ease 0s; }
  .listingactions__item svg {
    fill: var(--itemColor); }
  .listingactions__item:hover {
    color: var(--itemhoverColor); }
    .listingactions__item:hover svg {
      fill: var(--itemhoverColor); }

.form-switch span {
  display: none; }
  .form-switch span.disabled {
    display: inline-block; }

.form-switch input:checked ~ div .enabled {
  display: inline-block; }

.form-switch input:checked ~ div .disabled {
  display: none; }

.time-tab .ui-tabs-anchor {
  padding: 10px 0 !important;
  display: block;
  color: #212529;
  margin-bottom: 0px;
  font-size: 16px;
  font-weight: normal;
  background: transparent !important;
  border-radius: 0px; }
  @media (min-width: 768px) {
    .time-tab .ui-tabs-anchor {
      padding: 20px 22px; } }
  .time-tab .ui-tabs-anchor:hover {
    border: none; }
  .time-tab .ui-tabs-anchor + div {
    display: none; }

.time-tab .tab-list {
  width: 100%;
  background-color: #fff !important;
  box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.1);
  padding: 0 !important;
  margin-bottom: 20px !important; }
  .time-tab .tab-list > li {
    width: calc(100%/7);
    border-radius: 0px; }

.time-tab .ui-state-active a {
  background-color: #0ab7c5 !important; }

.time-tab .ui-state-default {
  background-color: #fff !important; }

.list-hint {
  padding: 0px;
  margin: 0px;
  display: flex;
  align-items: center;
  margin: 0 -6px;
  flex-wrap: wrap;
  position: relative;
  padding-left: 30px; }
  .list-hint:before {
    content: url(../images/info-icon.svg);
    position: absolute;
    top: 6px;
    left: 0; }
  .list-hint > li {
    padding: 6px;
    font-size: 0.93333rem; }
  .list-hint__icon {
    --size:14px;
    width: var(--size);
    height: var(--size);
    border: 1px solid transparent;
    display: inline-block; }
    .list-hint__icon--scheduled {
      background-color: rgba(0, 175, 240, 0.15);
      border-color: #0ab7c5; }
    .list-hint__icon--unscheduled {
      background-color: #fff;
      border-color: #495057; }
    .list-hint__icon--booked {
      background-color: rgba(3, 181, 170, 0.15);
      border-color: #03B5AA; }

@media (max-width: 991.98px) {
  .schedule-block .tabcontent {
    padding: 0px; } }

@keyframes circleFadeOut {
  0% {
    background: white;
    transform: scale(0);
    opacity: 0; }
  50% {
    background: rgba(0, 175, 240, 0.2);
    transform: scale(1.5);
    opacity: 1; }
  100% {
    background: white;
    transform: scale(3);
    opacity: 0; } }

.card.--introcard {
  background: none;
  text-align: center; }
  .card.--introcard .card__body-title {
    margin: 1.5625rem 0;
    font-size: 1.25rem;
    font-weight: 600; }
  .card.--introcard .card__thumbnail img {
    max-width: 100%; }

.card.--borderedcard {
  border: 1px solid #ced4da;
  padding: 1.25rem; }
  @media (min-width: 992px) {
    .card.--borderedcard {
      padding: 1.875rem; } }

.card.--colorcard {
  --bgcolor: $gray-200;
  border: 1px solid #EEEEEE;
  border-radius: 3px;
  background: var(--bgcolor); }
  .card.--colorcard .card__body {
    background: #fff;
    padding: 3.125rem 0 1.875rem; }
    .card.--colorcard .card__body-title {
      font-weight: 700;
      text-transform: uppercase;
      font-size: 1rem; }
  .card.--colorcard .card__thumbnail {
    margin: 3.125rem auto -1.25rem; }

.card--default {
  background-color: transparent; }
  .card--default .card-body {
    margin-top: 1.33333em; }
  .card--default .card-thumbnail {
    border: 1px solid #ced4da; }
    .card--default .card-thumbnail img {
      max-width: 100%;
      height: auto; }
  .card--default .card-title {
    font-weight: 600;
    font-size: 1rem; }
  .card--default .card-text {
    font-size: 0.93333rem; }

.arrowlink {
  display: inline-flex;
  margin-top: 1.25rem;
  position: relative;
  padding: 0.625rem; }
  .arrowlink::before {
    --circleSize: 55px;
    content: "";
    display: block;
    width: var(--circleSize);
    height: var(--circleSize);
    border-radius: var(--circleSize);
    position: absolute;
    top: 0;
    bottom: 0;
    left: -3px;
    margin: auto;
    z-index: 0; }
  .arrowlink .icon {
    width: 30px;
    fill: #0ab7c5; }
  .arrowlink:hover::before {
    animation: 0.6s circleFadeOut ease-out; }

.introcontent {
  margin-bottom: 1.875rem; }
  @media (min-width: 992px) {
    .introcontent {
      margin-bottom: 0; } }

.cards--shadow {
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.16); }

.cards--secondary {
  border-radius: 0 16px 0 16px;
  box-shadow: 4px 8px 20px rgba(0, 0, 0, 0.16);
  padding: 20px;
  transition: all 0.3s ease-in-out;
  transform: translateY(0); }
  .cards--secondary:hover {
    transform: translateY(-4px);
    box-shadow: 8px 12px 20px rgba(0, 0, 0, 0.16); }
    .cards--secondary:hover .team--new .team__figure {
      transform: translateY(-6px);
      animation: myfirst 2s linear 0.3s 1 forwards; }

.cards__header {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  justify-content: space-between; }
  .cards__header:before, .cards__header:after {
    display: none; }

.cards__title {
  flex: 1 0 auto;
  margin-bottom: 10px; }

.cards .header-action {
  flex: 0 1 auto; }

.cards[data-featured*="true"] {
  border: 1px solid #0ab7c5;
  box-shadow: 0px 2px 10px rgba(0, 175, 240, 0.2); }
  .cards[data-featured*="true"]:hover {
    box-shadow: 0px 6px 18px rgba(0, 175, 240, 0.4); }

.cards[data-featured*="false"] .ribbon {
  display: none; }

.testimonial-block {
  margin: 0 auto;
  position: relative; }
  .testimonial-block .client-thumb {
    width: 200px;
    height: 200px;
    border-radius: 50%;
    overflow: hidden;
    margin: 0 auto;
    vertical-align: middle; }
    .testimonial-block .client-thumb img {
      height: 100%;
      object-fit: cover; }
    .testimonial-block .client-thumb::before {
      position: absolute;
      top: 0;
      right: 400px;
      left: 0;
      background: url("../images/quote.png") no-repeat;
      content: "";
      display: block;
      width: 120px;
      height: 90px;
      margin: auto; }
  .testimonial-block .client-content {
    vertical-align: middle;
    margin-top: 20px;
    color: white;
    text-align: center; }
    .testimonial-block .client-content h3,
    .testimonial-block .client-content p {
      color: inherit; }
    .testimonial-block .client-content h3 {
      font-weight: 600; }

.pricingtablecontainer {
  display: flex;
  flex-wrap: nowrap;
  overflow: auto;
  padding: 30px 0;
  scroll-snap-type: x mandatory;
  /* Chrome Canary */
  scroll-snap-type: mandatory;
  /* Firefox */
  -ms-scroll-snap-type: mandatory;
  /* IE/Edge */
  -webkit-scroll-snap-type: mandatory;
  /* Safari */
  -webkit-scroll-snap-destination: 0% 0%;
  -webkit-overflow-scrolling: touch;
  /* important for iOS */
  display: grid;
  grid-template-columns: repeat(3, minmax(300px, 1fr));
  grid-gap: 15px;
  grid-auto-flow: column; }

.pricingtable {
  padding: 0px;
  border: 1px solid #DBDBDB;
  border-radius: 5px;
  background-color: #fff;
  flex: 1 0 100%;
  scroll-snap-align: start;
  /* latest (Chrome 69+) */
  scroll-snap-coordinate: 0% 0%;
  /* older (Firefox/IE) */
  -webkit-scroll-snap-coordinate: 0% 0%;
  /* older (Safari) */
  display: flex;
  flex-direction: column; }
  .pricingtable__body {
    margin-top: 1.25rem; }
    .pricingtable__header + .pricingtable__body {
      margin-top: 0px; }
  .pricingtable__footer {
    padding-bottom: 20px; }
    .pricingtable__footer .btn-box + .pricingtable__btn {
      margin: 15px 0 0 0;
      padding: 0px; }
  .pricingtable.js-priceHighlights .btn-box {
    position: relative; }
    .pricingtable.js-priceHighlights .btn-box:before {
      position: absolute;
      bottom: 100%;
      left: 0;
      content: " ";
      height: 80px;
      width: 100%;
      background: linear-gradient(0deg, #fbfbfb 20%, transparent 100%); }
  .pricingtable .js-extra {
    display: none; }
  .pricingtable .yearlypricing {
    display: none; }
  .pricingtable__listBlock ul {
    padding-top: 0 !important; }
    .pricingtable__listBlock ul li {
      margin: 0px; }
  .pricingtable__listBlock__title {
    background-color: #e9ecef;
    padding: 15px 20px;
    font-weight: bold;
    margin-bottom: 0;
    text-transform: capitalize;
    margin: 0 10px; }
  .pricingtable ul {
    padding: 10px; }
  .pricingtable li {
    display: flex;
    padding: 1em 0.66667em;
    align-items: center;
    justify-content: space-between; }
    .pricingtable li:not(:last-child) {
      border-bottom: 1px solid #DBDBDB; }
    .pricingtable li.disable {
      opacity: 0.5; }
    .pricingtable li.pricingtable__head {
      border: none;
      text-transform: uppercase;
      justify-content: center;
      padding: 1.25rem 0.625rem;
      font-size: 2.5rem; }
      .pricingtable li.pricingtable__head small {
        font-size: 14px;
        margin-left: 0.3125rem;
        text-transform: lowercase; }
    .pricingtable li.pricingtable__highlight {
      border: none;
      color: #fff;
      padding: 0.625rem;
      font-size: 1.6rem;
      justify-content: center;
      margin-bottom: 0; }
  .pricingtable .pricingtable__btn {
    border: none;
    display: flex;
    justify-content: center;
    margin: 1.25rem 0; }
  .pricingtable .basic, .pricingtable .premium, .pricingtable .pro {
    position: sticky;
    top: 0;
    background: white;
    z-index: 100;
    margin-bottom: 0px; }
  .pricingtable .basic .pricingtable__highlight {
    background: rgba(0, 175, 240, 0.5); }
  .pricingtable .pro .pricingtable__highlight {
    background: rgba(96, 221, 77, 0.5); }
  .pricingtable .premium .pricingtable__highlight {
    background: rgba(242, 57, 148, 0.5); }

.slideToggle {
  display: flex;
  justify-content: center;
  margin: 50px 0; }
  .slideToggle i {
    margin: 0 15px; }

.form-switch {
  align-items: center;
  display: flex;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  justify-content: space-between;
  margin-bottom: 0; }

.form-switch i {
  position: relative;
  display: inline-block;
  width: 100px;
  height: 30px;
  border: 1px solid #DFDFDF;
  border-radius: 15px;
  transition: all 0.3s linear; }

.form-switch i::after {
  content: "";
  position: absolute;
  left: 0;
  width: 40px;
  height: 22px;
  background-color: #00CE6C;
  border-radius: 15px;
  transform: translate3d(4px, 3px, 0);
  transition: all 0.2s ease-in-out; }

.form-switch input {
  display: none; }

.form-switch input:checked + i::after {
  transform: translate3d(54px, 3px, 0); }

.easy-autocomplete {
  position: relative;
  width: 100% !important; }

.easy-autocomplete-container {
  position: absolute;
  top: 100%;
  left: 0;
  background: white;
  text-align: left;
  color: #212529;
  width: 100%;
  max-height: 300px;
  overflow-y: auto;
  box-shadow: 0px 10px 9px 0px rgba(0, 0, 0, 0.3);
  z-index: 800; }
  .easy-autocomplete-container ul {
    padding: 0px; }
    .easy-autocomplete-container ul > li {
      list-style: none;
      /* padding: 0px !important; */
      padding: 10px 20px;
      border-bottom: 1px solid #e9ecef;
      margin: 0px; }
      .easy-autocomplete-container ul > li:last-child {
        margin-bottom: 0px;
        border: none; }
      .easy-autocomplete-container ul > li a {
        color: #212529; }
    .easy-autocomplete-container ul .eac-category {
      color: #ced4da;
      font-weight: 600;
      /* border-bottom: 1px solid #ccc; */
      padding: 10px 20px;
      padding-bottom: 0px;
      font-size: 0.875rem; }

.croppie-container {
  width: 100%;
  height: 100%; }

.croppie-container .cr-image {
  z-index: -1;
  position: absolute;
  top: 0;
  left: 0;
  transform-origin: 0 0;
  max-height: none;
  max-width: none; }

.croppie-container .cr-boundary {
  position: relative;
  overflow: hidden;
  margin: 0 auto;
  z-index: 1;
  width: 100%;
  height: 100%; }

.croppie-container .cr-viewport,
.croppie-container .cr-resizer {
  position: absolute;
  border: 2px solid #fff;
  margin: auto;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  box-shadow: 0 0 2000px 2000px rgba(0, 0, 0, 0.5);
  z-index: 0; }

.croppie-container .cr-resizer {
  z-index: 2;
  box-shadow: none;
  pointer-events: none; }

.croppie-container .cr-resizer-vertical,
.croppie-container .cr-resizer-horisontal {
  position: absolute;
  pointer-events: all; }

.croppie-container .cr-resizer-vertical::after,
.croppie-container .cr-resizer-horisontal::after {
  display: block;
  position: absolute;
  box-sizing: border-box;
  border: 1px solid black;
  background: #fff;
  width: 10px;
  height: 10px;
  content: ''; }

.croppie-container .cr-resizer-vertical {
  bottom: -5px;
  cursor: row-resize;
  width: 100%;
  height: 10px; }

.croppie-container .cr-resizer-vertical::after {
  left: 50%;
  margin-left: -5px; }

.croppie-container .cr-resizer-horisontal {
  right: -5px;
  cursor: col-resize;
  width: 10px;
  height: 100%; }

.croppie-container .cr-resizer-horisontal::after {
  top: 50%;
  margin-top: -5px; }

.croppie-container .cr-original-image {
  display: none; }

.croppie-container .cr-vp-circle {
  border-radius: 50%; }

.croppie-container .cr-overlay {
  z-index: 1;
  position: absolute;
  cursor: move;
  touch-action: none; }

.croppie-container .cr-slider-wrap {
  width: 75%;
  margin: 15px auto;
  text-align: center; }

.croppie-result {
  position: relative;
  overflow: hidden; }

.croppie-result img {
  position: absolute; }

.croppie-container .cr-image,
.croppie-container .cr-overlay,
.croppie-container .cr-viewport {
  -webkit-transform: translateZ(0);
  -moz-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0); }

/*************************************/
/***** STYLING RANGE INPUT ***********/
/*************************************/
/*http://brennaobrien.com/blog/2014/05/style-input-type-range-in-every-browser.html */
/*************************************/
.cr-slider {
  -webkit-appearance: none;
  /*removes default webkit styles*/
  /*border: 1px solid white; */
  /*fix for FF unable to apply focus style bug */
  width: 300px;
  /*required for proper track sizing in FF*/
  max-width: 100%;
  padding-top: 8px;
  padding-bottom: 8px;
  background-color: transparent; }

.cr-slider::-webkit-slider-runnable-track {
  width: 100%;
  height: 3px;
  background: rgba(0, 0, 0, 0.5);
  border: 0;
  border-radius: 3px; }

.cr-slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  border: none;
  height: 16px;
  width: 16px;
  border-radius: 50%;
  background: #ddd;
  margin-top: -6px; }

.cr-slider:focus {
  outline: none; }

/*
.cr-slider:focus::-webkit-slider-runnable-track {
background: #ccc;
}
*/
.cr-slider::-moz-range-track {
  width: 100%;
  height: 3px;
  background: rgba(0, 0, 0, 0.5);
  border: 0;
  border-radius: 3px; }

.cr-slider::-moz-range-thumb {
  border: none;
  height: 16px;
  width: 16px;
  border-radius: 50%;
  background: #ddd;
  margin-top: -6px; }

/*hide the outline behind the border*/
.cr-slider:-moz-focusring {
  outline: 1px solid white;
  outline-offset: -1px; }

.cr-slider::-ms-track {
  width: 100%;
  height: 5px;
  background: transparent;
  /*remove bg colour from the track, we'll use ms-fill-lower and ms-fill-upper instead */
  border-color: transparent;
  /*leave room for the larger thumb to overflow with a transparent border */
  border-width: 6px 0;
  color: transparent;
  /*remove default tick marks*/ }

.cr-slider::-ms-fill-lower {
  background: rgba(0, 0, 0, 0.5);
  border-radius: 10px; }

.cr-slider::-ms-fill-upper {
  background: rgba(0, 0, 0, 0.5);
  border-radius: 10px; }

.cr-slider::-ms-thumb {
  border: none;
  height: 16px;
  width: 16px;
  border-radius: 50%;
  background: #ddd;
  margin-top: 1px; }

.cr-slider:focus::-ms-fill-lower {
  background: rgba(0, 0, 0, 0.5); }

.cr-slider:focus::-ms-fill-upper {
  background: rgba(0, 0, 0, 0.5); }

/*******************************************/
/***********************************/
/* Rotation Tools */
/***********************************/
.cr-rotate-controls {
  position: absolute;
  bottom: 5px;
  left: 5px;
  z-index: 1; }

.cr-rotate-controls button {
  border: 0;
  background: none; }

.cr-rotate-controls i:before {
  display: inline-block;
  font-style: normal;
  font-weight: 900;
  font-size: 22px; }

.cr-rotate-l i:before {
  content: '↺'; }

.cr-rotate-r i:before {
  content: '↻'; }

label.cabinet {
  display: block;
  font-weight: normal;
  cursor: pointer; }
  label.cabinet .textinput {
    display: flex;
    align-items: center; }

label.cabinet input.file {
  position: relative;
  height: 100%;
  width: auto;
  opacity: 0;
  -moz-opacity: 0;
  filter: progid:DXImageTransform.Microsoft.Alpha(opacity=0);
  margin-top: -30px; }

#upload-demo {
  width: 100%;
  height: 250px;
  padding-bottom: 25px; }

.--apmt-media {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center; }
  .--apmt-media .media-left {
    display: flex;
    flex: 1 0 auto;
    flex-direction: column; }
  .--apmt-media .media-body {
    display: flex;
    flex-direction: column; }
  .--apmt-media .--media-time {
    justify-content: flex-end; }
    .--apmt-media .--media-time:last-child {
      margin-bottom: 0; }
  .--apmt-media .apmt__users {
    margin-bottom: 5px !important; }

.media--flex {
  display: flex; }
  .media--flex .media__left {
    margin-right: 20px; }
    .media--flex .media__left .media__thumbnail--100 {
      --size:100px;
      width: var(--size);
      height: var(--size);
      background-color: #F2F2F2;
      overflow: hidden; }
  .media--flex .media__body {
    flex: 1;
    position: relative; }
  .media--flex .media__header {
    display: flex;
    justify-content: space-between; }
  .media--flex .media__title {
    line-height: 1.6;
    flex: 1 1 100%;
    width: 100px; }
    @media (min-width: 768px) {
      .media--flex .media__title {
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap; } }
  .media--flex .media__action {
    flex: 1 0 auto; }

.media--bordered:not(:last-of-type) {
  border-bottom: 1px solid #DBDBDB; }

@media (max-width: 767.98px) {
  .media-body {
    width: auto; } }

.media-heading {
  font-size: 18px;
  line-height: normal; }

.circlecheck {
  --circleSize: 18px;
  --bgcolor: $gray-500;
  align-items: center;
  background: var(--bgcolor);
  border-radius: var(--circleSize);
  display: flex;
  height: var(--circleSize);
  justify-content: center;
  width: var(--circleSize);
  flex: 0 0 var(--circleSize); }
  .circlecheck svg {
    width: var(--circleSize)-5px;
    height: auto;
    fill: #fff; }

.usernav-navbar {
  padding: 0 15px; }
  @media (max-width: 575.98px) {
    .usernav-navbar {
      padding: 0; } }

.user-menu {
  flex-grow: 1;
  display: flex; }

.usernav-wrap {
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  height: 100%; }
  .usernav-wrap li {
    list-style: none; }
    .usernav-wrap li a:hover {
      text-decoration: none; }
  .usernav-wrap .usernav {
    display: flex;
    margin-left: 10px;
    align-items: center;
    justify-content: center;
    height: 100%;
    padding: 0 10px; }
    @media (max-width: 575.98px) {
      .usernav-wrap .usernav {
        margin: 0;
        padding: 0 5px; } }
    .usernav-wrap .usernav-notification, .usernav-wrap .usernav-profile {
      padding: 0; }
      .usernav-wrap .usernav-notification .dropdown, .usernav-wrap .usernav-profile .dropdown {
        display: flex;
        position: inherit;
        padding: 10px;
        justify-content: center;
        align-items: center;
        height: 100%; }
        @media (max-width: 575.98px) {
          .usernav-wrap .usernav-notification .dropdown, .usernav-wrap .usernav-profile .dropdown {
            padding: 6px; } }
        .usernav-wrap .usernav-notification .dropdown.show, .usernav-wrap .usernav-profile .dropdown.show {
          background: #DBDBDB; }
        .usernav-wrap .usernav-notification .dropdown .dropdown-menu, .usernav-wrap .usernav-profile .dropdown .dropdown-menu {
          top: 100%;
          margin-top: 0; }

.dropdown-menu {
  border: none;
  padding: 0;
  max-width: 100%;
  left: auto;
  right: 0;
  top: 100%; }
  .notification-toggle + .dropdown-menu {
    min-width: 450px; }

.drop-items {
  margin: 0;
  padding: 0; }
  .drop-items li {
    list-style: none;
    white-space: initial;
    position: relative; }
    .drop-items li a {
      color: #000;
      display: block;
      padding: 15px 25px; }
      .drop-items li a > img {
        max-width: 16px;
        margin-right: 15px; }
      .drop-items li a:hover {
        background: #DBDBDB;
        text-decoration: none; }
  .drop-items:hover {
    background: none;
    color: inherit; }

.notification-toggle .notification-icon {
  display: block;
  width: 20px; }
  .notification-toggle .notification-icon img {
    width: 100%;
    height: 100%;
    object-fit: cover; }

.notification-toggle .notification-count {
  position: absolute;
  height: 16px;
  border-radius: 2px;
  font-size: 10px;
  padding: 2px 4px;
  transform: translateY(50%) translateX(-10px);
  background: red;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  right: 0; }
  .notification-toggle .notification-count:after {
    --stroke:6px;
    content: " ";
    position: absolute;
    top: 100%;
    border-left: var(--stroke) solid red;
    border-right: var(--stroke) solid transparent;
    border-bottom: var(--stroke) solid transparent; }

.notification-note {
  border-bottom: 1px solid #DBDBDB;
  color: #6c757d;
  display: flex;
  justify-content: space-between;
  font-size: 0.93333rem;
  padding: 15px;
  margin: 0; }

.notification-items {
  max-height: 400px;
  overflow: auto; }

.notification-items .notification-items__active {
  background: #fff; }

.notification-items li {
  border-bottom: 2px solid #fff; }
  .notification-items li a {
    background: #EFF3F6;
    display: flex;
    padding: 15px; }
    .notification-items li a:hover {
      background: #fff; }
    .notification-items li a .notification-ico {
      border: 2px solid #fff;
      display: block;
      flex: 0 0 50px;
      width: 50px;
      height: 50px;
      border-radius: 50%;
      overflow: hidden; }
      .notification-items li a .notification-ico img {
        height: 100%;
        width: 100%;
        object-fit: cover; }
    .notification-items li a .notification-detail {
      color: #212529;
      font-size: 1.06667rem;
      margin-left: 20px;
      font-weight: normal; }
      .notification-items li a .notification-detail b {
        font-weight: 600; }
      .notification-items li a .notification-detail .notification-time {
        color: #6c757d;
        display: block;
        font-size: 0.8rem;
        margin-top: 15px; }

.notification-page {
  padding: 15px 0; }
  @media (min-width: 768px) {
    .notification-page {
      padding: 60px 0; } }
  .notification-page .notification-note {
    border: none;
    padding: 15px 15px 15px 0;
    font-weight: 500; }
  .notification-page .notification-items li {
    border-bottom-width: 1px; }
    .notification-page .notification-items li .notification-items__active {
      border-bottom: 1px solid #DBDBDB; }
    .notification-page .notification-items li a .notification-time {
      margin-top: 5px; }
  .notification-page .notification-items {
    max-height: 100%;
    overflow: visible; }

.delnotify {
  cursor: pointer;
  position: absolute;
  top: 5px;
  right: 5px; }
  .delnotify svg {
    width: 12px;
    fill: #adb5bd; }

.media--report:nth-of-type(12n + 1) .media__thumbnail {
  background-color: #26A69A; }

.media--report:nth-of-type(12n + 2) .media__thumbnail {
  background-color: #29B6F6; }

.media--report:nth-of-type(12n + 3) .media__thumbnail {
  background-color: #26C6DA; }

.media--report:nth-of-type(12n + 4) .media__thumbnail {
  background-color: #EF5350; }

.media--report:nth-of-type(12n + 5) .media__thumbnail {
  background-color: #EC407A; }

.media--report:nth-of-type(12n + 6) .media__thumbnail {
  background-color: #7E57C2; }

.media--report:nth-of-type(12n + 7) .media__thumbnail {
  background-color: #5C6BC0; }

.media--report:nth-of-type(12n + 8) .media__thumbnail {
  background-color: #78909C; }

.media--report:nth-of-type(12n + 9) .media__thumbnail {
  background-color: #8D6E63; }

.media--report:nth-of-type(12n + 10) .media__thumbnail {
  background-color: #FFEE58; }

.media--report:nth-of-type(12n + 11) .media__thumbnail {
  background-color: #FFA726; }

.media--report:nth-of-type(12n + 3) .media__thumbnail {
  background-color: #26C6DA; }

.media--report .media__thumbnail {
  font-size: 0.875rem;
  font-weight: 600;
  text-transform: uppercase;
  color: white;
  --size:50px !important; }
  @media (min-width: 768px) {
    .media--report .media__thumbnail {
      --size:100px !important;
      font-size: 1.25rem; } }
  .media--report .media__thumbnail svg {
    fill: white;
    font-size: 14px; }
    @media (min-width: 768px) {
      .media--report .media__thumbnail svg {
        font-size: 40px; } }

.media--report .media__title {
  color: #212529;
  font-weight: 600; }
  .media--report .media__title a {
    color: inherit; }

.media--report.report-shared .media__header {
  flex-wrap: wrap; }
  @media (min-width: 576px) {
    .media--report.report-shared .media__header {
      flex-wrap: nowrap; } }

.report-list {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 25px 0 25px 40px;
  position: relative; }
  .report-list:not(:last-child) {
    border-bottom: 1px solid #DBDBDB; }
  .report-list .custom-option {
    position: absolute;
    top: 10px;
    left: 0; }
  .report-list__content {
    margin-right: auto; }
    @media (min-width: 576px) {
      .report-list__content {
        flex: 0 1 60%; } }
  .report-list__title {
    font-size: 16px;
    line-height: normal;
    margin-top: -4px; }
    .report-list__title label {
      font-size: inherit;
      margin: 0px;
      cursor: pointer; }
  .report-list .fancygallery {
    display: inline-flex;
    align-self: flex-start; }
    .report-list .fancygallery img {
      margin-left: 10px;
      flex: 0 0 14px; }

.swMains .anchor > li a .stepNumber {
  --size:20px;
  width: var(--size);
  height: var(--size);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 12px; }

.swMains .anchor > li a .stepDesc {
  font-size: 14px; }
  .swMains .anchor > li a .stepDesc small {
    font-size: inherit; }

.swMains .anchor > li {
  width: auto; }

.swMains .anchor > li a {
  padding-left: 6px !important;
  padding-right: 6px !important; }

.accordion {
  margin-top: 40px; }
  .accordion .card {
    border: none;
    margin-bottom: 20px; }
    .accordion .card h2 {
      background: url(https://cdn0.iconfinder.com/data/icons/entypo/91/arrow56-512.png) no-repeat calc(100% - 10px) center;
      background-size: 20px;
      cursor: pointer;
      font-size: 18px; }
      .accordion .card h2.collapsed {
        background-image: url(https://cdn0.iconfinder.com/data/icons/arrows-android-l-lollipop-icon-pack/24/expand2-256.png); }
    .accordion .card-body {
      padding-left: 0;
      padding-right: 0; }

a.cc-btn.cc-dismiss {
  background: #0ab7c5;
  border-radius: 4px; }
  a.cc-btn.cc-dismiss:hover, a.cc-btn.cc-dismiss:focus {
    background: #1399A3; }

.activities {
  padding: 0px;
  margin: 0px; }
  .activities--short-lists {
    max-height: 200px;
    overflow-y: auto; }
  .activities__item {
    display: flex;
    justify-content: space-between;
    padding-top: 15px;
    padding-bottom: 15px;
    font-size: 0.875rem;
    flex-wrap: wrap; }
    .activities__item:not(:last-child) {
      border-bottom: 1px solid #eee; }

.alert-bottom {
  background: #28a745;
  bottom: 10px;
  color: #fff !important;
  position: fixed !important;
  right: 10px;
  padding-left: 1.25rem !important; }
  .alert-bottom .close {
    opacity: 0.5 !important;
    text-shadow: none !important; }

.alert {
  z-index: 1; }

.ratio-16by9 {
  padding-top: 56.25%;
  position: relative; }
  .ratio-16by9 img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0; }

.embed-responsive img {
  max-width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover; }

.embed-responsive-1by1 {
  padding-bottom: 100%; }

.owl-carousel .owl-nav .fa {
  color: #f1f1f1; }

.payoutblock--empty {
  min-height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; }
  .payoutblock--empty .plusicon svg {
    --svgSize: 40px;
    height: var(--svgSize);
    width: var(--svgSize);
    fill: #adb5bd; }
  .payoutblock--empty p {
    color: #adb5bd;
    margin: 0.625rem 0 1.25rem 0; }
  .payoutblock--empty .btnwrap {
    margin-top: 0 !important; }

.payoutblock--methodlists .tableblock__item-title {
  margin-top: 0;
  font-size: 1.25rem;
  font-weight: 600; }

.payoutblock--methodlists .tableblock__item {
  flex-basis: 0;
  flex-grow: 1; }
  .payoutblock--methodlists .tableblock__item:first-child {
    max-width: 40%; }
  .payoutblock--methodlists .tableblock__item:last-child {
    justify-content: flex-end;
    align-items: flex-end; }

.payoutblock--methodlists .tableBlock {
  min-width: 600px; }

.payoutblock--history .tableblock__item {
  flex-basis: 0;
  flex-grow: 1;
  padding: 0.625rem; }
  .payoutblock--history .tableblock__item:first-child {
    color: #adb5bd;
    max-width: inherit;
    flex: 0 0 auto;
    font-size: 12px; }
  .payoutblock--history .tableblock__item:nth-child(2) {
    max-width: 45%; }
  .payoutblock--history .tableblock__item:nth-child(3) {
    max-width: 25%; }
  .payoutblock--history .tableblock__item:last-child {
    justify-content: flex-end;
    align-items: flex-end;
    flex: 0 0 auto; }
  .payoutblock--history .tableblock__item .fancyclick {
    cursor: pointer; }

.payoutblock--history .tableBlock {
  min-width: 600px; }

.paymentstatus[status="pending"] {
  color: #f46946; }

.paymentstatus[status="paid"] {
  color: #28a745; }

#payoutmethod {
  display: none; }

.setting-payout .radioOptions {
  margin-bottom: 2.5rem; }

.setting-payout .custom-option--single .custom-option__item {
  border: none;
  padding: 0; }

.setting-payout .custom-option--single input[type="checkbox"] ~ .custom-option__label:before {
  border-radius: 0; }

.setting-payout .btnwrap {
  margin-top: 2.5rem; }

@media (max-width: 991.98px) {
  .setting-payout .custom-option__item {
    margin-bottom: 0.625rem; } }

.filterbtns {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #DBDBDB;
  padding-bottom: 1.25rem;
  margin-bottom: 1.25rem;
  flex-wrap: wrap; }
  .filterbtns .filteroptions {
    margin-top: 1.25rem;
    display: flex;
    flex-wrap: wrap; }
    @media (min-width: 576px) {
      .filterbtns .filteroptions {
        margin-left: 1.25rem;
        margin-top: 0; } }
    .filterbtns .filteroptions__item {
      border: 1px solid #DBDBDB;
      color: #929292;
      margin: 0.3125rem;
      padding: 0.3125rem 0.9375rem;
      border-radius: 3px;
      transition: all 0.3s ease-out 0s; }
      @media (min-width: 576px) {
        .filterbtns .filteroptions__item {
          margin-bottom: 0;
          margin-top: 0; } }
      .filterbtns .filteroptions__item:hover, .filterbtns .filteroptions__item.active {
        background: #0ab7c5;
        border-color: #0ab7c5;
        color: #fff; }

.detailpopup > h3 {
  font-size: 14px;
  font-weight: 600;
  color: #495057; }

.detailpopup > p {
  font-size: 14px; }

.detailcontent {
  display: flex;
  justify-content: space-between;
  margin: 1.875rem 0;
  align-items: flex-start; }
  .detailcontent__item-title {
    font-size: 1rem;
    font-weight: 600; }
  .detailcontent__item-subtitle {
    font-size: 14px; }

.detailfoot {
  margin: 1.25rem 0;
  border-top: 1px solid #DBDBDB;
  padding-top: 1.25rem;
  display: flex;
  justify-content: space-between; }
  .detailfoot__item-subtitle {
    font-size: 12px;
    color: #6c757d;
    display: block; }
  .detailfoot__item-cont {
    font-size: 14px; }

.tableBlock--responsive {
  overflow: auto; }

.bubble-info {
  background-color: #D8F2FC;
  padding: 0.66667rem;
  font-size: 0.93333rem;
  position: relative;
  display: none; }
  @media (min-width: 768px) {
    .bubble-info {
      display: inline-block; } }
  .bubble-info[data-direction]:before {
    width: 20px;
    height: 20px;
    margin: 0 auto;
    background-color: inherit;
    content: " ";
    clip-path: polygon(0% 100%, 100% 100%, 0% 0%); }
  .bubble-info[data-direction="left"]:before {
    position: absolute;
    top: 50%;
    right: 100%;
    transform: rotate(45deg) translateY(-50%);
    transform-origin: center;
    margin-right: -5px; }

.badge {
  font-size: 1rem;
  padding: 6px 20px; }
  .badge-primary {
    background-color: #0ab7c5; }
  .badge-success {
    background-color: #03B5AA; }
  .badge-xs {
    font-size: 12px;
    padding: 3px 10px; }
  .badge-variant {
    color: #343a40; }
    .badge-variant.badge-primary {
      background-color: #d7f4ff;
      color: #0ab7c5; }
    .badge-variant.badge-warning {
      background-color: #fde7b0;
      color: #ffc107; }

.badge {
  font-size: 1rem;
  padding: 6px 20px; }
  .badge-primary {
    background-color: #0ab7c5; }
  .badge-success {
    background-color: #03B5AA; }
  .badge-xs {
    font-size: 12px;
    padding: 3px 10px; }
  .badge-variant {
    color: #343a40; }
    .badge-variant.badge-primary {
      background-color: #d7f4ff;
      color: #0ab7c5; }
    .badge-variant.badge-warning {
      background-color: #fde7b0;
      color: #ffc107; }

.search-box {
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.3);
  position: relative; }
  .search-box input[type="search"] {
    padding-right: 36px; }
    .search-box input[type="search"] + .fa {
      position: absolute;
      top: 50%;
      right: 15px;
      transform: translateY(-50%); }
  .search-box button {
    border: none;
    outline: none;
    background: none;
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    padding: 2px 10px; }

#headersearch {
  position: relative; }

.search-overlay.request-wrap {
  background: inherit;
  padding: 0px; }

.search-overlay img {
  max-width: 80%;
  height: auto;
  margin: 2em auto !important; }

@media (min-width: 576px) {
  .search-overlay img {
    display: none; }
  .search-overlay .content-block-detail {
    padding: 0px; }
  .search-overlay .content-block-heading {
    display: none !important; }
  .search-overlay.request-wrap {
    padding: 28px 0; } }

.table-price {
  width: 100%;
  font-size: 15px; }
  .table-price thead {
    border-bottom: 1px solid #DBDBDB; }
  .table-price tfoot {
    border-top: 1px solid #DBDBDB; }
  .table-price td, .table-price th {
    padding: 10px; }

.table-striped tr:nth-of-type(odd) {
  background-color: white !important; }

.table-striped tr:nth-of-type(even) {
  background-color: rgba(0, 175, 240, 0.1); }

.table-striped th, .table-striped td {
  border: 0 !important;
  padding: 1.33333em !important; }
  .table-striped th img, .table-striped td img {
    margin: 0 0.5rem;
    margin-left: 0; }
    .table-striped th img ~ img, .table-striped td img ~ img {
      margin-left: 0.5rem;
      margin-right: 0; }

.table-striped thead th {
  background-color: #f8f9fa;
  font-size: 14px; }

.table-align-left th:last-child, .table-align-left td:last-child {
  text-align: left !important; }

.table-user-activities img {
  --width:20px;
  max-width: var(--width);
  max-height: var(--width); }

@media (max-width: 767.98px) {
  .swMains .anchor {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    overflow-y: hidden;
    padding-bottom: 30px; }
    .swMains .anchor > li {
      display: flex; }
      .swMains .anchor > li a {
        display: flex;
        padding: 30px;
        align-items: flex-start;
        flex-direction: column; }
        .swMains .anchor > li a .stepNumber {
          flex: 0 0 auto;
          margin: 0; }
        .swMains .anchor > li a .stepDesc {
          padding-top: 10px;
          padding-left: 0;
          flex: 1 1 100%;
          display: block;
          width: 100%;
          display: flex;
          white-space: nowrap; } }

.intl-tel-input {
  display: block; }
  .intl-tel-input input#phone {
    margin-bottom: 1em; }
    @media (min-width: 992px) {
      .intl-tel-input input#phone {
        margin-bottom: 0; } }

.tab-menu .owl-item:not(:last-child) {
  border-right: 1px solid #ddd; }

.tab-content {
  padding: 10px; }

@media (max-width: 767.98px) {
  .nav-tabs.nav-tabs-dropdown,
  .nav-tabs-dropdown {
    border-radius: 0;
    overflow: hidden;
    position: relative;
    flex-direction: column; }
    .nav-tabs.nav-tabs-dropdown::after,
    .nav-tabs-dropdown::after {
      content: "☰";
      position: absolute;
      top: 16px;
      right: 8px;
      z-index: 2;
      pointer-events: none;
      color: white;
      padding: 2px 6px;
      border-radius: 2px; }
    .nav-tabs.nav-tabs-dropdown.open:after,
    .nav-tabs-dropdown.open:after {
      content: "✕"; }
    .nav-tabs.nav-tabs-dropdown.open a,
    .nav-tabs-dropdown.open a {
      position: relative;
      display: block; }
    .nav-tabs.nav-tabs-dropdown.open > li > a.active,
    .nav-tabs-dropdown.open > li > a.active {
      background-color: #0ab7c5; }
    .nav-tabs.nav-tabs-dropdown li,
    .nav-tabs-dropdown li {
      display: block;
      padding: 0;
      vertical-align: bottom; }
    .nav-tabs.nav-tabs-dropdown > li > a,
    .nav-tabs-dropdown > li > a {
      position: absolute;
      top: 0;
      left: 0;
      margin: 0;
      width: 100%;
      height: 100%;
      display: inline-block;
      border-color: transparent; }
      .nav-tabs.nav-tabs-dropdown > li > a:focus, .nav-tabs.nav-tabs-dropdown > li > a:hover, .nav-tabs.nav-tabs-dropdown > li > a:active,
      .nav-tabs-dropdown > li > a:focus,
      .nav-tabs-dropdown > li > a:hover,
      .nav-tabs-dropdown > li > a:active {
        border-color: transparent; }
    .nav-tabs.nav-tabs-dropdown > li > a.active,
    .nav-tabs-dropdown > li > a.active {
      display: block;
      border-color: transparent;
      position: relative;
      z-index: 1;
      background: #fff; }
      .nav-tabs.nav-tabs-dropdown > li > a.active:focus, .nav-tabs.nav-tabs-dropdown > li > a.active:hover, .nav-tabs.nav-tabs-dropdown > li > a.active:active,
      .nav-tabs-dropdown > li > a.active:focus,
      .nav-tabs-dropdown > li > a.active:hover,
      .nav-tabs-dropdown > li > a.active:active {
        border-color: transparent; } }

.nav-tabs {
  box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.1);
  border: 0 !important; }
  .nav-tabs .nav-item {
    margin: 0px; }
    .nav-tabs .nav-item:not(:last-child) {
      border-right: 1px solid #ddd; }
    .nav-tabs .nav-item.active a {
      color: white !important; }
    @media (max-width: 768px) {
      .nav-tabs .nav-item {
        order: 2; }
        .nav-tabs .nav-item.active {
          order: 1 !important; } }
  .nav-tabs .nav-link {
    padding: 20px 22px;
    border: 0;
    background: transparent;
    border-radius: 0;
    margin: 0px;
    color: #555;
    height: 100%;
    border: 0 !important;
    text-transform: capitalize; }
    .nav-tabs .nav-link.active {
      background: #0ab7c5 !important;
      color: white; }
    .nav-tabs .nav-link:hover {
      background: rgba(0, 175, 240, 0.1); }

.tab-pane {
  padding: 0 20px; }

.tab-menu-new {
  box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.1);
  display: none;
  flex-direction: column; }
  @media (min-width: 768px) {
    .tab-menu-new {
      flex-direction: row;
      display: flex; }
      .tab-menu-new.show {
        display: flex !important; } }
  .tab-menu-new .item:not(:last-child) {
    border-right: 1px solid #ddd; }
  .tab-menu-new .item a {
    padding: 20px 22px;
    display: block;
    color: #495057;
    transition: all 0.3s ease-in-out; }
    .tab-menu-new .item a:not(.active):hover {
      background: rgba(0, 175, 240, 0.1); }
    .tab-menu-new .item a.active {
      background: #0ab7c5;
      color: white;
      display: none; }
      @media (min-width: 768px) {
        .tab-menu-new .item a.active {
          display: block; } }

.tab-toggle {
  padding: 20px 22px;
  background: #0ab7c5;
  color: white;
  display: block;
  position: relative; }
  @media (min-width: 768px) {
    .tab-toggle {
      display: none; } }
  .tab-toggle:hover, .tab-toggle:focus {
    color: white;
    background: #009cd7; }
  .tab-toggle:after {
    content: "☰";
    position: absolute;
    top: 16px;
    right: 8px;
    z-index: 2;
    color: white;
    padding: 2px 6px;
    border-radius: 2px; }
    .tab-toggle[aria-expanded="true"]:after {
      content: "✕"; }

.pagination {
  display: flex;
  border: 1px solid #ccc;
  padding: 5px 10px !important;
  align-items: center;
  overflow-x: auto; }
  .pagination .page-item {
    --size: 50px;
    width: var(--size);
    border: none; }
    .pagination .page-item:last-child {
      margin-left: auto !important; }
    .pagination .page-item:first-child {
      margin-right: auto !important; }
    .pagination .page-item:first-child, .pagination .page-item:last-child {
      flex: 0 0 auto; }
      .pagination .page-item:first-child .page-link, .pagination .page-item:last-child .page-link {
        font-weight: bold; }
    .pagination .page-item:not(:first-child):not(:last-child) {
      padding: 4px !important; }
    .pagination .page-item .page-link {
      padding: 6px 10px !important;
      display: flex !important;
      height: auto !important;
      line-height: normal !important;
      align-items: center;
      justify-content: center;
      float: none !important;
      border: none;
      height: 40px !important;
      border-radius: 2px !important; }
    .pagination .page-item.disabled .page-link {
      cursor: not-allowed; }
    .pagination .page-item:hover:not(.active) .page-link {
      border-radius: 2px !important;
      background-color: #f1f1f1 !important;
      color: #777 !important; }
    .pagination .page-item:active .page-link {
      border-radius: 2px !important; }

.team {
  --minSize:200px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(var(--minSize), max-content));
  justify-content: center;
  grid-gap: 30px; }
  .team__figure {
    position: relative;
    padding-top: 100%;
    overflow: hidden;
    align-self: baseline; }
    .team--new .team__figure {
      --size: 100px;
      padding-top: var(--size);
      width: var(--size);
      position: absolute;
      top: 25px;
      transform: translateY(0px);
      transition: all 0.3s ease-in-out;
      border-radius: 0 16px 0 16px;
      box-shadow: 8px 16px 20px rgba(0, 0, 0, 0.25); }
      @media (min-width: 576px) {
        .team--new .team__figure {
          padding-top: 120%;
          width: 100%;
          position: relative;
          top: 0; } }
  .team__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: top center;
    position: absolute;
    top: 0;
    left: 0; }
  .team__meta {
    padding: 15px 10px;
    text-align: center; }
    .team--new .team__meta {
      text-align: left; }
    .team__meta__name {
      font-weight: bold; }
    .team__meta__position {
      color: #727272;
      font-size: 0.93333rem;
      margin-top: 6px; }
    .team__meta__social {
      display: flex;
      justify-content: center; }
      .team__meta__social li {
        margin: 6px; }
        .team__meta__social li a {
          font-size: 20px; }
    @media (min-width: 768px) {
      .team__meta__header {
        justify-content: space-between;
        display: flex; } }
    .team--new .team__meta__header {
      padding-left: 115px; }
      @media (min-width: 576px) {
        .team--new .team__meta__header {
          padding-left: 0; } }
    .team__meta__title {
      flex: 1 0 auto; }
    .team__meta__summery {
      border-top: 1px solid #DBDBDB;
      padding-top: 16px;
      margin-top: 22px; }
      .team__meta__summery p:last-child {
        margin-bottom: 0; }
  .team--new {
    --minSize:165px; }
    @media (min-width: 768px) {
      .team--new .social-icons {
        margin-left: auto;
        flex: 1 0 auto;
        grid-auto-flow: column;
        justify-content: end; } }
    .team--new .social-icons svg {
      font-size: 20px; }

@keyframes myfirst {
  0% {
    transform: translateY(-6px); }
  50% {
    transform: translateY(-12px); }
  100% {
    transform: translateY(0px); } }

.healthFit {
  display: flex;
  flex-wrap: wrap;
  background: rgba(0, 175, 240, 0.05); }
  .postblock__preview .healthFit {
    background: transparent;
    margin: -15px; }
  .healthFit__item {
    display: flex;
    align-items: center;
    padding: 15px; }
    .healthFit__item img {
      flex: 0 0 auto; }
    .healthFit__item.disabled {
      opacity: 0.25;
      filter: grayscale(100); }
  .healthFit__summery {
    margin-left: 10px; }
    .healthFit__summery__value {
      font-weight: bold; }

.countries {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -10px; }
  @supports (display: grid) {
    .countries {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(220px, max-content));
      grid-auto-rows: auto;
      grid-gap: 20px; } }
  .countries__list {
    display: flex;
    padding: 10px;
    flex: 0 0 auto;
    align-items: center; }
    @media (min-width: 768px) {
      .countries__list {
        flex-basis: 25%; } }
    .countries__list__link {
      color: #495057; }
    .countries__list__flag {
      width: 30px; }
    .countries__list__title {
      margin-left: 10px; }
  .countries--single {
    grid-template-columns: 1fr; }

.jumbotron:after {
  background: rgba(0, 0, 0, 0.3); }

.jumbotron--page-banner {
  --blur: 9px;
  height: 100px;
  position: relative; }
  @media (min-width: 992px) {
    .jumbotron--page-banner {
      height: 374px; } }
  .jumbotron--page-banner:after {
    display: none; }
  .jumbotron--page-banner .jumbotron__header {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 15px;
    background-color: rgba(0, 175, 240, 0.7); }
    .jumbotron--page-banner .jumbotron__header * {
      color: white; }
  .jumbotron--page-banner .jumbotron__title {
    text-transform: capitalize;
    font-size: 1.6rem;
    font-weight: 600; }
    @media (min-width: 992px) {
      .jumbotron--page-banner .jumbotron__title {
        font-size: 2.4rem; } }
  .jumbotron--page-banner .jumbotron__imgoverlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    z-index: -1;
    background-attachment: fixed; }

.jumbotron .hero-content h1 {
  font-size: 1.46667rem;
  font-weight: bold;
  line-height: normal;
  text-transform: capitalize; }
  @media (min-width: 576px) {
    .jumbotron .hero-content h1 {
      font-size: 2.8rem; } }
  .jumbotron .hero-content h1 + p {
    margin-top: 20px;
    font-size: 1.06667rem;
    line-height: normal; }
    @media (min-width: 576px) {
      .jumbotron .hero-content h1 + p {
        font-size: 1.46667rem; } }

.social-icons {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(20px, min-content));
  grid-gap: 10px; }
  .social-icons a {
    display: inline-block; }
    .social-icons a svg {
      font-size: 35px; }

.navigation.sticky {
  position: sticky;
  top: 0;
  z-index: 50; }

.navigation--secondary {
  background-color: #F5FCFF;
  border-bottom: 1px solid #DBDBDB; }
  .navigation--secondary .menu {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0px;
    overflow-x: auto;
    padding-bottom: 10px; }
    @media (min-width: 768px) {
      .navigation--secondary .menu {
        padding-bottom: 0; } }
    .navigation--secondary .menu > li {
      flex: 1 0 auto; }
      .navigation--secondary .menu > li a {
        padding: 1.33333em 0.8em;
        position: relative;
        display: block;
        text-transform: uppercase;
        line-height: 1;
        color: #495057;
        text-align: center; }
        .navigation--secondary .menu > li a:after {
          content: " ";
          position: absolute;
          right: 0;
          bottom: 0;
          left: 0;
          transform: scaleX(0);
          height: 2px;
          background-color: currentColor;
          transition: all 0.3s ease-in-out; }
        .navigation--secondary .menu > li a.active:after, .navigation--secondary .menu > li a:hover:after {
          transform: scaleX(1); }
        .navigation--secondary .menu > li a.active {
          color: #0ab7c5;
          font-weight: 600; }
        .navigation--secondary .menu > li a:hover:not(.active):after {
          opacity: 0.2; }

.heading {
  line-height: normal; }

.link-default {
  color: #495057; }
  .link-default:hover, .link-default:focus, .link-default:active {
    color: #3d4349; }

.feedback-btn {
  writing-mode: vertical-lr;
  display: inline-block;
  position: fixed;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  padding: 1.5em 0.5em;
  letter-spacing: 1.5px;
  font-size: 14px;
  line-height: normal; }

.ribbon {
  position: absolute;
  left: -5px;
  top: -8px;
  z-index: 1;
  overflow: hidden;
  width: 100px;
  height: 100px;
  text-align: right; }

.ribbon span {
  font-size: 12px;
  font-weight: bold;
  color: #FFF;
  text-transform: capitalize;
  text-align: center;
  line-height: 20px;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  width: 110px;
  display: block;
  background: #0ab7c5;
  box-shadow: 0 3px 10px -5px black;
  position: absolute;
  top: 20px;
  left: -25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center; }

.ribbon span::before {
  content: "";
  position: absolute;
  left: 0px;
  top: 100%;
  z-index: -1;
  border-left: 3px solid #009cd7;
  border-right: 3px solid transparent;
  border-bottom: 3px solid transparent;
  border-top: 3px solid #009cd7; }

.ribbon span::after {
  content: "";
  position: absolute;
  right: 0px;
  top: 100%;
  z-index: -1;
  border-left: 3px solid transparent;
  border-right: 3px solid #009cd7;
  border-bottom: 3px solid transparent;
  border-top: 3px solid #009cd7; }

.filter-wrap img {
  max-width: 18px; }

.filter-wrap > a {
  background: #0ab7c5;
  border-radius: 50%;
  color: #fff;
  width: 50px;
  height: 50px;
  z-index: 999;
  font-size: 1.2rem;
  padding: 15px 17px;
  position: fixed;
  right: 30px;
  bottom: 80px;
  display: flex;
  align-items: center;
  justify-content: center; }
  @media (min-width: 992px) {
    .filter-wrap > a {
      display: none; } }

.sidebar-mobile {
  top: 0;
  opacity: 1; }

@media (max-width: 991.98px) {
  .filter-sidebar {
    height: 100%;
    overflow: auto;
    z-index: 8010;
    background: #fff;
    width: 100%;
    padding: 15px;
    opacity: 0;
    position: fixed;
    top: -100%;
    left: 0;
    -webkit-transition: all 0.4s ease;
    transition: all 0.4s ease; } }

.filter-sidebar__header {
  margin-bottom: 25px;
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center; }
  .filter-sidebar__header #sidebar_mob_close {
    display: none;
    color: black; }
    @media (max-width: 991.98px) {
      .filter-sidebar__header #sidebar_mob_close {
        display: inline-block; } }

.filter-sidebar__title {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 0; }

.filter-sidebar .card__title {
  font-size: 16px; }

.filter-sidebar .rating {
  float: none; }

@media (min-width: 768px) {
  .filter-sidebar--clinic {
    padding: 30px 40px; } }

.upload-list {
  display: grid;
  margin: 10px 0;
  grid-template-columns: 15px 2fr minmax(100px, 0.5fr) 15px;
  grid-gap: 10px;
  align-items: center; }
  .upload-list .progress {
    margin: 0px; }

@media (min-width: 992px) {
  .cards--clinic .userMeta__title {
    --width:30vw; } }

.section-doctors .userMeta__title {
  --size: 100%;
  white-space: pre-wrap; }

.nav-tabs .nav-link {
  height: auto; }

/*=============================
=            pages            =
=============================*/
.dotted-box {
  border: 1px dashed #0ab7c5;
  padding: 2.5rem; }

.howitworks__inner {
  padding: 1.25rem; }
  @media (min-width: 768px) {
    .howitworks__inner {
      padding: 2.5rem 5rem; } }
  @media (min-width: 768px) {
    .howitworks__inner .loader-cont {
      max-width: 80%;
      margin: 0 auto; } }

.howcard {
  margin: 2.5rem 0 0; }
  .howcard .card {
    background: #FCFCFC;
    border: 1px solid #E9E9E9;
    padding: 1.25rem;
    text-align: center;
    margin: 0 0 1.25rem 0; }
    @media (min-width: 768px) {
      .howcard .card {
        min-height: 350px; } }
    @media (min-width: 992px) {
      .howcard .card {
        margin: 0; } }
    .howcard .card__image {
      align-items: center;
      display: flex;
      max-width: 150px;
      margin: 0 auto;
      height: 150px;
      justify-content: center; }
      .howcard .card__image img {
        max-width: 100%;
        height: auto; }
    .howcard .card__title {
      margin: 1.25rem 0 0.625rem;
      font-size: 1.25rem; }

.blog-page {
  padding: 0 0 20px; }
  @media (min-width: 992px) {
    .blog-page {
      padding: 0 0 60px; } }

.postcardwrap .postcard {
  display: inline-block; }
  .postcardwrap .postcard .entry-figure {
    margin-bottom: 1.25rem; }
  .postcardwrap .postcard p:last-child {
    margin-bottom: 1.25rem; }

.hero-section {
  align-items: center;
  background: url(../../assets/images/jumbotron-banner6.jpeg) no-repeat;
  background-size: cover;
  background-attachment: fixed;
  background-position: center center;
  display: flex;
  min-height: 150px;
  width: 100%;
  margin-bottom: 20px;
  position: relative; }
  .hero-section:after {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    background: linear-gradient(to right, #2193bf, rgba(255, 255, 255, 0));
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1; }
  @media (min-width: 992px) {
    .hero-section {
      min-height: 300px;
      margin-bottom: 60px; } }
  .hero-section__cont {
    max-width: 360px;
    position: relative;
    z-index: 2; }
    .hero-section__cont-title {
      color: #fff;
      font-size: 2.66667rem; }
    .hero-section__cont p {
      color: #fff; }

.blogSingle {
  column-count: inherit;
  column-gap: inherit; }
  .blogSingle .entry-figure {
    height: 250px; }
    .blogSingle .entry-figure img {
      width: 100%;
      height: 100%;
      object-fit: cover; }
  .blogSingle .author-name {
    font-size: 1.06667rem !important;
    font-weight: 600 !important; }
  .blogSingle .postcard .entry-main {
    border-bottom: none; }

.listgroup__icon {
  max-width: 12px; }

.listgroup__icon img {
  max-width: 100%; }

.chipswrap {
  margin: 0 -5px; }

.chipswrap .chips {
  background: #D9F3FD;
  display: inline-block;
  padding: 5px 10px;
  font-size: 14px;
  border-radius: 3px;
  margin: 5px; }

.visiblealways {
  display: block; }

.card-dropdown .dropdown-toggle {
  background: none;
  border: none;
  cursor: pointer;
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translate(0, -50%); }

.card-dropdown .dropdown-toggle:after {
  display: none; }

.card-dropdown .dropdown-toggle svg {
  width: 5px;
  fill: #adb5bd; }

.card-dropdown .dropdown-toggle:focus {
  outline: none; }

.card-dropdown .dropdown-menu {
  min-width: 170px; }

.card-dropdown .dropdown-menu a {
  font-weight: 400; }

.closesvg {
  fill: #212529;
  width: 15px; }

.addblogpage {
  padding: 2.5rem 0; }
  .addblogpage .sidebar .custom-option__item {
    display: block;
    margin: 0.625rem 0; }
  .addblogpage .sidebar .categorylist {
    height: 170px;
    overflow: auto; }

.setprofileimage {
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.16);
  position: relative;
  margin-bottom: 1.25rem; }
  .setprofileimage .postblock__attachment-icon {
    background: #fff;
    padding: 0 !important;
    position: absolute;
    bottom: 10px;
    left: 10px; }
    .setprofileimage .postblock__attachment-icon label {
      font-weight: 400;
      color: #000;
      padding: 10px 10px 10px 0; }
    .setprofileimage .postblock__attachment-icon i {
      color: #000;
      margin-left: 0.9375rem; }

.close_btn {
  --circleSize: 20px;
  background: rgba(0, 0, 0, 0.5);
  width: var(--circleSize);
  height: var(--circleSize);
  display: flex;
  border-radius: var(--circleSize);
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 10px; }
  .close_btn svg {
    width: 10px;
    fill: #fff; }

.setprofileimage .beforepicset {
  display: inline-block !important; }

.setprofileimage .afterpicset {
  display: none !important; }

.setprofileimage.profilepicadded .beforepicset {
  display: none !important; }

.setprofileimage.profilepicadded .afterpicset {
  display: inline-block !important; }

.setprofileimage .close_btn {
  display: none !important; }

.setprofileimage.profilepicadded .close_btn {
  display: flex !important; }

.addcategorydiv {
  display: none; }

.pageTitle {
  margin-bottom: 1.25rem; }

.filtersForm {
  display: flex;
  justify-content: space-between;
  flex-direction: column; }
  @media (min-width: 768px) {
    .filtersForm {
      flex-direction: row; } }
  .filtersForm .filters {
    display: flex;
    flex-direction: column;
    margin-right: 0; }
    @media (min-width: 768px) {
      .filtersForm .filters {
        flex-direction: row;
        margin-right: 1.25rem; } }
    @media (min-width: 992px) {
      .filtersForm .filters {
        margin-right: 0; } }
    .filtersForm .filters .form-group {
      margin-right: 0;
      margin-bottom: 0.9375rem; }
      @media (min-width: 768px) {
        .filtersForm .filters .form-group {
          margin-right: 0.9375rem;
          margin-bottom: 0; } }
    .filtersForm .filters .form-control {
      padding: 10px 20px 10px 10px;
      border-radius: 3px; }

.blogauthor {
  max-width: 75px; }

.modifybloglinks a {
  border-left: 1px solid #DBDBDB;
  margin-left: 0.625rem;
  padding-left: 0.625rem; }
  .modifybloglinks a:first-child {
    border-left: none;
    margin-left: 0;
    padding-left: 0; }

.bloglists .custom-option__item {
  display: block; }

.bloglists .actions a {
  display: inline-flex;
  margin-bottom: 0.625rem; }

.bloglists .actions svg {
  fill: #6c757d; }

.bloglists .table > thead > tr > th {
  vertical-align: top; }

.bloglists .table > thead > tr > th:first-child + th {
  width: 10%; }

.searchblog {
  margin-bottom: 1.25rem; }
  @media (min-width: 768px) {
    .searchblog {
      align-items: center;
      display: flex;
      flex-direction: row; } }
  .searchblog .form-group {
    margin: 0; }
    @media (min-width: 768px) {
      .searchblog .form-group {
        display: flex;
        flex-direction: row;
        align-items: center;
        flex-wrap: wrap; } }
    .searchblog .form-group input {
      margin-right: 0.625rem;
      width: auto; }
      @media (max-width: 991.98px) {
        .searchblog .form-group input[type="submit"] {
          display: none; } }
      @media (max-width: 991.98px) {
        .searchblog .form-group input[type="search"] {
          background-image: url(http://s2.wp.com/wp-content/themes/pub/twentythirteen/images/search-icon.png);
          background-position: 95% center;
          background-repeat: no-repeat;
          background-size: 18px auto;
          border-radius: 3px;
          cursor: pointer;
          padding-right: 35px;
          width: 100%; } }
  .searchblog form {
    margin-bottom: 20px; }
    @media (min-width: 768px) {
      .searchblog form {
        margin-bottom: 0;
        margin-right: 0; } }

@media (min-width: 768px) {
  .addblogpage-head {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between; } }

.filter-wrap > #deletesticky {
  background: #FA6541;
  box-shadow: 0px 10px 18px 0px rgba(250, 101, 65, 0.41); }

.stickybtn {
  transform: scale(0);
  transition: all 0.3s ease 0s; }
  .stickybtn.active {
    transform: scale(1); }

.--bloglistcomments .table > thead > tr > th:first-child + th {
  width: auto; }

.--bloglistcomments .blogauthor {
  max-width: inherit;
  display: flex;
  --circleSize: 40px; }
  .--bloglistcomments .blogauthor img {
    width: var(--circleSize);
    height: var(--circleSize);
    border-radius: var(--circleSize);
    margin-right: 0.9375rem; }
  .--bloglistcomments .blogauthor__info-name {
    display: flex; }
  .--bloglistcomments .blogauthor__info-email {
    display: flex;
    font-size: 12px; }

.--bloglistcomments .actions {
  display: flex;
  flex-direction: column; }
  @media (min-width: 768px) {
    .--bloglistcomments .actions {
      margin-left: 30px; } }

.blockquote.--blueilneblockquote {
  border-left: 2px solid #0ab7c5;
  align-items: center;
  justify-content: space-between;
  flex-direction: column; }
  @media (min-width: 576px) {
    .blockquote.--blueilneblockquote {
      flex-direction: row; } }
  .blockquote.--blueilneblockquote .blockquote__left {
    margin-right: 0.9375rem; }
    .blockquote.--blueilneblockquote .blockquote__left h3 {
      font-size: 1.25rem;
      font-weight: 600; }
  .blockquote.--blueilneblockquote .replyto {
    font-style: italic;
    font-size: 14px;
    color: #6c757d; }

.media .--media-time {
  margin-bottom: 0.625rem;
  display: flex;
  align-items: center; }
  .media .--media-time svg {
    fill: #495057;
    margin-right: 0.625rem; }

.media .status {
  font-weight: 500; }

.media .online {
  color: #03B5AA; }

.media .offline {
  color: #FA6541; }

.associatedcard .tableBlock__item-cont:first-child {
  max-width: initial; }
  @media (min-width: 576px) {
    .associatedcard .tableBlock__item-cont:first-child {
      max-width: 120px; } }

.associatedcard .tableBlock__item-cont:nth-child(2) {
  flex: 1 0 auto; }
  @media (min-width: 576px) {
    .associatedcard .tableBlock__item-cont:nth-child(2) {
      flex-basis: 0; } }

.associatedcard .tableBlock__item {
  align-items: center;
  position: relative; }
  .associatedcard .tableBlock__item .bordered-btn {
    border: 1px solid;
    padding: 3px 15px;
    font-size: 14px;
    border-radius: 3px;
    position: absolute;
    top: 0;
    right: 0; }
    @media (min-width: 576px) {
      .associatedcard .tableBlock__item .bordered-btn {
        position: initial; } }

.associatedcard .tableBlock__item-list {
  flex-direction: column;
  align-items: flex-start; }

.associatedcard .tableBlock__item-list h3 {
  font-size: 16px;
  margin: 0;
  font-weight: 600; }

.associatedcard .tableBlock__item-cont:nth-child(3) {
  margin-bottom: auto; }
  @media (min-width: 576px) {
    .associatedcard .tableBlock__item-cont:nth-child(3) {
      margin-bottom: 0.625rem; } }

.highcharts-root {
  width: auto;
  height: auto; }

.dashboard-new .user-media .media-body {
  padding-left: 0; }

.dashboard-new .media-heading-link {
  align-items: center;
  font-size: 14px;
  display: flex; }
  .dashboard-new .media-heading-link svg {
    fill: #0ab7c5;
    margin-right: 0.3125rem; }

.dashboard-new .page-header {
  margin-bottom: 0; }
  .dashboard-new .page-header .page-title {
    align-items: center;
    display: flex;
    justify-content: space-between;
    font-size: 1.25rem; }
  .dashboard-new .page-header a {
    align-items: center;
    display: flex;
    font-size: 1rem; }
    .dashboard-new .page-header a svg {
      fill: #0ab7c5;
      width: 14px;
      height: 14px;
      margin-right: 0.625rem; }

.setting-form__foot {
  margin-top: 2.5rem; }

.setting-form .cards__header {
  display: flex;
  justify-content: space-between;
  align-items: center; }
  .setting-form .cards__header::before, .setting-form .cards__header::after {
    display: none; }

.setting-form .cards__title {
  font-size: 14px;
  font-weight: 600; }

.border__textblock {
  border: 1px solid #DBDBDB;
  padding: 1.25rem; }

.setting-card {
  flex: 1; }
  .setting-card .card__footer {
    margin-top: 2.5rem; }

.uploaditem {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 0.9375rem; }
  .uploaditem__details {
    display: flex; }
  .uploaditem__icon {
    display: inline-flex;
    align-items: center;
    margin-right: 0.625rem; }
    .uploaditem__icon svg {
      fill: #929292; }
  .uploaditem__actions {
    display: flex;
    flex-direction: row;
    align-items: center; }
    .uploaditem__actions svg {
      width: 11px;
      height: 11px;
      fill: #495057; }
  .uploaditem__status {
    width: 150px;
    margin-right: 1.25rem; }
    .uploaditem__status .progress {
      margin: 0; }
  .uploaditem.success .uploaditem__icon svg {
    fill: #0ab7c5; }
  .uploaditem.success .uploaditem__name {
    color: #0ab7c5; }

.security-setting input {
  max-width: 350px;
  width: 100%;
  display: inline-flex; }

.security-setting .intl-tel-input {
  max-width: 350px;
  width: 100%; }

.security-setting .form-group > label {
  display: flex; }

.security-setting .form-group .input-block {
  display: flex; }
  .security-setting .form-group .input-block > a {
    text-decoration: underline;
    margin-top: 0.625rem;
    font-size: 14px; }

.security-setting .form-group > label.error {
  margin: 0; }

@media (min-width: 768px) {
  .security-setting .form-group .passnote {
    margin-left: 0.625rem; } }

.howitworks-homepage .card__thumbnail {
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center; }

.section-conditions {
  background: #FBFBFB; }

.conditions-homepage .introcontent {
  margin-bottom: 1.25rem; }
  .conditions-homepage .introcontent .--introcard {
    height: 100%; }

.conditions-homepage .card__thumbnail {
  --thumbnailsize: 75px;
  height: var(--thumbnailsize);
  width: var(--thumbnailsize);
  margin: 0 auto; }

.section-howitworks, .section-doctors, .section-intro-highlight {
  background: #fff; }

.pricing-homepage .custom-option--inline .custom-option__item {
  margin-bottom: 0.9375rem; }
  @media (min-width: 768px) {
    .pricing-homepage .custom-option--inline .custom-option__item {
      margin-bottom: 0; } }

.pricing-bottom-title {
  margin-top: 2.5rem; }

.jumbotron {
  z-index: 1; }
  .jumbotron .static-content,
  .jumbotron .hero-content {
    width: 100%; }

.section-intro-highlight .card__thumbnail {
  height: 150px;
  width: 200px;
  margin: 0 auto 20px; }
  .section-intro-highlight .card__thumbnail img {
    height: 100%; }

.home-slider.slick-initialized .single-bgs:after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.2);
  content: ""; }

.static-slide, .single-bgs {
  height: calc(100vh - 80px) !important;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%; }

.single-bgs {
  opacity: 0; }

.jumbo {
  position: relative;
  max-height: calc(100vh - 80px) !important;
  overflow: hidden; }
  .jumbo .static-content {
    position: absolute;
    z-index: 500;
    width: 100%;
    top: 0; }
    .jumbo .static-content .hero-content {
      width: 100%;
      padding: 30px 15px; }

.home-slider.slick-initialized .single-bgs {
  position: relative; }

.home-slider.slick-initialized img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: bottom;
  transform: translateY(-20%); }

.enterprisepricing {
  padding: 1.25rem 0;
  background: #fff; }
  @media (min-width: 768px) {
    .enterprisepricing {
      padding: 3.75rem 0; } }
  .enterprisepricing .inline-iconlist {
    margin-bottom: 2.5rem; }
  .enterprisepricing .btnwrap {
    text-align: center;
    margin-top: 2.5rem; }
  .enterprisepricing-howitworks {
    background: #FBFBFB; }

.inline-iconlist {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 0 -0.9375rem; }
  .inline-iconlist__title {
    align-items: center;
    font-size: 1.25rem;
    font-weight: 600;
    display: flex; }
    .inline-iconlist__title .circlecheck {
      margin-right: 0.625rem; }
  .inline-iconlist li {
    max-width: 100%;
    padding: 0.9375rem;
    flex: 1 0 100%; }
    @media (min-width: 576px) {
      .inline-iconlist li {
        max-width: 50%; } }
    @media (min-width: 768px) {
      .inline-iconlist li {
        max-width: 25%; } }

.clinicsetup-head {
  margin-bottom: 2.5rem;
  margin-top: 2.5rem; }

.clinicsetup-body {
  margin-bottom: 2.5rem; }

.clinicsetup .templatelists__block {
  background: #fff;
  box-shadow: none;
  border: 1px solid #DBDBDB; }

.clinicsetup .templatelists li {
  margin: 0.625rem 0; }
  .clinicsetup .templatelists li label {
    font-weight: 600; }

.clinicsetup .templatelists__actions {
  display: flex; }
  .clinicsetup .templatelists__actions li {
    border-top: 1px solid #DBDBDB; }
    .clinicsetup .templatelists__actions li label, .clinicsetup .templatelists__actions li a {
      display: flex;
      justify-content: center;
      margin: 0;
      font-weight: 400; }

.cliniclogo__select {
  margin: 1.25rem 0 0.625rem;
  display: block; }

.cliniclogo__img {
  border: 1px dotted #DBDBDB;
  display: flex;
  justify-content: center;
  padding: 0.625rem;
  max-width: 300px;
  position: relative; }
  .cliniclogo__img span {
    display: block; }
  .cliniclogo__img img {
    display: none; }
  .cliniclogo__img #removeImg {
    color: #fff;
    display: none;
    position: absolute;
    top: -10px;
    right: -10px; }

.cliniclogo.clinicimage .cliniclogo__img {
  display: inline-flex; }
  .cliniclogo.clinicimage .cliniclogo__img span {
    display: none; }
  .cliniclogo.clinicimage .cliniclogo__img img {
    display: block; }
  .cliniclogo.clinicimage .cliniclogo__img #removeImg {
    display: flex; }

.clinicslider__img {
  border: none;
  padding: 0;
  max-width: inherit; }

#accordionSlider .card {
  border: 1px solid #DBDBDB; }
  #accordionSlider .card-head h2 {
    padding: 0.9375rem; }
  #accordionSlider .card-body {
    border-top: 1px solid #DBDBDB;
    padding: 1.25rem; }

#accordionSlider .clinicsetup-head {
  margin-top: 0; }

#accordionSlider .clinicsetup-body {
  margin-bottom: 0; }
  #accordionSlider .clinicsetup-body textarea {
    height: 100px;
    resize: none; }

.media--clinic-top .media-thumbnail {
  height: 60px;
  width: 100%;
  border-radius: 0;
  margin-bottom: 0.9375rem; }

.listconditions {
  background: #fff; }
  .listconditions__figure {
    max-height: 400px;
    overflow: hidden; }
    .listconditions__figure iframe, .listconditions__figure video {
      width: 100%; }
  .listconditions .searchbar {
    margin: 3.75rem 0.9375rem; }
    .listconditions .searchbar form {
      max-width: 500px;
      margin: 0 auto; }
  .listconditions .sidebar__title {
    border: none;
    border: none;
    margin: 0 0 1.25rem;
    padding: 0;
    font-weight: 600;
    font-size: 1.25rem; }
  .listconditions-body figure {
    margin: 1.25rem 0; }
    .listconditions-body figure img {
      max-width: 100%; }

.arrowlists {
  margin: 0;
  height: 980px;
  overflow: auto; }
  .arrowlists__item {
    margin-bottom: 0.625rem; }
    .arrowlists__item a {
      color: #495057;
      background: url("../images/double-arrow-right.png") no-repeat 0px center;
      background-size: 10px;
      padding-left: 1.25rem;
      transition: all 0.3s ease 0s; }
      .arrowlists__item a:hover {
        background-position: 5px center; }

.borderedblock {
  border: 1px solid #DBDBDB;
  padding: 0.625rem;
  margin-bottom: 0.9375rem; }

.medicalcondition .media-list .media-object {
  --circleSize: 60px;
  box-shadow: none;
  height: var(--circleSize);
  width: var(--circleSize);
  border-radius: var(--circleSize);
  margin: 0; }

.medicalcondition .media-list .media-left {
  padding-right: 0.625rem; }

.medicalcondition .media-list .media-heading {
  font-weight: 600;
  font-size: 1rem; }

.medicalcondition .media-list .position {
  color: #adb5bd;
  font-size: 12px;
  font-weight: 600; }

.arrow-lists li {
  background: url("../images/circle-arrow-right.png") no-repeat left 4px;
  background-size: 16px;
  padding-left: 1.875rem;
  margin-top: 0.9375rem; }

.pricingtable--fullwidth {
  max-width: inherit;
  padding: 0;
  margin-bottom: 1.875rem; }
  .pricingtable--fullwidth ul {
    margin-bottom: 0.625rem; }
  .pricingtable--fullwidth li:first-child {
    padding-top: 0; }
  .pricingtable--fullwidth li:last-child {
    border-bottom: none;
    padding-bottom: 0; }

.custom-option--bgcolors {
  margin-bottom: 1.25rem;
  margin-top: 1.25rem; }
  @media (min-width: 1200px) {
    .custom-option--bgcolors {
      flex-wrap: nowrap !important; }
      .custom-option--bgcolors > [class^="col-"] {
        max-width: 1000px;
        flex: 1 1 30%;
        white-space: nowrap; } }
  .custom-option--bgcolors .custom-option__item {
    align-items: center;
    background-color: #0ab7c5;
    border: none;
    color: #fff;
    display: flex;
    justify-content: space-between;
    margin: 0 0 10px;
    flex-wrap: wrap; }
    .custom-option--bgcolors .custom-option__item.plan-basic {
      --color: #0ab7c5;
      background-color: #0ab7c5; }
    .custom-option--bgcolors .custom-option__item.plan-pro {
      --color: #03B5AA;
      background-color: #60DD4D; }
    .custom-option--bgcolors .custom-option__item.plan-premium {
      --color: #F75358;
      background-color: #F23994; }
    .custom-option--bgcolors .custom-option__item.plan-enterprise {
      --color: #6A47DA;
      background-color: #6A47DA; }
    @media (min-width: 1200px) {
      .custom-option--bgcolors .custom-option__item {
        margin: 0; } }
  .custom-option--bgcolors .custom-option__label::before {
    background: #fff; }
  .custom-option--bgcolors .custom-option__sublabel {
    font-weight: 600;
    margin-left: 10px;
    font-size: 16px;
    flex: 1 1 100%;
    margin-left: 30px; }
  .custom-option--bgcolors input[type="radio"]:checked ~ .custom-option__label:before,
  .custom-option--bgcolors input[type="checkbox"]:checked ~ .custom-option__label:before {
    background: #fff; }
  .custom-option--bgcolors input[type="radio"]:checked ~ .custom-option__label:after,
  .custom-option--bgcolors input[type="checkbox"]:checked ~ .custom-option__label:after {
    color: var(--color); }

#addressblock {
  display: none;
  margin-top: 1.25rem; }
  #addressblock > h3 {
    font-size: 1.25rem; }

.chooseplanpage #newCCForm {
  margin-bottom: 1.875rem; }

.chooseplanpage .cards__title {
  font-weight: 600;
  margin-bottom: 2.5rem; }

.block-pricedetails {
  background: #F8FDFF;
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 1.875rem; }
  .block-pricedetails__head {
    font-weight: 600;
    font-size: 1.25rem;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #DBDBDB;
    padding-bottom: 1.25rem; }
    .block-pricedetails__head a {
      font-weight: 400;
      font-size: 1rem; }

.flexlisting {
  border-top: 1px solid #DBDBDB; }
  .flexlisting li {
    display: flex;
    justify-content: space-between;
    margin: 0.625rem 0; }
    .flexlisting li:last-child {
      border-top: 1px solid #DBDBDB;
      padding-top: 1.25rem;
      font-weight: 600; }

.plan-details {
  display: none; }
  .plan-details.active {
    display: block; }

.plan-details .show-form[aria-expanded="true"] {
  display: none; }

.plan-details .show-form[aria-expanded="false"] {
  display: inline-block; }

.cards__column {
  flex-direction: column; }
  @media (min-width: 768px) {
    .cards__column {
      flex-direction: row; } }

.category-max-height {
  max-height: 300px;
  overflow-y: auto; }
  @media (min-width: 768px) {
    .category-max-height {
      max-height: 600px; } }

.custom-option-block--category .custom-option--block {
  display: block; }

.custom-option-block--category .custom-option__item {
  position: relative;
  cursor: pointer; }
  .custom-option-block--category .custom-option__item .card:before, .custom-option-block--category .custom-option__item .card:after {
    content: " ";
    position: absolute;
    width: 20px;
    height: 20px;
    left: 15px;
    border-radius: 50%;
    top: 15px; }
  .custom-option-block--category .custom-option__item .card:before {
    background-color: #EEEEEE;
    transition: all 0.3s; }
  .custom-option-block--category .custom-option__item .card:after {
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
    content: "\f00c";
    font-family: fontawesome;
    font-size: 10px;
    opacity: 0;
    transition: all 0.3s; }
  .custom-option-block--category .custom-option__item input:checked ~ .card:before {
    background-color: var(--activeColor); }
  .custom-option-block--category .custom-option__item input:checked ~ .card:after {
    color: white;
    opacity: 1; }
  .custom-option-block--category .custom-option__item input:disabled ~ .card {
    opacity: 0.4;
    cursor: not-allowed; }
    .custom-option-block--category .custom-option__item input:disabled ~ .card:before {
      background-color: #EEEEEE; }
    .custom-option-block--category .custom-option__item input:disabled ~ .card:after {
      display: none; }

.wrap-box input:disabled ~ * {
  opacity: 0.2; }

.wrap-box input:disabled ~ .overlay-content {
  min-height: 0px !important; }
  .wrap-box input:disabled ~ .overlay-content label {
    cursor: default; }
  .wrap-box input:disabled ~ .overlay-content h2:after {
    display: none; }

.report-list-wrapper {
  max-height: 400px;
  overflow-x: hidden;
  overflow-y: auto; }

.media--report-checkbox {
  padding-left: 30px;
  position: relative; }
  .media--report-checkbox .custom-option {
    position: absolute;
    top: 20px;
    left: 0; }

.tab-content {
  padding: 30px 15px; }
  @media (min-width: 768px) {
    .tab-content {
      padding: 30px 0px; } }

.clinicfilters {
  display: flex;
  flex-direction: column; }
  @media (min-width: 768px) {
    .clinicfilters {
      flex-direction: row;
      margin: 0 -15px; } }
  .clinicfilters .form-group {
    flex: 1;
    margin: 15px; }
    @media (min-width: 768px) {
      .clinicfilters .form-group {
        margin: 0 15px; } }
  .clinicfilters .btn {
    flex: 0 0 auto;
    margin: 15px; }
    @media (min-width: 768px) {
      .clinicfilters .btn {
        margin: 0 15px; } }

.cards--clinic .appointment-types {
  display: block; }

@media (max-width: 991.98px) {
  .cards--clinic .summery {
    display: none; } }

.countries-wrap:not(:last-child) {
  margin-bottom: 3.33333em; }

.custom-dropmenu {
  background: white;
  padding: 15px;
  border: 1px solid #eee;
  max-height: 288px;
  overflow-y: auto;
  overflow-x: hidden; }

.icon-lists {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(80px, 1fr));
  grid-auto-rows: 80px;
  grid-gap: 10px;
  place-items: center;
  counter-reset: icons; }
  .icon-lists > svg {
    font-size: 22px;
    background: #eee;
    width: 100%;
    height: 100%;
    padding: 20px;
    display: block; }

.static-page {
  padding: 3.33333em 0; }
  .static-page h1, .static-page h2, .static-page h3, .static-page h4, .static-page h5, .static-page h6 {
    font-weight: 600;
    margin-bottom: 1.33333rem; }

.press__footer {
  border-top: 1px solid #DBDBDB;
  padding-top: 20px;
  display: flex;
  justify-content: space-between;
  flex-direction: column; }
  @media (min-width: 576px) {
    .press__footer {
      flex-direction: row;
      align-items: center; } }

.press__logo {
  max-height: 60px;
  max-width: 200px; }
  @media (max-width: 767.98px) {
    .press__logo {
      margin-bottom: 10px; } }
  .press__logo__img {
    max-width: 100%;
    max-height: 100%;
    object-fit: scale-down;
    object-position: left center;
    height: 100%; }

.contact-illustration-wrap {
  position: static; }
  .contact-illustration-wrap img {
    max-width: 100%;
    position: absolute;
    right: 0;
    transform: translateX(40%); }

.contact-section:not(:first-child) {
  margin-top: 4em;
  padding-top: 4em;
  border-top: 1px solid #DBDBDB; }

.svg-filter {
  -webkit-filter: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg'%3E%3Cfilter id='a' x='0' y='0' width='1' height='1' color-interpolation-filters='sRGB'%3E%3CfeGaussianBlur stdDeviation='10' result='b'/%3E%3CfeMorphology operator='dilate' radius='10'/%3E %3CfeMerge%3E%3CfeMergeNode/%3E%3CfeMergeNode in='b'/%3E%3C/feMerge%3E%3C/filter%3E %3C/svg%3E#a");
  filter: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg'%3E%3Cfilter id='a' x='0' y='0' width='1' height='1' color-interpolation-filters='sRGB'%3E%3CfeGaussianBlur stdDeviation='10' result='b'/%3E%3CfeMorphology operator='dilate' radius='10'/%3E %3CfeMerge%3E%3CfeMergeNode/%3E%3CfeMergeNode in='b'/%3E%3C/feMerge%3E%3C/filter%3E %3C/svg%3E#a"); }

/*==============================
=            vendor            =
==============================*/
.ui-slider {
  --handleSize:20px;
  height: 8px;
  border-radius: 3px; }
  .ui-slider-handle {
    border-radius: 50%;
    background-color: #0ab7c5 !important;
    border: none !important;
    width: var(--handleSize) !important;
    height: var(--handleSize) !important;
    top: 50% !important;
    transform: translateY(-50%) !important; }
    .ui-slider-handle.ui-state-focus {
      background-color: #0077a4 !important; }
  .ui-slider-range {
    background-color: #0ab7c5 !important; }
  .ui-slider-value {
    margin-top: 15px;
    display: flex;
    justify-content: space-between;
    font-size: 15px; }

.ui-autocomplete {
  max-height: 300px;
  overflow-x: hidden;
  overflow-y: auto; }

.ui-datepicker .ui-corner-all:before {
  color: #495057; }

.ui-datepicker .ui-state-highlight {
  background-color: #0ab7c5;
  color: white;
  border: none; }

.ui-datepicker .ui-state-active {
  background-color: #495057;
  color: white;
  border: none; }

.ui-datepicker .ui-widget-header {
  background: #e9e9e9; }

.ui-datepicker .ui-sate-hover {
  font-weight: bold; }

.ui-datepicker .ui-datepicker-prev:hover {
  border: none;
  top: 2px;
  left: 2px;
  background: transparent; }

.ui-datepicker .ui-datepicker-next:hover {
  border: none;
  top: 2px;
  right: 2px;
  background: transparent; }

.ui-autocomplete {
  z-index: 8011;
  padding-top: 10px;
  padding-bottom: 10px; }
  .ui-autocomplete .ui-menu-item {
    padding: 6px 10px; }
    .ui-autocomplete .ui-menu-item a {
      color: #495057;
      cursor: pointer;
      padding: 4px 8px;
      display: block; }
      .ui-autocomplete .ui-menu-item a.ui-state-focus, .ui-autocomplete .ui-menu-item a.ui-state-active {
        background-color: #d7f4ff;
        border-color: #0ab7c5; }

.daterangepicker .ranges li {
  list-style: none; }

.fancypop {
  padding: 6px; }
  .fancypop > .closebtn {
    position: absolute;
    top: 0;
    right: 0; }
  .fancypop-title {
    font-size: 1.06667rem !important;
    font-weight: 600 !important; }
  .fancypop .content-block-heading {
    margin: 0 15px;
    padding: 18px 0 !important;
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px; }
    .fancypop .content-block-heading:before, .fancypop .content-block-heading:after {
      display: none; }
    .fancypop .content-block-heading h1, .fancypop .content-block-heading h2, .fancypop .content-block-heading h3, .fancypop .content-block-heading h4, .fancypop .content-block-heading h5, .fancypop .content-block-heading h6 {
      font-size: 1.06667rem !important;
      font-weight: 600 !important;
      line-height: 26px; }
    .fancypop .content-block-heading .closebtn {
      position: static;
      flex: 0 0 auto;
      margin-top: 2px;
      margin-left: 15px;
      padding: 0px !important;
      font-size: inherit; }
  .fancypop--flex {
    --height:100%;
    height: var(--height); }
    .fancypop--flex__container {
      height: var(--height);
      display: flex;
      flex-direction: column; }
    @media (max-width: 767.98px) {
      .fancypop--flex__fullScreen {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%; } }
    .fancypop--flex .content-block-heading, .fancypop--flex .content-block-footer {
      flex: 0 0 auto; }
    .fancypop--flex .content-block-detail {
      flex: 1 1 auto;
      overflow-y: auto; }
    .fancypop--flex .content-block-footer {
      margin: 0 15px;
      padding: 0 0 18px 0 !important; }
    .fancypop--flex .content-block-heading {
      margin-bottom: 0; }
  .fancypop__status {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center; }
    .fancypop__status__icon .fa {
      font-size: 30px; }
    .fancypop__status__title {
      font-weight: 600;
      margin-top: 20px; }

.confirm-dialog__title {
  font-weight: 600; }

.fancybox-wrap {
  transition: all 0.3s ease-in-out; }

.fancybox-overlay {
  background: rgba(0, 0, 0, 0.6) !important; }
