.testimonial-block {
  // display: table;
  // @media(min-width: @screen-tablet) {
  //   width: 80%;
  // }
  margin:0 auto;
  position: relative;
  .client-thumb {
    width: 200px;
    height: 200px;
    border-radius: 50%;
    overflow: hidden;
    margin: 0 auto;
    vertical-align: middle;
    // @media(min-width: @screen-tablet) {
    //   display: table-cell;
    // }
    img {
      // .img-responsive();
      // .img-circle();
      height: 100%;
      object-fit: cover;
    }
    &::before {
      @include absolute(left 0 top 0 right 400px);
      background: url('../images/quote.png') no-repeat;
      content: "";
      display: block;
      width: 120px;
      height: 90px;
      margin: auto;
    }
  }
  .client-content {
    // @media(min-width: @screen-tablet) {
    //   display: table-cell;
    //   text-align: left;
    //   padding-left: 50px;
    //   margin-top: 0px;
    // }
    vertical-align:middle;
    margin-top:20px;
    color:white;
    text-align:center;
    h3,
    p {
      color: inherit;
    }
    h3 {
      font-weight: 600;
    }
  }
}