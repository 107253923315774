.blog-page {
  padding: 0 0 20px;

  @include media-breakpoint-up(lg) {
    padding: 0 0 60px;
  }
}

.postcardwrap {
  // @include media-breakpoint-up(lg) {
  //   column-count: 2;
  //   column-gap: 30px;
  // }

  .postcard {
    // width: 100%;
    display: inline-block;
    .entry-figure {
      margin-bottom: $spacer;
    }
     p {
       &:last-child {
         margin-bottom: $spacer;
       }
     }
  }
}

 

.hero-section {
  align-items: center;
  background: url(../../assets/images/jumbotron-banner6.jpeg) no-repeat;
  background-size: cover;
  background-attachment: fixed;
  background-position: center center;
  display: flex;
  min-height: 150px;
  width: 100%;
  margin-bottom: 20px;
  position: relative;

  &:after {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    background: linear-gradient(to right, rgba(33, 147, 191, 1), rgba(255, 255, 255, 0));
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
  }

  @include media-breakpoint-up(lg) {
    min-height: 300px;
    margin-bottom: 60px;
  }

  &__cont {
    max-width: 360px;
    position: relative;
    z-index: 2;

    &-title {
      color: $white;
      font-size: rem(40);
    }

    p {
      color: $white;
    }
  }
}

.blogSingle {
  column-count: inherit;
  column-gap: inherit;

  .entry-figure {
    height: 250px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .author-name {
    font-size: rem(16) !important;
    font-weight: 600 !important;
  }

  .postcard .entry-main {
    border-bottom: none;
  }
}


// blog custom css
.listgroup__icon {
  max-width: 12px;
}

.listgroup__icon img {
  max-width: 100%;
}


.chipswrap {
  margin: 0 -5px;
}

.chipswrap .chips {
  background: #D9F3FD;
  display: inline-block;
  padding: 5px 10px;
  font-size: 14px;
  border-radius: 3px;
  margin: 5px;
}

.visiblealways {
  display: block;
}

.card-dropdown .dropdown-toggle {
  background: none;
  border: none;
  cursor: pointer;
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translate(0, -50%)
}

.card-dropdown .dropdown-toggle:after {
  display: none;
}

.card-dropdown .dropdown-toggle svg {
  width: 5px;
  fill: #adb5bd;
}

.card-dropdown .dropdown-toggle:focus {
  outline: none;
}

.card-dropdown .dropdown-menu {
  min-width: 170px;
}

.card-dropdown .dropdown-menu a {
  font-weight: 400;
}

.closesvg {
  fill: #212529;
  width: 15px;
}