.search-box {
//    filter:drop-shadow(2px 2px 2px black);
box-shadow: 2px 2px 8px rgba(black, 0.3);
position: relative;

input[type="search"]{
    padding-right: 36px;

    + .fa {
        @include absolute(top 50% right 15px);
        transform:translateY(-50%);
    }

}
// display:flex;
button {
    border:none;
    outline:none;
    background: none;
    @include absolute(top 0 right 0);
    height: 100%;
    padding:2px 10px;
}
}

#headersearch {
    position: relative;
}

.search-overlay {
  

    &.request-wrap {
        background:inherit;
        padding:0px;
     
    }
    img {
        max-width: 80%;
        height: auto;
        margin:2em auto !important;
    }
    @include media-breakpoint-up(sm){
        img {
            display: none;
        }
        .content-block-detail{
            padding:0px;
        }
        .content-block-heading {
            display: none !important;
        }
        &.request-wrap {
            padding: 28px 0;
        }
    }

}