%data-background {
    background-position: center;
    background-size:cover;
    background-repeat: no-repeat;
    overflow: hidden;
    z-index: 1;
    position: relative;
    &:after {
        @include absolute(top 0 left 0);
        width: 100%;
        height: 100%;
        @include opacity(0.7);
        content:" ";
        z-index: -1;
        background:$body-color;

    }
}

%imgobjectcover { // cover image with position center
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  %longtextellipsis { //For the long lines of text break the line with ... at the end
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  %whitetextshadow {
      text-shadow: 0 1px 7px $gray-700;
  }
  %dropshadowblue {
      box-shadow: 0px 10px 18px 0px rgba(0, 176, 239, 0.41);
  }

 %flexcenterall {
     display:flex;
     align-items: center;
     justify-content: center;
 }

 %selectbox {
    appearance: none;
    padding-right: 30px !important;
    // background-image:
    // linear-gradient(45deg, transparent 50%, $gray-700 50%),
    // linear-gradient(135deg, $gray-700 50%, transparent 50%) !important;
    background-image: url(../images/caret-down.svg) !important;
    
  background-position:
    calc(100% - 14px) center,
    calc(100% - 10px) center !important;
// background-position: 94% 50%, 96% 50%;
//   background-size:
//     4px 4px,
//     4px 4px !important;
    background-size:   8px !important;
  background-repeat: no-repeat !important;
  &::-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #000;
  }
 }