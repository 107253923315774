.usernav-navbar {
	padding: 0 15px;
	@include media-breakpoint-down(xs) {
		padding: 0;
	}
}
.user-menu {
    flex-grow: 1;
    display: flex;
}
.usernav-wrap {
	display: flex;
	flex-direction: row;
	align-items: center;
	position: relative;
	height: 100%;
	li {
		list-style: none;
		a {
			&:hover {
				text-decoration: none;
			}
		}
	}
	.usernav {
		display: flex;
		margin-left: 10px;
		align-items: center;
		justify-content: center;
		height: 100%;
		padding: 0 10px;
		@include media-breakpoint-down(xs) {
			margin: 0;
			padding: 0 5px;
		}
		&-notification, &-profile {
			padding: 0;
			.dropdown {
				display: flex;
				position: inherit;
				padding: 10px;
				justify-content: center;
				align-items: center;
				height: 100%;
				@include media-breakpoint-down(xs) {
					padding: 6px;
				}
				&.show {
					background : $border-color;
				}
				.dropdown-menu {
					top: 100%;
					margin-top: 0;
				}
			}
		}
	}
}

// usernav profile starts

.dropdown-menu {
	border: none;
	padding: 0;
	
	max-width: 100%;
	left: auto;
	right: 0;
	top: 100%;
	.notification-toggle + & {
		min-width: 450px; 
	}
	// @extend %dropshadowDark;
}

.drop-items {
	margin: 0;
	padding: 0;
	li {
		list-style: none;
		white-space: initial;
		position: relative;
		a {
			color: $black;
			display: block;
			padding: 15px 25px;
			 > img {
				max-width: 16px;
				margin-right: 15px;
			}
			&:hover {
				background: $border-color;
				text-decoration: none;
			}
		}
	}
	&:hover {
		background: none;
		color: inherit;
	}
}
// usernav profile ends 
// usernav notification styling
.notification-toggle {
	.notification-icon {
		display: block;
		width: 20px;
		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}
	.notification-count {
 
	  
	  position: absolute;
	  height: 16px ;
	  border-radius: 2px ;
	  font-size: 10px ;
	  padding: 2px 4px ;
	  transform: translateY(50%) translateX(-10px);
	  background: red;
	  color:white;
	  display:flex;
	  align-items: center;
	  justify-content: center;
	  top:0;
	  right:0;
	  &:after {
		  --stroke:6px;
		content:" ";
		position: absolute;
		top:100%;
		  border-left: var(--stroke) solid red;
		  border-right: var(--stroke) solid transparent;
		  border-bottom: var(--stroke) solid transparent;
		
	}

	}
}
.notification-note {
	border-bottom: 1px solid $border-color;
	color: $gray-600;
	display: flex;
	justify-content: space-between;
	font-size: rem(14);
	padding: 15px;
	margin: 0;
}
.notification-items {
    max-height: 400px;
    overflow: auto;
    
}
.notification-items { 
	$P:&;
	#{$P}__active {
		background: $white;
	}
	li {
	border-bottom: 2px solid $white;
	a{
		background: #EFF3F6;
		display: flex;
		padding: 15px;
		&:hover {
			background: $white;
		}
		.notification-ico {
			// @extend %dropshadowlightgray;
			border: 2px solid $white;
		    display: block;
		    flex: 0 0 50px;
		    width: 50px;
		    height: 50px;
		    border-radius: 50%;
		    overflow: hidden;
		    img {
		    	height: 100%;
		    	width: 100%;
		    	object-fit: cover;
		    }
		}
		.notification-detail {
			color: $gray-900;
			font-size: rem(16);
			margin-left: 20px;
			font-weight: normal;
			b {
				font-weight: 600;
			}
			.notification-time {
				color: $gray-600;
				display: block;
				font-size: rem(12);
				margin-top: 15px;
			}
		}
		
	}
}
}

// Notification page styling
.notification-page {
	padding: 15px 0;
	@include media-breakpoint-up(md) {
		padding: 60px 0;
	}
	.notification-note {
		border: none;
		padding: 15px 15px 15px 0;
		font-weight: 500;
	}
	.notification-items li {
		border-bottom-width: 1px;
		.notification-items__active {
			border-bottom: 1px solid $border-color;
		}
		a .notification-time {
			margin-top: 5px;
		}
	}
	.notification-items {
		max-height:100%;
		overflow:visible;
	}
}
.delnotify {
	cursor: pointer;
	@include absolute(top 5px right 5px);
	svg {
		width: 12px;
		fill: $gray-500;
	}
}