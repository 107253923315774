// tableBlock for listing with border
.tableBlock {
  &__item {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: $spacer;
    padding-bottom: $spacer;
    border-bottom: 1px solid $border-color;
    &-title {
      margin-bottom: $spacer*.5;
      font-weight: 600;
    }
    &-subtitle{
      font-size: 14px;
      margin-bottom: $spacer*.5;
      flex:1 1 100%;
    }
    &-note {
      font-size: 14px;
      color: $gray-600;
    }
    &-list {
      display: flex;
      align-items: baseline;
      margin:5px 0;
      svg {
        
        margin-right: $spacer*.5;
        fill: $gray-600;
        flex: 0 0 14px;
      }
    }
    &-action {
      a {
        margin-left: $spacer*.5;
      }
      svg {
        fill: $gray-800;
      }
    }
    &-cont {
      flex-basis: 0;
      flex-grow: 1;
      margin-bottom: $spacer*.5;
      &:first-child {
        display: flex;
        flex-direction: column;
        max-width: 100%;
        flex-basis: 100%;

        @include media-breakpoint-up(md) {
          max-width: 50%;
          flex-basis: 50%;
        }
      }
      &:last-child {
        display: flex;
        justify-content: flex-end;
      }
      &:nth-child(2) {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
      }
    }
    &.--tableBlock__bottomitem {
      > .tableBlock__item-subtitle {
        margin-top: $spacer*.5;
        margin-bottom: 0;
      }
    }
  }
}

// tableblock for design like as table 
.tableblock {
  align-items: center;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  &__item {
    display: flex;
    flex-direction: column;
    &-title {
      align-items: center;
      display: flex;
      @include font-size($lead-font-size);
      margin-top: $spacer*.5;
      .btn {
        margin-left: $spacer*.5;
        padding: ($spacer*.25) ($spacer*.75);
      }
    }
    &-row {
      align-items: center;
      flex-direction: row;
    }
  }
}

.listingactions {
  &__item {
    --itemColor: #495057;
    --itemhoverColor: #0ab7c5;
    margin-left: $spacer*.5;
    color: var(--itemColor);
    display: inline-flex;
    cursor: pointer;
    transition: all 0.3s ease 0s;
    svg {
      fill: var(--itemColor);
    }
    &:hover {
      color: var(--itemhoverColor);
      svg {
        fill: var(--itemhoverColor)
      }
    }
  }
}