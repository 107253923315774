.input-datepicker {

  border: 1px solid #ccc;
  display: flex;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
  cursor: pointer;


  span.form-control {
    border: none;
    @include font-size(14px);
    // display: flex !important;
    align-items: center;
    // padding:10px 15px 10px 10px !important;
    @include truncate();
  }
}

.select-box {
  position: relative;

  @include media-breakpoint-down(sm) {
    @at-root .search-listing__header & {
      width: 100%;
    }
  }

  @at-root .form-inline & {
    display: inline-block;

  }



  select {
    appearance: none;
    padding-right: 60px;
  }

  &:after {
    @include absolute(right 0 top 0);
    content: "";
    font-family: fontawesome;
    // height: 100%;
    display: flex;
    align-items: center;
    padding: 10px;
    pointer-events: none;
    z-index: 3;
  
  }
}

// custom select box using just css starts
select {
 @extend %selectbox;

@at-root .select-box {
  &:after {

    display: none;
  }
  input[type="text"]{
    @extend %selectbox;
    + .fa {
      display: none;
    }
  }
}
 
 .custom-select & {
  background-color: white;
    + .select-icons {
     display: none;
   }
 }

 .input-icon-box & {
   ~.fa {
     display: none;
   }
 }

  @at-root .input-icon-box {
    input[type="text"]{
       @extend %selectbox;
      ~ .fa {
        display:none;
      }
    }
 }

 @at-root .input-datepicker  {
    padding-right: 0;
   .form-control {
    @extend %selectbox;
    // background-position: calc(100% - 15px) center, calc(100% - 10px) center;
    + .fa {
      display: none;
    }
   }
 }

 @at-root .custom-select {
  >.custom-select__icon {
    z-index: 1;
    ~ .custom-select__arrow{
      display: none;
  }
  }
}

@at-root #changelanguage {
  .goog-te-combo {
    background-color: white !important;
}
.goog-te-gadget{
        border:1px solid #ced4da;
    }
}

}

 // custom select box using just css ends

 
.upload-group .btnwrap {
  .postblock__attachment-icon {
    margin: 0;
    display: inline-flex;
    padding: 10px 20px;
    cursor: pointer;

    input[type="file"] {
      position: absolute;
      left: -9999px;
      opacity: 0;
      z-index: -1;
    }

    label {
      cursor: pointer;
    }
  }
}


.input-group {
  width: 100%;

  .input-group-addon {
    position: absolute;
    z-index: 100;
    height: 44px;
    padding: 5px;
    width: 44px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    left: 1px;
  }

  .form-control {
    padding-left: 50px;
  }

  &.addon-reverese {
    .input-group-addon {
      left: auto;
      right: 1px;
    }

    .form-control {
      padding-left: 20px;
    }
  }

  &--custom {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-align: stretch;
    align-items: stretch;
    width: 100%;

    input.form-control {
      position: relative;
      -ms-flex: 1 1 auto;
      flex: 1 1 auto;
      width: 1%;
      margin-bottom: 0;
      margin-right: -1px;
      padding: 10px 20px;
    }

    select {
      padding: 0 0 0 15px !important;
      height: auto;
      border: none;
      background: none;
      box-shadow: none;
      height: 100%;
      &.input-sm {
        line-height:normal;
      }
    }
    .input-group-units {
        padding: 0 9px;
        display: block;
    }
    .select-box {
      height: 42px;
      // height: 100%;
      width: 100%;

      &:after {
        //     transform: translateY(50%);
        // top: 50%;
      }
    }

    .input-group-append,
    .input-group-prepend {
      background: #e9ecef;
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 70px;
      // padding: 4px 8px;
      border: 1px solid #c8c8c8;
    }
  }
}

.error,
.form-group>label.error {
  color: $red;
  font-size: 13px;
  font-weight: 400;
}

.valid-feedback.feedback-icon,
.invalid-feedback.feedback-icon {
  position: absolute;
  width: auto;
  top: 47px;
  right: 10px;
  margin-top: 0;
}

.is-valid,
.form-control.is-valid {
  border-color: $success;
}

.is-invalid,
.form-control.is-invalid {
  border-color: $red;
}

.valid-feedback,
.invalid-feedback {
  display: block;
  width: 100%;
  margin-top: .25rem;
  font-size: 80%;
  color: $success;
}

.invalid-feedback {
  color: $red;
}

.profilesetupform {
  .custom-option__item {
    border: 1px solid $border-color;
    padding: 8px 10px;
    margin-bottom: $spacer*.75;

    @include media-breakpoint-up(md) {
      margin-bottom: 0;
    }
  }

  .custom-option__label:before,
  .custom-option__label:after {
    border-radius: 0px;
    width: 18px;
    height: 18px;
    top: 2px;
  }
}

.custom-option__square {

  .custom-option__label:before,
  .custom-option__label:after {
    border: 1px solid $border-color;
    border-radius: 0px;
    width: 18px;
    height: 18px;
    top: 2px;
  }
}

label,
.form-label {
  font-weight: 600;
  @include font-size(14);
}

input[type='date'] {
  text-transform: uppercase;
}

.pricingoptions {
  .custom-option {
    justify-content: center;
  }
}

.custom-option {
  $c: &;

  &--bordered {
    #{$c}__item {
      border: 1px solid $border-color;
      padding: ($spacer*.35) ($spacer*.75);
    }

    #{$c}__label {
      &::before {
        border: 1px solid $border-color;
        background: $white;
      }

      &::before,
      &::after {
        border-radius: 0;
      }
    }

    input[type="radio"] {
      &:checked~#{$c}__label::before {
        border-color: var(--activeColor);
      }
    }
  }
}

.posrelative {
  position: relative;

  .cvvinfo {
    cursor: pointer;
    @include absolute(top 0 right 0);

    &__icon {
      --circleSize: 15px;
      color: $white;
      // background: $gray-500;
      width: var(--circleSize);
      height: var(--circleSize);
      display: inline-flex;
      justify-content: center;
      align-items: center;
      border-radius: var(--circleSize);
      font-size: 12px;

      img {
        width: 16px;
      }
    }

    &__figure {
      border-radius: 5px;
      display: none;
      margin: 0;
      width: 200px;
      z-index: 99;
      position: absolute;
      right: 0;

      @include media-breakpoint-up(lg) {
        right: auto;
        left: 0;
      }

      img {
        border-radius: 5px;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    &:hover {
      .cvvinfo__figure {
        display: block;
      }
    }
  }
}

// Nothing found ( 404 error page)
.form-group {
  &.form-inlinebtn {
    display: flex;

    .form-inlinebtn .btn {
      flex: 1 0 auto;
      border-radius: 0;
    }
  }
}

#noresult {
  padding-bottom: 60px;

  form {
    max-width: 600px;
    margin: 0 auto;
  }
}


.iconinput {
  &__block {
    display: flex;
  }

  &__icon {
    border: 1px solid $border-color;
    padding: $spacer*.25;
    margin-right: $spacer*.5;
    display: flex;
    align-items: center;
    width: 44px;
    justify-content: center;

    svg {
      fill: $gray-600;
    }
  }

  &__input {
    flex-grow: 1;
  }
}

.fade.show {
  opacity: 1;
}

.modal-backdrop.show {
  opacity: .5;
}

//group of error 
small.help-block {
  p.help-block {
    font-size: 13px;
    margin: 0px;
  }
}

.form-error {
  --color: red;

  .form-group {
    >label {
      color: var(--color);
    }

    .form-control {
      border-color: var(--color);
      color: var(--color);
      @include placeholder-color(var(--color))
    }
  }
}

.form-group-value {
  .form-label {
    font-weight: normal;
    font-size: 14px;
    margin-bottom: 6px;
  }

  .form-value {
    font-size: 14px;
    font-weight: 600;
  }
}

.form-control-sm {
  padding: 5px 12px !important;
  font-size: 14px;
  line-height: 1.5;
  height: 33px;
}

.select-box {
  select {
    padding-right: 30px !important;

    option:checked~.fa {
      color: red;
      opacity: 1;
    }
  }

  .fa {
    //  @include absolute(top 50% left 6px);
    transform: translateY(-50%);
    opacity: 0;
    ;
    pointer-events: none;
  }
}

