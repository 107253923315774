.socialbtn-block {
    --spacer: 6px;
    display: flex;
    // flex-wrap: wrap;
    justify-content: space-between;
    margin: 0 calc(var(--spacer) * -1);

    &--inline {
        display: inline-flex;
    }
}

.btn-social {
    --btnHeight: 44px;
    height: var(--btnHeight);
    border-radius: 2px;
    color: white;
    display: flex;
    line-height: var(--btnHeight);

    margin: 0 var(--spacer);
    flex: 1 0 auto;

    &__icon {
        @include media-breakpoint-up(lg) {
            flex: 1 0 40px;
            border-right: 1px solid rgba(255, 255, 255, 0.2);
        }

        text-align: center;
        flex: 1 1 100%;
        width:40px;
        display:flex;
        align-items: center;
        height: var(--btnHeight);
        text-align: center;
        justify-content: center;

        svg {
            fill: white;
        }
    }

    &__label {
        padding: 0px 20px;
        display: none;

        @include media-breakpoint-up(lg) {
            display: flex;
            flex: 0 0 100%;
        }
    }





    &.btn-fb {
        background-color: $facebook;
        transition: background-color 0.5s;

        &:hover,
        &:focus {
            background-color: darken($facebook, 5%);
            color: white;
        }
    }

    &.btn-gp {
        background-color: $google;
        transition: background-color 0.5s;

        &:hover,
        &:focus {
            background-color: darken($google, 5%);
            color: white;
        }
    }

    &.btn-tw {
        background-color: $twitter;
        transition: background-color 0.5s;

        &:hover,
        &:focus {
            background-color: darken($twitter, 5%);
            color: white;
        }
    }


}


//html example:
// <div class="socialbtn-block mb-2x">
// <a href="https://accessibleconsult.com/facebook" class="btn-social btn-fb">
//     <span class="btn-social__icon">
//         {{ icon.svg('facebook', 'facebook-icon') }}
//     </span>
//     <span class="btn-social__label">Facebook</span>
// </a>
// <a href="https://accessibleconsult.com/google" class="btn-social btn-gp">
//     <span class="btn-social__icon">{{ icon.svg('google', 'google-icon') }}</span>
//     <span class="btn-social__label">Google</span>
// </a>
// <a href="https://accessibleconsult.com/twitter" class="btn-social btn-tw">
//     <span class="btn-social__icon">{{ icon.svg('twitter', 'twitter-icon') }}</span>
//     <span class="btn-social__label">Twitter</span>
// </a>
// </div>