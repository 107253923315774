.healthFit {
    display:flex;
    flex-wrap: wrap;
     
    // margin:-15px;
    // padding:15px;
    background: rgba(0, 175, 240, 0.05);
    .postblock__preview & {
        background:transparent;
        margin:-15px;
    }
    // margin-bottom: 30px;
    
    &__item {
        display:flex;
        align-items:center;
        padding:15px;
        img {
            flex:0 0 auto;
        }

        &.disabled {
            opacity: 0.25;
            filter: grayscale(100);
        }
      
        
    }
      &__summery {
            margin-left:10px;
            
            &__value {
                font-weight: bold;
            }
        }
  }