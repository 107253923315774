.sprogress {
  background: $white;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  height: 10px;
}

.progress {
  border-radius: 2px;
  height: 16px;
       
  &-bar {
      background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
      background-size: 40px 40px;
      line-height: 16px;
      font-size: 12px;
  }
}