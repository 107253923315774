.profilesetupform {
  form {
    display: block;
    width: 100%;
    textarea {
      height: 100px;
      resize: none;
    }
  }
  .error {
    font-weight: 400;
    margin: 0;
    width: 100%;
  }
  .tokenfield .token {
    height: 26px;
  }
  .custom-option--inline {
    flex-wrap: wrap;
  }
}
.tokenfield {
  padding: 10px 10px 0;
}


.payment-input-group {
  .input-group-prepend {
    display: flex;
    z-index: 100;
    height: 100%;
    @include absolute(top 0 left 15px);
    .input-group-text {
      padding: ($spacer*.5) ($spacer*.75);
      border-right: 1px solid $border-color;
      white-space: nowrap;
      display: flex;
      align-items: center;
      font-weight: 600;
    }
  }
  .group-note {
    font-size: 14px;
    margin-top: $spacer*.25;
  }
  .input-group-text {
    display: inline-flex;
    height: 100%;
    align-items: center;
  }
}
.chipswrap {
  &.--customchips {
    .chips {
      display: inline-flex;
      align-items: center;

      .chip-label {
        
      }
    }
    .chipclose {
      @include font-size($font-size-base);
      color: $gray-900;
      margin-left: $spacer*.5;
    }
  }
}