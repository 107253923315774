a.cc-btn.cc-dismiss {
    background: #0ab7c5;
    border-radius: 4px;
    &:hover, &:focus {
        background: #1399A3;
    }
}

 

 