.card {
  $C: &;

  &.--introcard {
    background: none;
    text-align: center;

    #{$C}__body {
      &-title {
        margin: ($spacer*1.25) 0;
        @include font-size($font-size-lg);
        font-weight: 600;
      }
    }

    #{$C}__thumbnail {
      img {
        max-width: 100%;
      }
    }
  }

  &.--borderedcard {
    border: 1px solid $border-color;
    padding: $spacer;

    @include media-breakpoint-up(lg) {
      padding: $spacer*1.5;
    }
  }

  &.--colorcard {
    --bgcolor: $gray-200;
    border: 1px solid #EEEEEE;
    border-radius: 3px;
    background: var(--bgcolor);

    #{$C}__body {
      background: $white;
      padding: ($spacer*2.5) 0 ($spacer*1.5);

      &-title {
        font-weight: 700;
        text-transform: uppercase;
        @include font-size($font-size-base);
      }
    }

    #{$C}__thumbnail {
      margin: ($spacer*2.5) auto (-$spacer);
    }
  }

  &--default {
    background-color: transparent;

    #{$C}-body {
      margin-top: em(20);
    }

    #{$C}-thumbnail {
      border: 1px solid $border-color;

      img {
        max-width: 100%;
        height: auto;
      }
    }

    #{$C}-title {
      font-weight: 600;
      font-size: 1rem;
    }

    #{$C}-text {
      font-size: rem(14);
    }
  }
}

.arrowlink {
  display: inline-flex;
  margin-top: $spacer;
  position: relative;
  padding: $spacer*.5;

  &::before {
    --circleSize: 55px;
    content: "";
    display: block;
    width: var(--circleSize);
    height: var(--circleSize);
    border-radius: var(--circleSize);
    @include absolute(top 0 bottom 0 left -3px);
    margin: auto;
    z-index: 0;
  }

  .icon {
    width: 30px;
    fill: $primary;
  }

  &:hover {
    &::before {
      animation: 0.6s circleFadeOut ease-out;
    }
  }
}

.introcontent {
  margin-bottom: $spacer*1.5;

  @include media-breakpoint-up(lg) {
    margin-bottom: 0;
  }
}

.cards {

  &--shadow {
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.16);
  }
  &--secondary {
    border-radius: 0 16px 0 16px;
    box-shadow: 4px 8px 20px rgba(black, 0.16);
    padding: 20px;
    transition: all 0.3s ease-in-out;
    transform: translateY(0);


    &:hover {
      transform: translateY(-4px);
      box-shadow: 8px 12px 20px rgba(black, 0.16);

      .team--new .team__figure {
        transform: translateY((-6px));
        animation: myfirst 2s linear 0.3s 1 forwards;
      }
    }
  }

  &__header {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    justify-content: space-between;

    &:before,
    &:after {
      display: none;
    }
  }

  &__title {
    flex: 1 0 auto;
    margin-bottom: 10px;
  }

  .header-action {
    flex: 0 1 auto;


  }
 

  &[data-featured *="true"]{
    border:1px solid $primary;
    box-shadow:0px 2px 10px rgba($primary, 0.2);

    &:hover {
      box-shadow:0px 6px 18px rgba($primary, 0.4);
    }

    
  }

  &[data-featured *="false"]{
    .ribbon {
      display: none;
    }
  }

  
}